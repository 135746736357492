import * as actionTypes from '../actions/actionTypes';
import {updateObject}   from "../../shared/utility";
import moment           from 'moment';

const initialState = {
    rows: [],
    error: false,
    lastUpdate: null,
};

const fetchcoaList = (state, action) => {
    return updateObject(state, {
        rows: action.data,
        error: false,
        lastUpdate: moment()
    })
};

const reduce = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LIST_COA:
            return fetchcoaList(state, action);
        default:
            return state;
    }
};

export default reduce;