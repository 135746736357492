import * as actions from './actionTypes';


export const getDataMobileMenu = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_MOBILE_MENU_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitMobileMenu = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_MOBILE_MENU,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getDataMobileVersion = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_MOBILE_VERSION_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitMobileVersion = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_MOBILE_VERSION_DATA,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getDataMobileCommerce = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_MOBILE_COMMERCE_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitMobileCommerce = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_MOBILE_COMMERCE,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};