import React                                from 'react';
import axios                                from 'axios';
import {apiEntrySimpool, fineractHeaderKey} from './shared/apiUrl';
import {logoutWithError}                    from './store/actions';
import store                                from './store/store';
// import {} from 'react-router-dom'

const instance = axios.create({
    baseURL: apiEntrySimpool,
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(req => {
    try {
        if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined) {
            // throw 'no token';
            console.log('no token');
            errorAxios('INVALID_TOKEN');
        } else {
            if (localStorage.getItem('tenant') === null || localStorage.getItem('tenant') === undefined) {
                console.log('no tenant');
                errorAxios('INVALID_TENANT');
            } else {
                req.headers['Authorization'] = 'Basic ' + localStorage.getItem("token");
                req.headers[fineractHeaderKey] = localStorage.getItem('tenant');
            }
        }
    } catch (error) {
        console.log('INSIDE AXIOS', error);
    }
    return req;
});

/**
 * https://www.npmjs.com/package/axios?activeTab=versions
 * 
 * untuk handle error 401 (yang pernah di tes), untuk saat ini belum bisa, 
 * sepertinya ini masalah version dan harus coba update version ke lebih baru (Kemungkinan)
untuk saat ini version axios 0.19.2 (Sudah deprecated).
jika terjadi error 401, selalu terjadi network error. (baca di beberapa forum, memang ada issue untuk handle beberapa status code pada axios)


"Error: Network Error
    at createError (http://localhost:3000/static/js/7.chunk.js:50839:15)
    at XMLHttpRequest.handleError (http://localhost:3000/static/js/7.chunk.js:50364:14)"

 */
instance.interceptors.response.use( res => res, error => {
    // console.log('interceptors error1', error);
    // console.log('interceptors error2', error.response);
    if(error.response){
        return Promise.reject(error.response);
    }
    else{
        return Promise.reject(error);
    }
    //throw new Error(error.response.data);
    //errorAxios('INVALID_TOKEN');
});

function errorAxios(error) {
    switch (error) {
        case 'INVALID_TOKEN':
            store.dispatch(logoutWithError('INVALID_TOKEN'));
            break;
        case 'INVALID_TENANT':
            store.dispatch(logoutWithError('INVALID_TENANT'));
            break;
    }
}

export default instance;