import axios        from '../../Axios-Simpool';
// import {put}        from 'redux-saga/effects';
// import * as actions from '../actions';
import {
    basedPortalMenuURL,basedRolesURL,basedRolesMenuURL
} from '../../shared/apiUrl';
import { toLogout } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* addWebMenuParameterSaga(action) {
    try {
        const response = yield axios.post(basedPortalMenuURL,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
    }
}

export function* getAllWebMenuParameterSaga(action) {
    try {
        const response = yield axios.get(basedPortalMenuURL).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}
export function* getDetailWebMenuParameterSaga(action) {
    try {
        const response = yield axios.get(basedPortalMenuURL+"/"+action.id).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* deleteWebMenuParameterSaga(action) {
    try {
        const response = yield axios.delete(basedPortalMenuURL+"/"+action.id).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* editWebMenuParameterSaga(action) {
    try {
        const response = yield axios.put(basedPortalMenuURL+"/"+action.id,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getListRolesSaga(action) {
    try {
        const response = yield axios.get(basedRolesURL).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getListRoleMenuByRolesIDSaga(action) {
    try {
        const response = yield axios.get(basedRolesMenuURL+"/"+action.id).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response,action.datawebmenuparam);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* addRolesMenuSaga(action) {
    try {
        const response = yield axios.post(basedRolesMenuURL,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* deleteRolesMenuSaga(action) {
    try {
        const response = yield axios.delete(basedRolesMenuURL+"/"+action.id).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* editRolesMenuSaga(action) {
    try {
        const response = yield axios.put(basedRolesMenuURL+"/"+action.id,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}