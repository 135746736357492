import React, {useState, useEffect}    from 'react';
import {Formik}                        from 'formik';
import {useTranslation}                from 'react-i18next';
import ContentWrapper              from  '../../../components/Layout/ContentWrapper';
import ContentHeading              from  '../../../components/Layout/ContentHeading';
import {Container, Card, CardBody,Button} from 'reactstrap';
import {DropdownList, DatePicker}      from 'react-widgets';
import * as actions                 from '../../../store/actions';
// import Button                          from '@material-ui/core/Button';
// import {createMuiTheme} from '@material-ui/core/styles';
import moment                          from 'moment';
import momentLocalizer                 from 'react-widgets-moment';
import Viewer,{ RenderViewerProps, SpecialZoomLevel,PageChangeEvent,DocumentLoadEvent } from '@phuocng/react-pdf-viewer';
import pdfjs from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// import excelfile from '../Reports/FileTes/fileexcel.xlsx'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
// import 'moment/locale/id';
import {useDispatch}   from 'react-redux';
import { listTypeReport, reloadToHomeNotAuthorize } from '../../../shared/maskFunc';
import { Loading } from '../../../components/Common/Loading';
import Swal                         from 'sweetalert2';
import { ReportFinancingPaidOffDetailss } from '../../../shared/PermissionForFeatures';
// import Tablecustom from '../../../components/Layout/TableCustom';
import Grid                         from '../gridReports';
import {useHistory}                 from 'react-router-dom';
import * as pathmenu from '../../../shared/pathMenu';
import { downloadDataCSV, processDataCSV, textDialogHtml } from '../../../shared/utility';
import { maxDataCSV } from '../../../shared/constantValue';
import { getInfoLogin } from '../../../shared/processInfoLogin';

export default function ReportFinancingPaidOffDetails() {
    reloadToHomeNotAuthorize(ReportFinancingPaidOffDetailss);
    const {i18n} = useTranslation('translations');
    const dispatch = useDispatch();
    momentLocalizer();
    const history = useHistory();

    const [product, setProduct] = useState('');
    const [officeid, setOffice] = useState('');
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [output, setOutput] = useState('');

    const [listoffice, SetListOffice] = useState([]);
    const [listoutput, SetListOutPut] = useState([]);
    const [listproduct, SetListProduct] = useState([]);
    
    const [loading, setLoading] = useState(false);
    var [filedoc, setFileDoc] = useState('');

    const [rows, setRows] = useState([]);
    const [ValueCSV, setValueCSV] = useState([]);
    const [columns, setColumns] = useState([]);
    const [tableColumnExtensions, settableColumnExtensions] = useState([]);
    const [sortingStateColumnExtensions, setsortingStateColumnExtensions] = useState([]);

    const namaFile = 'FinancingPaidOffDetails';
    const namaReport = 'Loan Paid Off Details(Pentaho)';
    const namaReportCSV = 'Loan Paid Off Details';
    const [NoShowParameter, setNoShowParameter] = useState(false);
    const [IsShowDownloadPDF, setIsShowDownloadPDF] = useState(false);
    const [ParamQueryPdf, setParamQueryPdf] = useState('');
    const [InfoLength, setInfoLength] = useState('');
    const info = getInfoLogin();

    const render = (props) => {
        return (
            <div>
                
                <button style={{float:"right",marginRight:'33px'}} onClick={() => handleDownload()}>Download</button>
                {/* <button onClick={() => props.download()}>Download</button> */}
                <div style={{ height: '500px' ,marginRight:'33px'}}>
                    {props.viewer}
                </div>
                {/* <button onClick={() => props.jumpToPage(props.doc.numPages - 1)}>Jump to last page</button>
                <button onClick={() => props.rotate(90)}>Rotate +90 degrees</button>
                <button onClick={() => props.zoom(0.5)}>Zoom to 50%</button>
                <button onClick={() => props.zoom(SpecialZoomLevel.ActualSize)}>Zoom to actual size</button>
                <button onClick={() => props.changeScrollMode(ScrollMode.Wrapped)}>Switch to wrapped scrolling</button>
                <button onClick={() => props.changeSelectionMode(SelectionMode.Hand)}>Switch to hand tool</button>
                <button onClick={() => props.print()}>Print</button> */}
                
            </div>
        );
    };

        useEffect(() => {
            setLoading(true);
            dispatch(actions.getValueRunReports('productIdLoanSelectAll?parameterType=true',handleGetReportLoanProduct,errorHandler));
            dispatch(actions.getValueRunReports('OfficeIdSelectOne?parameterType=true',handleGetReportOffice,errorHandler));
    }, [dispatch]);

    const handleGetReportLoanProduct = (data) => {
        
        let list = data.data.reduce((obj, el) => [
            ...obj,
            {
                'value': el["row"][0],
                'label': el["row"][1],
            }
        ], [])
        list.push( {
            'value': -1,
            'label': 'All'
        });
        SetListProduct(list);
    }

    const handleGetReportOffice = (data) => {
        SetListOutPut(listTypeReport({'isExcelHide':true,'isCSVHide':false,'isPDFHide':false}));
        let list = data.data.reduce((obj, el) => [
            ...obj,
            {
                'value': el["row"][0],
                'label': el["row"][1],
                'hier': el["row"][2],
            }
        ], [])
        SetListOffice(list);
        let officeid = info ?.officeid?info.officeid+"":"";
        setOffice(officeid);
        setLoading(false);
    }

    // const loadDataLoanOffice = (officeid) => {
    //     setLoading(true);
    //     setOffice(officeid);
    //     // dispatch(actions.GetStaffLoanOfficeByOffice(officeid,handleStaffSucces,handleStaffError));
    //     dispatch(actions.GetLoanProduct(handleLoanProdSucces,errorHandler));
    // }

    const loadData = (data) => {
        let output = data.outputtype;
        if(maxDataCSV > 0 && output.trim() === 'HTML'){
            Swal.fire({
                title: i18n.t('label_DIALOG_ALERT_SURE'),
                html:textDialogHtml({text:i18n.t('dialog.label_RUN_REPORT',{maxdata:maxDataCSV})}),//'<span style="color:green;font-size: larger">'+infoDialog()+'</span>',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: i18n.t('label_CONTINUE'),
                denyButtonText: i18n.t('label_CANCEL'),
                // width:'800px'
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    loadDataAction(data);
                    // transactiondeposit();
                //   Swal.fire('Saved!', '', 'success')
                } else if (result.isDenied) {
                //   Swal.fire('Changes are not saved', '', 'info')
                }
              })

        }else{
            loadDataAction(data);
        }
    }

    const loadDataAction = (data) => {
        setLoading(true);
        let listfilteroutput = listoutput.filter(output => output.value == data.outputtype);
        
        let namereport = namaReport;
        let output = '';
        let outputtypeaxios = '';
        if(listfilteroutput.length > 0){
            output = data.outputtype;
            outputtypeaxios = listfilteroutput[0].typeapi;
        }else{
            output = 'PDF';
            outputtypeaxios = 'application/pdf';
        }
       

        let tenantid = localStorage.getItem("tenant");
        let token = localStorage.getItem("token");
        let dateFormat = "dd MMMM yyyy";

        let listhier = listoffice.filter(output => output.value == data.officeid);
        let hier = '';
        if(listhier.length > 0){
            hier = listhier[0].hier
        }

        let limit = "&R_limit="+maxDataCSV+"&R_officeId="+data.officeid;
        if(output.trim() === 'CSV'){
            namereport = namaReportCSV;
            limit = "&R_officeHierarchy="+hier;
        }

        let paramquery = encodeURIComponent(namereport)+'?output-type='+output+"&tenantIdentifier="+tenantid+"&locale=in"+"&dateFormat="+encodeURIComponent(dateFormat)+"&authorization="+encodeURIComponent(token)+"&R_loanProductId="+data.loanproduct+"&R_startDate="+moment(data.startdate).format('YYYY-MM-DD')+"&R_endDate="+moment(data.enddate).format('YYYY-MM-DD')+limit;

        limit = "&R_limit="+maxDataCSV+"&R_officeId="+data.officeid;
        let outputpdf = 'PDF';
        let paramqueryPDF = encodeURIComponent(namaReport)+'?output-type='+outputpdf+"&tenantIdentifier="+tenantid+"&locale=in"+"&dateFormat="+encodeURIComponent(dateFormat)+"&authorization="+encodeURIComponent(token)+"&R_loanProductId="+data.loanproduct+"&R_startDate="+moment(data.startdate).format('YYYY-MM-DD')+"&R_endDate="+moment(data.enddate).format('YYYY-MM-DD')+limit;
        setParamQueryPdf(paramqueryPDF);

        if(output.trim() === 'PDF'){
            dispatch(actions.ReportMember(paramquery,outputtypeaxios,handleSucces,errorHandler));
        }else if(output.trim() === 'HTML'){
            dispatch(actions.ReportMember(paramquery,outputtypeaxios,handleSuccesHtml,errorHandler));
        }else if(output.trim() === 'XLS'){
            dispatch(actions.ReportMember(paramquery,outputtypeaxios,handleSuccesXLS,errorHandler));
        }else if(output.trim() === 'XLSX'){
            dispatch(actions.ReportMember(paramquery,outputtypeaxios,handleSuccesXLSX,errorHandler));
        }else if(output.trim() === 'CSV'){
            dispatch(actions.ReportMember(paramquery,outputtypeaxios,handleSuccesCSV,errorHandler));
        }
        setProduct(data.loanproduct);
        setOffice(data.officeid)
        setOutput(data.outputtype);
        setStart(data.startdate);
        setEnd(data.enddate);

    }

    const handleDownloadPDF = () => {
        setLoading(true);
        dispatch(actions.ReportMember(ParamQueryPdf,'application/pdf',handleSuccesPDF,errorHandler));   
    }

    const handleSuccesPDF = (data) => {
        var blob = new Blob([data,{ type: 'application/pdf' }]);
        var dataUrl = URL.createObjectURL(blob);
        
        var fileLink = document.createElement('a');
        fileLink.href = dataUrl;

        // it forces the name of the downloaded file
        fileLink.download = namaFile+'.pdf';
        fileLink.click();
        fileLink.remove();

        setLoading(false);
        
    }
    const handleSuccesCSV = (data) => {
        let processCSV = processDataCSV( {data:data});
        setValueCSV([]);
        setRows([]);

        if(processCSV !== null){
            // const headerCSV = processCSV.header;
            const valcolumns = processCSV.columns;
            const valtableColumnExtensions = processCSV.columnsExtensions;
            const flagDownloadPDF = processCSV.flagDownloadPDF;
            const datalength = processCSV.datalength;

            setInfoLength(datalength);
            setColumns(valcolumns);
            settableColumnExtensions(valtableColumnExtensions);
            setsortingStateColumnExtensions(valtableColumnExtensions);

            let theData = processCSV.datagrid;
            // let valCSV = processCSV.datacsv;

            setValueCSV(data);
            setRows(theData);
            setFileDoc('CSV');
            setLoading(false);
            setNoShowParameter(true);
            setIsShowDownloadPDF(flagDownloadPDF);
        }else{
            Swal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'No Data'
            })
        }
    }

    const handleDownload = () => {
            
        // create <a> tag dinamically
        var fileLink = document.createElement('a');
        fileLink.href = filedoc;

        // it forces the name of the downloaded file
        fileLink.download = namaFile+'.pdf';
        fileLink.click();
        fileLink.remove();
    }
    const handleSuccesXLSX = (data) => {
        var blob = new Blob([data],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'});
        var dataUrl = URL.createObjectURL(blob);
        var fileLink = document.createElement('a');
        fileLink.href = dataUrl;

        // it forces the name of the downloaded file
        fileLink.download = namaFile+'.xlsx';
        fileLink.click();
        fileLink.remove();
        setLoading(false);
        setNoShowParameter(true);
    }
    const handleSuccesXLS = (data) => {
        var blob = new Blob([data,{ type: 'application/vnd.ms-excel' }]);
        var dataUrl = URL.createObjectURL(blob);
        var fileLink = document.createElement('a');
        fileLink.href = dataUrl;

        // it forces the name of the downloaded file
        fileLink.download = namaFile+'.xls';
        fileLink.click();
        fileLink.remove();
        setLoading(false);
        setNoShowParameter(true);
    }
    
    const handleSuccesHtml = (data) => {
        setFileDoc(data);
        setLoading(false);
        setNoShowParameter(true);
    }
   
    const handleSucces = (data) => {
        // console.log("apiSRM ",data);
        var blob = new Blob([data,{ type: 'application/pdf' }]);
        var dataUrl = URL.createObjectURL(blob);

        setFileDoc(dataUrl);
        setLoading(false);
        setNoShowParameter(true);
    }

    const handleDownloadCsv = () => {
        let dataCSV = downloadDataCSV({data:ValueCSV,isdownloadfile:true,namafile:namaFile});
        if(dataCSV == null){
            Swal.fire({
                icon: 'info',
                title: 'Oops...',
                text: 'No Data'
            })
        }
    }

    const handleShowParameter = () => {
        setNoShowParameter(false);
        setFileDoc('');
    }
    const initialPage = localStorage.getItem('current-page')
    ? parseInt(localStorage.getItem('current-page'), 10)
    : 0;
    const handlePageChange = (PageChangeEvent) => {
        localStorage.setItem('current-page', `${PageChangeEvent.currentPage}`);
    };

    function errorHandler(error) {
        setLoading(false);
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'error' + error
        })
    }

    return (
        <Formik
        initialValues={
            {
                officeid:officeid !== ''?officeid:'',
                outputtype:output !== ''?output: 'HTML',
                loanproduct:product !== '' ? product:'',
                startdate:start !== null ? moment(start, "DD MMMM YYYY").toDate() : new Date(),
                enddate:end !== null ? moment(end, "DD MMMM YYYY").toDate(): new Date(),
                valuedata:rows,
                noshowparameter:NoShowParameter,
                isshowdownloadpdf:IsShowDownloadPDF
            }
        }
        validate={values => {
            const errors = {};
            if (!values.loanofficer) {
                errors.loanofficer = i18n.t('forms.REQUIRED')
            }
            return errors;
        }}
        enableReinitialize="true"
        onSubmit={(values) => {
           
        }}
        >
            {
                formikProps => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldTouched,
                        setFieldValue,
                    } = formikProps;

                    return(
                        <form className="mb-6" onSubmit={handleSubmit}  name="reportFinancingPaidOffDetails">
                            <ContentWrapper>
                            <ContentHeading history={history} link={pathmenu.reportFinancingPaidOffDetails} label={'label_REPORT_FINANCING_PAID_OFF_DETAILS'} labeldefault={'label_REPORT_FINANCING_PAID_OFF_DETAILS'} />

                            <div className="row justify-content-center" hidden={!values.noshowparameter} >
                                <Button
                                    className={"btn btn-color"}
                                    color="primary"
                                    onClick={() => handleShowParameter()}
                                    title={'Show Parameter'}
                                    // hidden={isGetPermissions(deleteSettingFrequentPosting)}
                                    // style={{float: 'right',marginRight:'0.2%'}}
                                >
                                    {i18n.t('label_SHOW_PARAMETER')}
                                </Button>
                            </div>

                            <div className="row mt-2" hidden={values.noshowparameter}>
                            <div className="mt-2 col-lg-6 ft-detail mb-5">
                            <label className="mt-3 form-label required" htmlFor="officeid">
                                {i18n.t('member.data.OFFICE')}
                            </label>
                            <DropdownList
                                className={
                                    touched.officeid && errors.officeid
                                        ? "input-error" : ""
                                }
                                name="officeid"
                                filter='contains'
                                placeholder={i18n.t('select.SELECT_OPTION')}
                                onChange={val => setFieldValue("officeid", val?.value ? val.value : '')}
                                onBlur={val => setFieldTouched("officeid", val?.value ? val.value : '')}
                                
                                // onChange={val => loadDataLoanOffice(val.value) }
                                // onBlur={val => loadDataLoanOffice()}
                                data={listoffice}
                                textField={'label'}
                                valueField={'value'}
                                // style={{width: '25%'}}
                                // disabled={values.isdisabledcountry}
                                value={values.officeid}
                            />
                            <label className="mt-3 form-label required" htmlFor="loanproduct">
                                    {i18n.t('label_PRODUCT')}
                            </label>
                            <DropdownList
                                className={
                                    touched.loanproduct && errors.loanproduct
                                        ? "input-error" : ""
                                }
                                name="loanproduct"
                                filter='contains'
                                placeholder={i18n.t('select.SELECT_OPTION')}
                                
                                onChange={val => setFieldValue("loanproduct", val?.value ? val.value : '')}
                                onBlur={val => setFieldTouched("loanproduct", val?.value ? val.value : '')}
                                data={listproduct}
                                textField={'label'}
                                valueField={'value'}
                                // style={{width: '25%'}}
                                // disabled={values.isdisabledcountry}
                                value={values.loanproduct}
                            />
                            </div>

                            <div className="mt-2 col-lg-6 ft-detail mb-5">
                                <label className="mt-3 form-label required" htmlFor="startdate">
                                    {i18n.t('member.report.STARTDATE')}
                                </label>
                                <DatePicker
                                        name="startdate"
                                        onChange={(val) => {
                                                setFieldValue("startdate", val);
                                            }
                                        }
                                        onBlur={handleBlur}
                                        // defaultValue={Date(moment([]))}
                                        format={'DD MMMM YYYY'}
                                        value={values.startdate}
                                        // style={{width: '25%'}}
                                        
                                />

                                <label className="mt-3 form-label required" htmlFor="enddate">
                                        {i18n.t('member.report.ENDDATE')}
                                    
                                </label>
                                <DatePicker
                                        name="enddate"
                                        onChange={(val) => {
                                                setFieldValue("enddate", val);
                                            }
                                        }
                                        onBlur={handleBlur}
                                        // defaultValue={Date(moment([]))}
                                        format={'DD MMMM YYYY'}
                                        value={values.enddate}
                                        // style={{width: '25%'}}
                                        
                                />

                                <label className="mt-3 form-label required" htmlFor="outputtype" >
                                    {i18n.t('member.report.OUTPUTTYPE')}
                                </label>
                                <DropdownList
                                    className={
                                        touched.outputtype && errors.outputtype
                                            ? "input-error" : ""
                                    }
                                    name="outputtype"
                                    filter='contains'
                                    placeholder={i18n.t('select.SELECT_OPTION')}
                                    
                                    onChange={val => setFieldValue("outputtype", val?.value ? val.value : '')}
                                    // onBlur={val => setFieldTouched("identityCountryCodeValue", val?.value ? val.value : '')}
                                    data={listoutput}
                                    textField={'label'}
                                    valueField={'value'}
                                    // style={{width: '25%'}}
                                    // disabled={values.isdisabledcountry}
                                    value={values.outputtype}
                                />
                            </div>

                            </div>
                            <div className="row justify-content-center" hidden={values.noshowparameter} >
                            <Button
                                    // disabled={props.activeStep === 0}
                                    // style={{marginLeft:"35%",marginTop:"4%"}}
                                    onClick={() => loadData(values)}
                                >
                                    {/* {i18n.t('common.BACK')} */}
                                    {'Run Report'}
                            </Button>
                            </div>

                            <div className="row justify-content-center" style={{paddingTop:'20px'}} hidden={!(filedoc == 'CSV' && output === 'CSV' && !values.isshowdownloadpdf)} >
                            <h4>{i18n.t('label_REPORT_MAX_DATA',{totaldata:InfoLength,maxdata:maxDataCSV}) }</h4>
                            </div>

                            {
                                filedoc !== '' && output === 'PDF'?
                                (
                                    <div style={{marginTop:'2%'}}>
                                <Viewer 
                                fileUrl=  {filedoc}//{'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf'}
                                initialPage={initialPage}
                                onPageChange={handlePageChange}
                                render={render}
                                />
                                    </div>
                                ):(<h2></h2>)
                            }
                            {
                            filedoc !== '' && output === 'HTML'?
                            (   <div style={{marginTop:'1%',marginRight:'50px'}}>
                                            <ContentWrapper
                                            customizeMarginLeft={'0px'}
                                            >
                                            <Container fluid>
                                            <Card>
                                            <CardBody>
                                            <Container fluid className="center-parent">
                                            <div>
                                            <div className="table-responsive">
                                            <div dangerouslySetInnerHTML={{__html:filedoc}}  />
                                            </div></div>
                                            </Container>
                                            </CardBody>
                                            </Card>
                                            </Container>
                                            </ContentWrapper>
                                            
                                            </div>
                                        ):(<div></div>)
                        }
                        {
                            filedoc !== '' && output === 'CSV'?
                            (   <div style={{marginTop:'1%',marginRight:'50px'}}>
                                <ContentWrapper
                                customizeMarginLeft={'0px'}
                                >
                                <Container fluid>
                                <Card>
                                <CardBody>
                            <Button
                                className={"btn btn-color"}
                                color="primary"
                                onClick={() => handleDownloadCsv()}
                                title={'Export CSV'}
                                // hidden={isGetPermissions(deleteSettingFrequentPosting)}
                                style={{float: 'right',marginRight:'0.2%'}}
                            >
                                {i18n.t('Export CSV')}
                            </Button>

                            <Button
                                className={"btn btn-color"}
                                color="primary"
                                onClick={() => handleDownloadPDF()}
                                title={'Download PDF'}
                                hidden={!values.isshowdownloadpdf}
                                style={{float: 'right',marginRight:'0.2%'}}
                            >
                                {i18n.t('Download PDF')}
                            </Button>

                                <Container fluid className="center-parent">
                                <div>
                                <div className="table-responsive">
                                <Grid
                                    rows={rows}
                                    columns={columns}
                                    totalCounts={rows.length}
                                    loading={loading}
                                    tableColumnExtensions={tableColumnExtensions}
                                    sortingStateColumnExtensions={sortingStateColumnExtensions}
                                />
                            </div>
                                {/* <section>
                                <Tablecustom
                                    maxheight={600}
                                    rows={values.valuedata}
                                    columns={columns}    
                                />
                                </section> */}
                                {/* <div className="table-responsive">
                                <div dangerouslySetInnerHTML={{__html:filedoc}}  />
                                </div> */}
                                </div>
                                </Container>
                                </CardBody>
                                </Card>
                                </Container>
                                </ContentWrapper>
                                
                                </div>
                            )
                            :(<div></div>)
                        }
                            </ContentWrapper>
                            {loading && <Loading/>}
                        </form>

                    )
                }
            }
        </Formik>

    )

}