import * as actions from './actionTypes';


export const submitDataImportFileFinancingRepaymentParam = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DATA_IMPORT_FILE_FINANCING_REPAYMENT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getTemplateSimpoolBulk = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_TEMPLATE_SIMPOOL_BULK,
        param:param,
        successCallback,
        errorCallback
    }
};

export const downloadFileResultSimpoolBulk = (param,successCallback, errorCallback) => {
    return {
        type: actions.DOWNLOAD_FILE_RESULT_SIMPOOL_BULK,
        param:param,
        successCallback,
        errorCallback
    }
};

export const uploadFileSimpoolBulk = (param,successCallback, errorCallback) => {
    return {
        type: actions.UPLOAD_FILE_SIMPOOL_BULK,
        param:param,
        successCallback,
        errorCallback
    }
};

export const progressFileSimpoolBulk = (param,successCallback, errorCallback) => {
    return {
        type: actions.PROGRESS_SIMPOOL_BULK,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getListSimpoolBulk = (param,successCallback, errorCallback) => {
    return {
        type: actions.LIST_SIMPOOL_BULK,
        param:param,
        successCallback,
        errorCallback
    }
};