import axios from 'axios';
import {apiEntrySimpool} from './shared/apiUrl';

const instance = axios.create({
    baseURL: apiEntrySimpool,
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
});

// instance.interceptors.request.use(
//     (req) => {
//         console.log('interceptors get req ',req);
//        // Add configurations here
//        return req;
//     },
//     (err) => {
//         console.log('interceptors get ',err);
//        return Promise.reject(err);
//     }
//   );
  
//   // For POST requests
//   instance.interceptors.response.use(
//     (res) => {
//        // Add configurations here
//        console.log('interceptors post res ',res);
//        if (res.status === 201) {
//           console.log('Posted Successfully');
//        }
//        return res;
//     },
//     (err) => {
//         console.log('interceptors post ',err);
//        return Promise.reject(err);
//     }
//   );

export default instance;