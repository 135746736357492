import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    listmobileuserURL,mobileuserURL,unlockMobileUserURL,reSendPasswordURL
    ,createChangePasswordMobileUserURL,confirmChangePasswordMobileUserURL,resetpinURL,
    unlockConfirmMobileUserURL,getMobileUserByUsernameURL,getOTPResetMobileUser,submitOTPResetMobileUser,
    listMobileUserResetURL,externalUserParamUrl
} from '../../shared/apiUrl';
import { toLogout } from '../../shared/maskFunc';

export function* initGetListMobileUserSaga(action) {
    yield put(actions.getListMobileUserStart());
    try {
        const response = yield axios.get(listmobileuserURL).then(response => response.data);
        yield put(actions.getListMobileSuccess());
        action.successCallback(response, 'api');
    } catch (error) {
        toLogout(error);
        yield put(actions.getListMobileUserFailed());
        action.errorCallback(error.message ?? error.data.message);
    }
}


export function* initGetMobileUserByIDSaga(action) {
    try {
        const response = yield axios.get(mobileuserURL+"/"+action.userid).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        action.errorHandler(error);
    }

}

export function* initDeleteMobileUserByIDSaga(action) {
    try {
        const response = yield axios.delete(mobileuserURL+"/"+action.userid).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }

}


export function* initUnlockMobileUserByIDSaga(action) {
    try {
        const response = yield axios.post(unlockMobileUserURL(action.userid)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }

}


export function* initResendPasswordMobileUserSaga(action) {
    try {
        var obj = new Object();
        obj.username = action.username;
        var jsonString= JSON.stringify(obj);
        const response = yield axios.post(reSendPasswordURL,jsonString).then(response => response.data);
        // console.log("response ",response);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
        // action.errorHandler("huntu");
    }

}

export function* initCreateChangePasswordMobileUserSaga(action) {
    try {
        const response = yield axios.get(createChangePasswordMobileUserURL(action.userid)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }

}

export function* initConfirmChangePasswordMobileUserSaga(action) {
    try {
        const response = yield axios.post(confirmChangePasswordMobileUserURL(action.userid),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }

}


export function* initUpdateMobileUserSaga(action) {
    try {
        const response = yield axios.put(mobileuserURL+"/"+action.userid+"?clientId="+action.clientid,action.payload).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* initResetPinMobileUserSaga(action) {
    try {
        const response = yield axios.post(resetpinURL,action.payload).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* initUnlockConfirmMobileUserByIDSaga(action) {
    try {
        const response = yield axios.post(unlockConfirmMobileUserURL(action.userid),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // console.log('error ',error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }

}

export function* getMobileUserByUsernameSaga(action) {
    try {
        const response = yield axios.get(getMobileUserByUsernameURL(action.username)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getOtpResetMobileUserSaga(action) {
    try {
        const response = yield axios.post(getOTPResetMobileUser,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitOtpResetMobileUserSaga(action) {
    try {
        const response = yield axios.post(submitOTPResetMobileUser,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getListUserResetPasswordMobileSaga(action) {
    try {
        const response = yield axios.get(listMobileUserResetURL).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitListUserResetPasswordMobileSaga(action) {
    try {
        const response = yield axios.post(listMobileUserResetURL,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        // toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getExternalUserListPagingSaga(action) {
    try {
        const response = yield axios.get(externalUserParamUrl(action.queryparam)).then(response => response.data);
        action.successHandler(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}