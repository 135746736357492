import {takeEvery, all, takeLatest} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';

import {
    authUserSaga,
    logoutSaga,
    logoutWithError,
    authOtpSaga,
    authUserOtpSaga,
    authCheckStateSaga,
    changePasswordUserWebOTPSaga,
    confirmPasswordUserWebOTPSaga,
    getPasswordValidationSaga,
    getUserProfileSaga,
    changePasswordUserWebOTPReduxSaga,
    confirmPasswordUserWebOTPReduxSaga
} from './auth';

import {
    initGetListMobileUserSaga,
    initGetMobileUserByIDSaga,
    initDeleteMobileUserByIDSaga,
    initUnlockMobileUserByIDSaga,
    initResendPasswordMobileUserSaga,
    initCreateChangePasswordMobileUserSaga,
    initConfirmChangePasswordMobileUserSaga,
    initUpdateMobileUserSaga,
    initResetPinMobileUserSaga,
    initUnlockConfirmMobileUserByIDSaga,
    getMobileUserByUsernameSaga,
    getOtpResetMobileUserSaga,
    submitOtpResetMobileUserSaga,
    getListUserResetPasswordMobileSaga,
    submitListUserResetPasswordMobileSaga,
    getExternalUserListPagingSaga
} from './mobileuser';

import {
    initGetListMerchantSaga,
    getDetailMerchantSaga,
    getMerchantPaymentSaga,
    getMerchantQrisSaga,
    createMerchantSimpoolPaymentQRisSaga,
    getMerchantTemplateSaga,
    getSavingsMerchantSaga,
    submitAddMerChantSaga,
    getDataMerchantSaga,
    submitEditMerChantSaga,
    submitRejectMerChantSaga,
    submitApproveMerChantSaga,
    submitAddReCreateMerChantSaga
} from './merchant';

import {
    getListGLAccountSaga,
    getGLAccountSaga,
    getGLTemplateSaga,
    getGLTemplateWithTypeSaga,
    submitGLAccountSaga,
    getGlAccountByIDSaga,
    updateGLAccountSaga,
    deleteGLAccountSaga,
    getAccountingRulesSaga,
    getPaymentTypesSaga,
    submitJurnalEntriesSaga,
    getListAccountRulesSaga,
    getAccountRulesTemplateSaga,
    submitAccountingRulesSaga,
    getDetailAccountRulesSaga,
    deleteAccountRulesSaga,
    getDetailAccountRulesWithTemplateSaga,
    updateAccountingRulesSaga,
    getSearchJournalEntriesByParamSaga,
    getRecurringTemplateSaga,
    submitGlRecurringSaga,
    getListTransactionModeSaga,
    submitTransactionModeSaga,
    submitEditTransactionModeSaga,
    submitDeleteTransactionModeSaga,
    getListTransactionCodeSaga,
    getListChargesSaga,
    submitTransactionCodeSaga,
    submitDeleteTransactionCodeSaga,
    getDataTransactionCodeSaga,
    getDataTransactionModeSaga,
    submitEditTransactionCodeSaga,
    submitDeleteTransactionCodeDetailSaga,
    getTransactionCodeDetailSaga,
    submitTransactionCodeDetailSaga,
    submitEditTransactionCodeDetailSaga,
    submitTransactionCodeDetailOverDraftSaga,
    submitEditTransactionCodeDetailOverDraftSaga,
    getTransactionCodeLimitSaga,
    submitAddTransactionCodeLimitSaga,
    submitDeleteTransactionCodeLimitSaga,
    submitEditTransactionCodeLimitSaga,
    submitTransactionCodeControlSaga,
    getFeeTrxModeChargesProductSaga,
    getGLSummaryParameterDataSaga,
    submitAddGLSummaryParameterSaga,
    submitEditGLSummaryParameterSaga,
    submitDeleteGLSummaryParameterSaga,
    getReportJobSaga,
    submitGeneralTrxBulkExcelSaga,
    getInterBranchSettlementSaga,
    submitInterBranchSettlementSaga
} from './accounting';

import {
    initGetTokenServiceSaga,
    initGetTokenTrasactionHistorySaga,
    initGetTokenTrasactionHistoryTopUpSaga,
    initGetTemplateTokenSaga,
    initCreateTokenSaga,
    initGetTemplateServiceTokenSaga,
    initInsertServiceTokenSaga,
    initGetServiceSaga,
    initGetTokenRoocSaga,
    initGetTokenTrasactionHistoryParamSaga,
    initGetTokenTrasactionHistoryTopUpParamSaga,
    getTokenDataSaga,
    submitTokenSaga,
    getTopUpDataSaga
} from './token';

import {
    getListStaffSaga,
    getListLoanProductSaga,
    getListLoanDetailSaga,
    initGetListLoanSaga,
    initGetListLoanSearchSaga,
    submitApproveOrRejectSaga,
    initGetListProvisioningCategorySaga,
    getLoansTemplateSaga,
    createLoansSaga,
    scheduleLoansSaga,
    paymentToLoansSaga,
    getLoanTransTemplateByIdAndCommandSaga,
    submitLoanTransByIdAndCommandSaga,
    submitDisbursementMultipleSaga,
    getAccTransferTemplateSaga,
    submitTransferOverpaidLoanSaga,
    getLoanChargeTemplateSaga,
    submitLoanChargesSaga,
    getLoanTemplateByQueryParamSaga,
    editChargeInLoanSaga,
    deleteChargeInLoanSaga,
    getDetailChargeInLoanSaga,
    submitWaiveLoanChargesSaga,
    getLoanDataParamDataImportSaga,
    submitLoanTransByIdAndCommandMultipleSaga,
    submitLoanTransByIdAndCommandDataImportSaga,
    getLoansParamSaga,
    submitEditLoansSaga,
    getFindLoansAccNumberSaga,
    submitLoanPassBookCoverSaga,
    getLoanPrintPassbookSaga,
    submitLoanPassbookResetSaga,
    submitLoanRePrintPassbookSaga,
    getLoanPagingSaga,
    getSubmissionLoanPagingSaga,
    getNotesLoanSaga,
    submitNotesLoanSaga,
    getListDocumentLoanSaga,
    getDownloadDocumentLoanSaga,
    deleteDocumentLoanSaga,
    submitAddDocumentLoanSaga,
    getProductLoanSaga,
    submitAddProductLoanSaga,
    submitEditProductLoanSaga,
    submitInactiveChargeLoanSaga,
    getLoansSaga,
    submitLoansSaga,
    getProvosioningCriteriaSaga,
    submitProvosioningCriteriaSaga
} from './loan';

import {
    initMemberListSaga,
    initMemberTemplateSaga,
    checkDuplicateMemberSaga,
    memberAddSaga,
    memberAddImageSaga,
    memberAddDocumentSaga,
    reportsMemberSaga,
    initMemberTemplateSaga2,
    initGetSavingsByClientIDSaga,
    getTemplateClientCloseSaga,
    CloseMemberSaga,
    getParsingNIKSaga,
    submitMemberCommandPostSaga,
    initMemberList2Saga,
    getDataClientJobsSaga,
    submitAddClientJobsSaga,
    submitEditClientJobsSaga,
    submitDeleteClientJobsSaga,
    getDataClientApprovalSaga,
    submitClientApprovalSaga,
    submitClientApprovalMultipleSaga,
    getDataClientApprovalListActivationSaga,
    getDataMemberTemplateSaga,
    getCheckDuplicateMemberSaga,
    submitAddMemberEntitySaga,
    getDataEntityMemberSaga,
    getDataPersonMemberSaga,
    getDataMemberWithParamSaga,
    submitEditMemberEntitySaga,
    submitUpdateEmployeeInformationSaga,
    submitDataImportUpdateClientSaga,
    getDataMemberParamSaga,
    getTransferClientHistorySaga,
    submitMemberCommandPostMultipleSaga
} from './member';

import {
    initGetMemberDetailSaga,
    initGetMemberImageSaga,
    initGetMemberAccountSaga,
    initGetMemberSummarySaga,
    initGetMemberDocSaga,
    initDeleteMemberImageSaga,
    initDeleteMemberDocSaga,
    initGetDocumentSaga,
}   from './member-detail';

import {
    initGetMemberEditSaga,
    initMemberEditSaga
}   from './member-edit';

import {
    initGetMemberSavingDetailSaga,
    initGetMemberDepositDetailSaga,
    initGetMemberSavingQRImageSaga
} from './saving-detail';

import {
    initGetSavingAccountTransactionSaga,
    initGetSavingAccountTransferSaga,
    initGetProductSavingSaga,
    initPostSavingAccSaga,
    initGetListSavingSaga,
    getFindAccountAndGLSaga,
    getAllCodeTrxSaga,
    savingsGeneralTransactionSaga,
    getFeeByTrxModeSaga,
    GetListSavingsByAccountNumberSaga,
    getAllSavingsInteremediateSaga,
    getCoreTransDetailSaga,
    SavingsAccountByCommandSaga,
    getCoreTransDetailParamSaga,
    getAllSavingsInteremediateParamSaga,
    getProductSavingByIDSaga,
    GetSavingDetailAssociationsEmptySaga,
    getInterestCloseAccountSaga,
    getMaintenanceSavingsSaga,
    getMaintenanceChargesSavingsSaga,
    submitMaintenanceSavingSaga,
    getAllCodeTrxV2Saga,
    transactionByCommandSaga,
    getSavingProductTemplateByIDSaga,
    fixDepositByIdAndCommandSaga,
    getGlAccountIsGeneralTransTrueSaga,
    getFixedDepositByQueryParamSaga,
    submitMaintenanceTermSavingsSaga,
    submitAdjusmentTermSavingsSaga,
    getFindAccountNumberSaga,
    getListPaylaterByParamSaga,
    getDetailPayChargeSavingsSaga,
    submitPayChargeSavingsSaga,
    editSavingsAccountSaga,
    getDataBlockedAccountSaga,
    submitAddBlockAccountSaga,
    submitEditBlockAccountSaga,
    submitDeleteBlockAccountSaga,
    getListIntermediaryByParamSaga,
    getFixDepositAccDataSaga,
    getSavingProductDataSaga,
    submitUpdateSpecialRateSaga,
    submitResetSpecialRateSaga,
    getDataTypePassbookSaga,
    submitAddSavingsProductSaga,
    submitEditSavingsProductSaga,
    getDataPaylaterByQueryParamSaga,
    submitDataImportPaylaterSaga,
    submitSavingsTransactionSaga,
    getSavingSAccountParamSaga,
    getSavingTemplatePaylaterSaga,
    getListSavingByParamSaga,
    getPrintPassbookParamSaga,
    getTermSavingListPagingSaga,
    getRecurringDepositSaga,
    getListSavingRecurringByParamSaga,
    getAccountTransferDataSaga,
    submitRecurringDepositSaga,
    getFixDepositAccountDataSaga,
    submitFixDepositAccountSaga,
    getFindSavingRecurringSaga,
    editRecurringDepositSaga,
    getSavingsChargesSaga,
    submitSavingsChargesSaga,
    submitMaintenanceSavingsChargesSaga,
    getSavingsTransactionSaga
    // GetListSavingsByAccountNumberSaga
} from './saving';

import {
    initGetDepositAccountTransactionSaga,
    initGetDepositAccountTransferSaga,
    getDepositTemplateSaga,
    getDepositChartSaga,
    depositCreateSaga,
    getDepositBillerSaga,
    submitaddDepositBillerSaga,
    submitcancelDepositBillerSaga,
    submitaddListDepositBillerPhotoSaga
} from './deposit';
import { getListLoanProduct, submitLoanApproveOrRejectURL } from '../../shared/apiUrl';

import {
    fetchDashboardGenderSaga,
    fetchDashboardAgeSaga,
    fetchDashboardProvinceSaga,
    fetchDashboardClientMonthSaga,
    fetchDashboardLoanSaga,
    fetchDashboardTotalApexSaga,
    fetchDashboardMemberApexSaga,
    fetchDashboardCityApexSaga,
    fetchDashboardClientTenantApexSaga,
    fetchDashboardGeoCityTenantApexSaga,
    fetchDashboardSyncApexSaga,
    fetchDashboardActiveMobileUserApexSaga,
    getDashboardAgeSaga,
    getDashboardGenderSaga,
    getDashboardLoanSaga,
    getDashboardLoanDisburseSaga,
    getDataDashboardTokenParamSaga
} from './dashboard';

import {
    getOfficeSaga,
    getCurrencySaga,
    getGlAccountByQueryParamSaga,
    getChargeSaga,
    getChargeWithQueryParamSaga,
    deleteChargeSaga,
    submitChargeSaga,
    getChargeEditWithQueryParamSaga,
    editChargeSaga,
    getValueRunReportsSaga,
    submitBatchesSaga,
    getAllCodeTrxNonCashSaga,
    getProductGroupPPOBSaga,
    getFundsWithParamSaga,
    getCurrencyWithParamSaga,
    getAllCodeTrxCashSaga,
    getDataDenominationSaga,
    getDataFinancialActivitySaga,
    submitAddDenominationSaga,
    submitEditDenominationSaga,
    submitDeleteDenominationSaga,
    getDataMakerChekerSaga,
    submitMakerChekerMultipleSaga,
    getDataAuditsSaga,
    submitDeleteMakerChekeSaga,
    getDataTellerDashboardSaga,
    submitAddTellerOperationSaga,
    submitEditTellerOperationSaga,
    submitDeleteTellerOperationSaga,
    submitSettleTellerSaga,
    submitAllocateTellerSaga,
    getDataQuickSearchSaga,
    getRunreportCheckSaga,
    getDataTellerDashboardParamSaga,
    getDataFormatReportsCodeSaga,
} from './general'
import { getdetailaccountingruleswithtemplate, submitAddProductLoan, submitEditProductLoan } from '../actions';
import {
    addWebMenuParameterSaga,
    getAllWebMenuParameterSaga,
    getDetailWebMenuParameterSaga,
    deleteWebMenuParameterSaga,
    editWebMenuParameterSaga,
    getListRolesSaga,
    getListRoleMenuByRolesIDSaga,
    addRolesMenuSaga,
    deleteRolesMenuSaga,
    editRolesMenuSaga
} from './webmenumanagement';

import{
    getAllInternaUserSaga,
    getUserTemplateSaga,
    submitInternalUserSaga,
    detailInternalUserSaga,
    updateInternalUserSaga,
    deleteInternalUserSaga,
    getOtpChangePassowrdInternalUserSaga,
    submitChangePasswordInternalUserSaga,
    getOtpUnlockInternalUserSaga,
    submitUnlockInternalUserSaga,
    getDataUnitUserSaga,
    submitAddUnitUserSaga,
    submitDeleteUnitUserSaga,
    submitEditUnitUserSaga,
    submitLockInternalUserSaga,
    getInternalUserListPagingSaga
} from './usermanagement';

import{
    getDataAutoSweepSaga,
    submitAddAutoSweepSaga,
    submitDeleteAutoSweepSaga,
    submitEditAutoSweepSaga,
    getDataAutoSweepGroupSaga,
    submitAddAutoSweepGroupSaga,
    submitDeleteAutoSweepGroupSaga,
    submitEditAutoSweepGroupSaga,
    getDataAutoSweepBulkSaga,
    submitAutoSweepBulkSaga,
    submitUpdateAutoSweepBulkSaga
} from './sweepaccount';
import { getDetailPromotionManagementSaga, getListPromotionManagementSaga, 
    submitAddPromotionManagementSaga, submitDeletePromotionManagementSaga, submitEditPromotionManagementSaga,
    getDepositBillerMitraByTenantSaga, getDepositBillerServiceSaga, 
    getListDepositBillerMitraSaga, 
    getTemplateDepositBillerMitraByTenantSaga, 
    submitAddDepositBillerServiceSaga,
    submitDeleteDepositBillerServiceSaga,
    submitEditDepositBillerServiceSaga, 
    getDataAgentGroupSaga,
    submitAddComissionAgentSaga,
    submitDeleteComissionAgentSaga,
    submitAddAgentGroupSaga,
    submitEditAgentGroupSaga,
    submitDeleteAgentGroupSaga,
    submitEditComissionAgentSaga,
    getDataAgentSaga,
    submitAddSpecialComissionAgentSaga,
    submitEditSpecialComissionAgentSaga,
    submitDeleteSpecialComissionAgentSaga,
    submitAddAgentSaga,
    submitEditAgentSaga,
    submitDeleteAgentSaga,
    getLoanCollectionSheetSaga,
    getDataOfficesSaga,
    submitAddOfficesSaga,
    submitEditOfficesSaga,
    getListDepositBillerSettlementSaga,
    getDataOfficesApprovalSaga,
    submitAddOfficeApprovalSaga,
    submitDeleteOfficeApprovalSaga,
    submitEditOfficeApprovalSaga,
    getTransactionLimitSaga,
    submitAddTransactionLimitSaga,
    getDataEmploymentLevelSaga,
    submitAddEmploymentLevelSaga,
    submitEditEmploymentLevelSaga,
    submitDeleteEmploymentLevelSaga,
    submitEditTransactionLimitSaga,
    submitDeleteTransactionLimitSaga,
    getDataOrganisationPositionSaga,
    submitOrganisationPositionSaga,
    getDataOrganisationStructureSaga,
    submitOrganisationStructureSaga,
    getOfficesApprovalLoanSaga,
    submitOfficesApprovalLoanSaga,
    getUserGroupSaga,
    submitUserGroupSaga,
    getDataCollectionSheetParamSaga,
    getDataCollectionSheetPagingSaga
} from './organisation';
import { editExternalServiceSMTPSaga, getDataExternalServiceSMTPSaga, getDataJobsPagingSaga, getDataJobsSaga } from './system';
import { getDataPassbookTypeSaga, getPrintPassbookSaga, submitAddPassbookTypeSaga, submitDeletePassbookTypeSaga, submitPassbookCoverSaga, submitPrintPassbookCoverSaga, submitPrintPassbookPOSTSaga, submitRePrintPassbookSaga, submitResetPassbookSaga,
    getDataReferralCodeSaga, submitAddReferralCodeSaga, submitDeleteReferralCodeSaga, submitEditReferralCodeSaga,getDataLoanPassbookTypeSaga,submitAddLoanPassbookTypeSaga,submitDeleteLoanPassbookTypeSaga,submitEditLoanPassbookTypeSaga,getDataTellerLimitSaga,submitAddTellerLimitSaga,
    submitEditTellerLimitSaga,submitDeleteTellerLimitSaga, getPrintPassbookParamSaga01, getProductTemplateDataSaga, submitAddProductTemplateSaga, submitEditProductTemplateSaga, getProductGroupSaga, submitProductGroupSaga, submitProductGroupDetailsSaga,
     } from './product';
import { getMobileCommerceDataSaga, getMobileMenuDataSaga, getMobileVersionDataSaga, submitMobileCommerceSaga, submitMobileMenuSaga, submitMobileVersionSaga } from './mobile';
import { submitDataImportFileFinancingRepaymentSaga,downloadFileResultSimpoolBulkSaga, getTemplateSimpoolBulkSaga, progressSimpoolBulkSaga, uploadFileSimpoolBulkSaga,getListSimpoolBulkSaga } from './dataimport';

    export function* wacthProduct() {
        yield all([
            takeEvery(actionTypes.GET_DATA_PASSBOOKTYPE, getDataPassbookTypeSaga),
            takeEvery(actionTypes.SUBMIT_ADD_PASSBOOKTYPE, submitAddPassbookTypeSaga),
            takeEvery(actionTypes.SUBMIT_DELETE_PASSBOOKTYPE, submitDeletePassbookTypeSaga),
            takeEvery(actionTypes.SUBMIT_RESET_PASSBOOK, submitResetPassbookSaga),
            takeEvery(actionTypes.SUBMIT_PRINT_PASSBOOK, getPrintPassbookSaga),
            takeEvery(actionTypes.SUBMIT_PASSBOOKCOVER, submitPassbookCoverSaga),
            takeEvery(actionTypes.SUBMIT_PRINT_PASSBOOKCOVER, submitPrintPassbookCoverSaga),
            takeEvery(actionTypes.SUBMIT_PRINT_PASSBOOK_POST, submitPrintPassbookPOSTSaga),
            takeEvery(actionTypes.SUBMIT_RE_PRINT_PASSBOOK, submitRePrintPassbookSaga),
            takeEvery(actionTypes.GET_DATA_REFERRAL_CODE, getDataReferralCodeSaga),
            takeEvery(actionTypes.SUBMIT_ADD_REFERRAL_CODE, submitAddReferralCodeSaga),
            takeEvery(actionTypes.SUBMIT_DELETE_REFERRAL_CODE, submitDeleteReferralCodeSaga), 
            takeEvery(actionTypes.SUBMIT_EDIT_REFERRAL_CODE, submitEditReferralCodeSaga), 
            takeEvery(actionTypes.GET_DATA_LOAN_PASSBOOKTYPE, getDataLoanPassbookTypeSaga),
            takeEvery(actionTypes.SUBMIT_ADD_LOAN_PASSBOOKTYPE, submitAddLoanPassbookTypeSaga),
            takeEvery(actionTypes.SUBMIT_DELETE_LOAN_PASSBOOKTYPE, submitDeleteLoanPassbookTypeSaga),
            takeEvery(actionTypes.SUBMIT_EDIT_LOAN_PASSBOOKTYPE, submitEditLoanPassbookTypeSaga),
            takeEvery(actionTypes.GET_DATA_TELLER_LIMIT, getDataTellerLimitSaga),
            takeEvery(actionTypes.SUBMIT_ADD_TELLER_LIMIT, submitAddTellerLimitSaga),
            takeEvery(actionTypes.SUBMIT_EDIT_TELLER_LIMIT, submitEditTellerLimitSaga),
            takeEvery(actionTypes.SUBMIT_DELETE_TELLER_LIMIT, submitDeleteTellerLimitSaga),
            takeEvery(actionTypes.SUBMIT_PRINT_PASSBOOK_PARAM, getPrintPassbookParamSaga01),
            takeEvery(actionTypes.GET_PRODUCTTEMPLATE_DATA, getProductTemplateDataSaga), 
            takeEvery(actionTypes.SUBMIT_ADD_PRODUCTTEMPLATE, submitAddProductTemplateSaga), 
            takeEvery(actionTypes.SUBMIT_EDIT_PRODUCTTEMPLATE, submitEditProductTemplateSaga), 
            takeEvery(actionTypes.GET_PRODUCT_GROUP, getProductGroupSaga), 
            takeEvery(actionTypes.SUBMIT_PRODUCT_GROUP, submitProductGroupSaga),
            takeEvery(actionTypes.SUBMIT_PRODUCT_GROUP_DETAILS, submitProductGroupDetailsSaga),
            
        ]);

    }

    export function* wacthSystem() {
        yield all([
            takeEvery(actionTypes.GET_DATA_EXTERNAL_SERVICE_SMTP, getDataExternalServiceSMTPSaga),
            takeEvery(actionTypes.EDIT_EXTERNAL_SERVICE_SMTP, editExternalServiceSMTPSaga),
            takeEvery(actionTypes.GET_JOBS, getDataJobsSaga),
            takeEvery(actionTypes.GET_JOBS_PAGING, getDataJobsPagingSaga),
        ]);
    
    }
export function* watchOrganisation() {
    yield all([
        takeEvery(actionTypes.GET_LIST_PROMOTION_MANAGEMENT, getListPromotionManagementSaga),
        takeEvery(actionTypes.GET_DETAIL_PROMOTION_MANAGEMENT, getDetailPromotionManagementSaga),
        takeEvery(actionTypes.SUBMIT_ADD_PROMOTION_MANAGEMENT, submitAddPromotionManagementSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_PROMOTION_MANAGEMENT, submitEditPromotionManagementSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_PROMOTION_MANAGEMENT, submitDeletePromotionManagementSaga),
        takeEvery(actionTypes.GET_LIST_DEPOSIT_BILLER_SERVICE, getDepositBillerServiceSaga),
        takeEvery(actionTypes.SUBMIT_ADD_DEPOSIT_BILLER_SERVICE, submitAddDepositBillerServiceSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_DEPOSIT_BILLER_SERVICE, submitEditDepositBillerServiceSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_DEPOSIT_BILLER_SERVICE, submitDeleteDepositBillerServiceSaga),
        takeEvery(actionTypes.GET_LIST_DEPOSIT_BILLER_MITRA, getListDepositBillerMitraSaga),
        takeEvery(actionTypes.GET_DEPOSIT_BILLER_MITRA_BY_TENANT, getDepositBillerMitraByTenantSaga),
        takeEvery(actionTypes.GET_TEMPLATE_DEPOSIT_BILLER_MITRA_BY_TENANT, getTemplateDepositBillerMitraByTenantSaga),
        takeEvery(actionTypes.GET_DATA_AGENT_GROUP, getDataAgentGroupSaga),
        takeEvery(actionTypes.SUBMIT_ADD_COMISSION_AGENT, submitAddComissionAgentSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_COMISSION_AGENT, submitDeleteComissionAgentSaga),
        takeEvery(actionTypes.SUBMIT_ADD_AGENT_GROUP, submitAddAgentGroupSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_AGENT_GROUP, submitEditAgentGroupSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_AGENT_GROUP, submitDeleteAgentGroupSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_COMISSION_AGENT, submitEditComissionAgentSaga),
        takeEvery(actionTypes.GET_DATA_AGENT, getDataAgentSaga),
        takeEvery(actionTypes.SUBMIT_ADD_SPECIAL_COMISSION_AGENT, submitAddSpecialComissionAgentSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_SPECIAL_COMISSION_AGENT, submitEditSpecialComissionAgentSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_SPECIAL_COMISSION_AGENT, submitDeleteSpecialComissionAgentSaga),
        takeEvery(actionTypes.SUBMIT_ADD_AGENT, submitAddAgentSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_AGENT, submitEditAgentSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_AGENT, submitDeleteAgentSaga),
        takeEvery(actionTypes.GET_DATA_LOAN_COLLECTION_SHEET, getLoanCollectionSheetSaga),
        
        takeEvery(actionTypes.GET_DATA_OFFICES, getDataOfficesSaga),
        takeEvery(actionTypes.SUBMIT_ADD_OFFICES, submitAddOfficesSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_OFFICES, submitEditOfficesSaga),
        takeEvery(actionTypes.GET_LIST_DEPOSITBILLER_SETTLEMENT, getListDepositBillerSettlementSaga),
        takeEvery(actionTypes.GET_OFFICEAPPROVAL_DATA, getDataOfficesApprovalSaga),
        takeEvery(actionTypes.SUBMIT_ADD_OFFICEAPPROVAL, submitAddOfficeApprovalSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_OFFICEAPPROVAL, submitDeleteOfficeApprovalSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_OFFICEAPPROVAL, submitEditOfficeApprovalSaga),
        takeEvery(actionTypes.GET_DATA_TRANSACTION_LIMIT, getTransactionLimitSaga),
        takeEvery(actionTypes.SUBMIT_ADD_TRANSACTION_LIMIT, submitAddTransactionLimitSaga),
        takeEvery(actionTypes.GET_DATA_EMPLOYMENT_LEVEL, getDataEmploymentLevelSaga),
        takeEvery(actionTypes.SUBMIT_ADD_EMPLOYMENT_LEVEL, submitAddEmploymentLevelSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_EMPLOYMENT_LEVEL, submitEditEmploymentLevelSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_EMPLOYMENT_LEVEL, submitDeleteEmploymentLevelSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_TRANSACTION_LIMIT, submitEditTransactionLimitSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_TRANSACTION_LIMIT, submitDeleteTransactionLimitSaga),
        takeEvery(actionTypes.GET_ORGANISATION_POSITION, getDataOrganisationPositionSaga),
        takeEvery(actionTypes.SUBMIT_ORGANISATION_POSITION, submitOrganisationPositionSaga),
        takeEvery(actionTypes.GET_ORGANISATION_STRUCTURE, getDataOrganisationStructureSaga),
        takeEvery(actionTypes.SUBMIT_ORGANISATION_STRUCTURE, submitOrganisationStructureSaga),
        takeEvery(actionTypes.GET_OFFICEAPPROVAL_LOAN, getOfficesApprovalLoanSaga),
        takeEvery(actionTypes.SUBMIT_OFFICEAPPROVAL_LOAN, submitOfficesApprovalLoanSaga),
        takeEvery(actionTypes.GET_USER_GROUP, getUserGroupSaga),
        takeEvery(actionTypes.SUBMIT_USER_GROUP, submitUserGroupSaga),
        takeEvery(actionTypes.GET_DATA_COLLECTION_SHEET_PAGING, getDataCollectionSheetPagingSaga),
        takeEvery(actionTypes.GET_DATA_COLLECTION_SHEET, getDataCollectionSheetParamSaga),
    ]);

}


export function* watchSweepAccount() {
    yield all([
        takeEvery(actionTypes.GET_DATA_AUTO_SWEEP, getDataAutoSweepSaga),
        takeEvery(actionTypes.SUBMIT_ADD_AUTOSWEEP, submitAddAutoSweepSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_AUTOSWEEP, submitDeleteAutoSweepSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_AUTOSWEEP, submitEditAutoSweepSaga),
        takeEvery(actionTypes.GET_DATA_AUTO_SWEEP_GROUP, getDataAutoSweepGroupSaga),
        takeEvery(actionTypes.SUBMIT_ADD_AUTOSWEEPGROUP, submitAddAutoSweepGroupSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_AUTOSWEEPGROUP, submitDeleteAutoSweepGroupSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_AUTOSWEEPGROUP, submitEditAutoSweepGroupSaga),
        takeEvery(actionTypes.GET_DATA_AUTO_SWEEP_BULK, getDataAutoSweepBulkSaga),
        takeEvery(actionTypes.SUBMIT_AUTO_SWEEP_BULK, submitAutoSweepBulkSaga),
        takeEvery(actionTypes.UPDATE_AUTO_SWEEP_BULK, submitUpdateAutoSweepBulkSaga),
    ]);
}
export function* watchUserManagement() {
    yield all([
        takeEvery(actionTypes.GET_LIST_INTERNAL_USER_BY_PARAM, getAllInternaUserSaga),
        takeEvery(actionTypes.GET_USER_TEMPLATE, getUserTemplateSaga),
        takeEvery(actionTypes.SUBMIT_INTERNAL_USER, submitInternalUserSaga),
        takeEvery(actionTypes.GET_DETAIL_INTERNAL_USER, detailInternalUserSaga),
        takeEvery(actionTypes.UPDATE_INTERNAL_USER, updateInternalUserSaga),
        takeEvery(actionTypes.DELETE_INTERNAL_USER, deleteInternalUserSaga),
        takeEvery(actionTypes.GET_OTP_CHANGEPASSWORD_INTERNAL_USER, getOtpChangePassowrdInternalUserSaga),
        takeEvery(actionTypes.SUBMIT_CHANGEPASSWORD_INTERNAL_USER, submitChangePasswordInternalUserSaga),
        takeEvery(actionTypes.GET_OTP_UNLOCK_INTERNAL_USER, getOtpUnlockInternalUserSaga),
        takeEvery(actionTypes.SUBMIT_UNLOCK_INTERNAL_USER, submitUnlockInternalUserSaga),
        takeEvery(actionTypes.GET_DATA_UNIT_USER, getDataUnitUserSaga),
        takeEvery(actionTypes.SUBMIT_ADD_UNIT_USER, submitAddUnitUserSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_UNIT_USER, submitEditUnitUserSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_UNIT_USER, submitDeleteUnitUserSaga),
        takeEvery(actionTypes.SUBMIT_LOCK_INTERNAL_USER, submitLockInternalUserSaga),
        takeEvery(actionTypes.GET_INTERNAL_USER_PAGING, getInternalUserListPagingSaga),
        
    ]);
}
export function* watchMenuManagement() {
    yield all([
        takeEvery(actionTypes.ADD_WEB_MENU_PARAMETER, addWebMenuParameterSaga),
        takeEvery(actionTypes.GET_ALL_WEB_MENU_PARAMETER, getAllWebMenuParameterSaga),
        takeEvery(actionTypes.GET_DETAIL_WEB_MENU_PARAMETER, getDetailWebMenuParameterSaga),
        takeEvery(actionTypes.DELETE_WEB_MENU_PARAMETER, deleteWebMenuParameterSaga),
        takeEvery(actionTypes.EDIT_WEB_MENU_PARAMETER, editWebMenuParameterSaga),
        takeEvery(actionTypes.GET_LIST_ROLES, getListRolesSaga),
        takeEvery(actionTypes.GET_LIST_ROLE_MENU_BY_ROLES_ID, getListRoleMenuByRolesIDSaga),
        takeEvery(actionTypes.ADD_ROLE_MENU, addRolesMenuSaga),
        takeEvery(actionTypes.DELETE_ROLE_MENU, deleteRolesMenuSaga),
        takeEvery(actionTypes.EDIT_ROLE_MENU, editRolesMenuSaga)
    ]);
}
export function* watchAuth() {
    yield all([
        takeEvery(actionTypes.AUTH_USER, authUserSaga),
        takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
        takeEvery(actionTypes.AUTH_LOGOUT_WITHERROR, logoutWithError),
        takeEvery(actionTypes.AUTH_OTP, authOtpSaga),
        takeEvery(actionTypes.AUTH_USEROTP, authUserOtpSaga),
        takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
        takeEvery(actionTypes.CREATE_CHANGE_PASSWORD_USER_WEB_OTP, changePasswordUserWebOTPSaga),
        takeEvery(actionTypes.CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP, confirmPasswordUserWebOTPSaga),
        takeEvery(actionTypes.GET_PASSWORD_VALIDATION, getPasswordValidationSaga),
        takeEvery(actionTypes.GET_USER_PROFILE, getUserProfileSaga),
        takeEvery(actionTypes.CREATE_CHANGE_PASSWORD_USER_WEB_OTP_REDUX, changePasswordUserWebOTPReduxSaga),
        takeEvery(actionTypes.CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP_REDUX, confirmPasswordUserWebOTPReduxSaga)
    ]);
}
export function* watchMobileUser() {
    yield all([
        takeEvery(actionTypes.INIT_GET_LIST_MOBILEUSER, initGetListMobileUserSaga),
        takeEvery(actionTypes.GET_MOBILEUSER_BY_ID, initGetMobileUserByIDSaga),
        takeEvery(actionTypes.DELETE_MOBILEUSER_BY_ID, initDeleteMobileUserByIDSaga),
        takeEvery(actionTypes.UNLOCK_MOBILEUSER_BY_ID, initUnlockMobileUserByIDSaga),
        takeEvery(actionTypes.RESEND_PASSWORD_MOBILEUSER, initResendPasswordMobileUserSaga),
        takeEvery(actionTypes.CREATE_CHANGE_PASSWORD_MOBILEUSER, initCreateChangePasswordMobileUserSaga),
        takeEvery(actionTypes.CONFIRM_CHANGE_PASSWORD_MOBILEUSER, initConfirmChangePasswordMobileUserSaga),
        takeEvery(actionTypes.UPDATE_MOBILE_USER, initUpdateMobileUserSaga),
        takeEvery(actionTypes.RESET_PIN_MOBILE_USER, initResetPinMobileUserSaga),
        takeEvery(actionTypes.UNLOCK_CONFIRM_MOBILEUSER_BY_ID, initUnlockConfirmMobileUserByIDSaga),
        takeEvery(actionTypes.UNLOCK_CONFIRM_MOBILEUSER_BY_ID, initUnlockConfirmMobileUserByIDSaga),
        takeEvery(actionTypes.GET_MOBILEUSER_BY_USERNAME, getMobileUserByUsernameSaga),
        takeEvery(actionTypes.GET_OTP_RESET_MOBILEUSER, getOtpResetMobileUserSaga),
        takeEvery(actionTypes.SUBMIT_OTP_RESET_MOBILEUSER, submitOtpResetMobileUserSaga),
        takeEvery(actionTypes.GET_LIST_USER_RESET_PASSWORD_MOBILE, getListUserResetPasswordMobileSaga),
        takeEvery(actionTypes.SUBMIT_LIST_USER_RESET_PASSWORD_MOBILE, submitListUserResetPasswordMobileSaga),
        takeEvery(actionTypes.GET_EXTERNAL_USER_PAGING, getExternalUserListPagingSaga),
        
    ]);
}

export function* watchToken() {
    yield all([
        takeEvery(actionTypes.GET_TOKEN_SERVIE, initGetTokenServiceSaga),
        takeEvery(actionTypes.GET_LIST_TRANSACTION_HISTORY, initGetTokenTrasactionHistorySaga),
        takeEvery(actionTypes.GET_LIST_TRANSACTION_HISTORY_TOP_UP, initGetTokenTrasactionHistoryTopUpSaga),
        takeEvery(actionTypes.GET_TEMPLATE_TOKEN, initGetTemplateTokenSaga),
        takeEvery(actionTypes.CREATE_TOKEN, initCreateTokenSaga),
        takeEvery(actionTypes.GET_TEMPLATE_SERVICE_TOKEN, initGetTemplateServiceTokenSaga),
        takeEvery(actionTypes.INSERT_SERVICE_TOKEN, initInsertServiceTokenSaga),
        takeEvery(actionTypes.EXTERNAL_SERVICE_CHAT_BOT, initGetServiceSaga),
        takeEvery(actionTypes.GET_TOKEN_ROOC, initGetTokenRoocSaga),
        takeEvery(actionTypes.GET_LIST_TRANSACTION_HISTORY_PARAM, initGetTokenTrasactionHistoryParamSaga),
        takeEvery(actionTypes.GET_LIST_TRANSACTION_HISTORY_TOP_UP_PARAM, initGetTokenTrasactionHistoryTopUpParamSaga),
        takeEvery(actionTypes.GET_TOKEN_DATA, getTokenDataSaga),
        takeEvery(actionTypes.SUBMIT_TOKEN, submitTokenSaga),
        takeEvery(actionTypes.GET_DATA_TOPUP, getTopUpDataSaga),
    ]);
}

export function* watchAccounting() {
    yield all([
        takeEvery(actionTypes.GET_LIST_GL_ACCOUNT, getListGLAccountSaga),
        takeEvery(actionTypes.GET_COA_BY_ID, getGLAccountSaga),
        takeEvery(actionTypes.GET_GL_TEMPLATE, getGLTemplateSaga),
        takeEvery(actionTypes.GET_GL_TEMPLATE_WITH_TYPE, getGLTemplateWithTypeSaga),
        takeEvery(actionTypes.SUBMIT_GL_ACCOUNT, submitGLAccountSaga),
        takeEvery(actionTypes.GET_GL_ACCOUNT_BY_ID, getGlAccountByIDSaga),
        takeEvery(actionTypes.UPDATE_GL_ACCOUNT, updateGLAccountSaga),
        takeEvery(actionTypes.DELETE_GL_ACCOUNT, deleteGLAccountSaga),   
        
        takeEvery(actionTypes.GET_ACCOUNTING_RULES, getAccountingRulesSaga),
        takeEvery(actionTypes.GET_PAYMENTTYPES, getPaymentTypesSaga),
        takeEvery(actionTypes.SUBMIT_JURNAL_ENTRIES, submitJurnalEntriesSaga),
        takeEvery(actionTypes.GET_LIST_ACCOUNT_RULES, getListAccountRulesSaga),
        takeEvery(actionTypes.GET_ACCOUNT_RULES_TEMPLATE, getAccountRulesTemplateSaga),
        takeEvery(actionTypes.SUBMIT_ACCOUNTING_RULES, submitAccountingRulesSaga),
        takeEvery(actionTypes.GET_ACCOUNTING_RULES_DETAIL, getDetailAccountRulesSaga),
        takeEvery(actionTypes.DELETE_ACCOUNTING_RULES, deleteAccountRulesSaga),
        takeEvery(actionTypes.GET_ACCOUNTING_RULES_DETAIL_WITH_TEMPLATE, getDetailAccountRulesWithTemplateSaga),
        takeEvery(actionTypes.UPDATE_ACCOUNTING_RULES, updateAccountingRulesSaga),
        takeEvery(actionTypes.SEARCH_JOURNAL_ENTRIES_BY_PARAM, getSearchJournalEntriesByParamSaga),
        takeEvery(actionTypes.GET_GLRECURRING_TEMPLATE, getRecurringTemplateSaga),
        takeEvery(actionTypes.SUBMIT_GLRECURRING, submitGlRecurringSaga),        
        takeEvery(actionTypes.GET_LIST_TRANSACTION_MODE, getListTransactionModeSaga),
        takeEvery(actionTypes.SUBMIT_ADD_TRANSACTION_MODE, submitTransactionModeSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_TRANSACTION_MODE, submitEditTransactionModeSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_TRANSACTION_MODE, submitDeleteTransactionModeSaga),
        takeEvery(actionTypes.GET_LIST_TRANSACTION_CODE, getListTransactionCodeSaga),
        takeEvery(actionTypes.GET_LIST_CHARGES, getListChargesSaga),
        takeEvery(actionTypes.SUBMIT_ADD_TRANSACTION_CODE, submitTransactionCodeSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_TRANSACTION_CODE, submitDeleteTransactionCodeSaga),
        takeEvery(actionTypes.GET_DATA_TRANSACTION_CODE, getDataTransactionCodeSaga),
        takeEvery(actionTypes.GET_DATA_TRANSACTION_MODE, getDataTransactionModeSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_TRANSACTION_CODE, submitEditTransactionCodeSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_CORE_TRANSACTION_DETAIL, submitDeleteTransactionCodeDetailSaga),
        takeEvery(actionTypes.GET_CODE_TRANSACTION_DETAIL, getTransactionCodeDetailSaga),
        takeEvery(actionTypes.SUBMIT_CODE_TRANSACTION_DETAIL, submitTransactionCodeDetailSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_CODE_TRANSACTION_DETAIL, submitEditTransactionCodeDetailSaga),
        takeEvery(actionTypes.SUBMIT_CODE_TRANSACTION_DETAIL_OVERDRAFT, submitTransactionCodeDetailOverDraftSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_CODE_TRANSACTION_DETAIL_OVERDRAFT, submitEditTransactionCodeDetailOverDraftSaga),
        takeEvery(actionTypes.GET_TRANSACTION_CODE_LIMIT, getTransactionCodeLimitSaga),
        takeEvery(actionTypes.SUBMIT_ADD_TRANSACTION_CODE_LIMIT, submitAddTransactionCodeLimitSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_TRANSACTION_CODE_LIMIT, submitDeleteTransactionCodeLimitSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_TRANSACTION_CODE_LIMIT, submitEditTransactionCodeLimitSaga),
        takeEvery(actionTypes.SUBMIT_TRANSACTIONCODECONTROL, submitTransactionCodeControlSaga),
        takeEvery(actionTypes.GET_FEE_BY_TRX_MODE_CHARGE_PRODUCT, getFeeTrxModeChargesProductSaga),
        takeEvery(actionTypes.GET_GL_SUMMARY_PARAMETER_DATA, getGLSummaryParameterDataSaga),
        takeEvery(actionTypes.SUBMIT_ADD_GL_SUMMARY_PARAMETER, submitAddGLSummaryParameterSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_GL_SUMMARY_PARAMETER, submitEditGLSummaryParameterSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_GL_SUMMARY_PARAMETER, submitDeleteGLSummaryParameterSaga),
        takeEvery(actionTypes.GET_REPORT_JOB, getReportJobSaga),
        takeEvery(actionTypes.SUBMIT_GENERAL_TRX_BULK_EXCEL, submitGeneralTrxBulkExcelSaga),
        takeEvery(actionTypes.GET_INTERBRANCH_SETTLEMENT_DATA, getInterBranchSettlementSaga),
        takeEvery(actionTypes.SUBMIT_INTERBRANCH_SETTLEMENT, submitInterBranchSettlementSaga),
        
    ]);
}
export function* watchMerchant() {
    yield all([
        takeEvery(actionTypes.INIT_GET_LIST_MERCHANT, initGetListMerchantSaga),
        takeEvery(actionTypes.GET_MERCHANT_DETAIL, getDetailMerchantSaga),
        takeEvery(actionTypes.GET_MERCHANT_PAYMENT, getMerchantPaymentSaga),
        takeEvery(actionTypes.GET_MERCHANT_QRIS, getMerchantQrisSaga),
        takeEvery(actionTypes.CREATE_MERCHANT_SIMPOOLPAYMENT_QR, createMerchantSimpoolPaymentQRisSaga),
        takeEvery(actionTypes.GET_MERCHANT_TEMPLATE, getMerchantTemplateSaga),
        takeEvery(actionTypes.GET_MERCHANT_SAVING, getSavingsMerchantSaga),
        takeEvery(actionTypes.SUBMIT_ADD_MERCHANT, submitAddMerChantSaga),
        takeEvery(actionTypes.GET_DATA_MERCHANT, getDataMerchantSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_MERCHANT, submitEditMerChantSaga),
        takeEvery(actionTypes.SUBMIT_REJECT_MERCHANT, submitRejectMerChantSaga),
        takeEvery(actionTypes.SUBMIT_APPROVE_MERCHANT, submitApproveMerChantSaga),
        takeEvery(actionTypes.SUBMIT_ADD_RECREATE_MERCHANT, submitAddReCreateMerChantSaga),
    ]);
}
export function* watchLoan() {
    yield all([
        takeEvery(actionTypes.GET_STAFF_LOAN_OFFICER, getListStaffSaga),
        takeEvery(actionTypes.GET_LOAN_PRODUCT, getListLoanProductSaga),
        takeEvery(actionTypes.GET_LOAN_DETAIL, getListLoanDetailSaga),
        takeEvery(actionTypes.INIT_GET_LIST_LOAN, initGetListLoanSaga),
        takeEvery(actionTypes.INIT_GET_LIST_LOAN, initGetListLoanSaga),
        takeEvery(actionTypes.GET_LIST_LOAN_SEARCH_QUERY, initGetListLoanSearchSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_APPROVE_OR_REJECT, submitApproveOrRejectSaga),
        takeEvery(actionTypes.INIT_GET_LIST_PROVISIONINGCATEGORY, initGetListProvisioningCategorySaga),
        takeEvery(actionTypes.GET_LOANS_TEMPLATE, getLoansTemplateSaga),
        takeEvery(actionTypes.CREATE_LOANS, createLoansSaga),
        takeEvery(actionTypes.SIMULATION_LOANS_SCHEDULE, scheduleLoansSaga),
        takeEvery(actionTypes.PAYMENT_TO_LOAN, paymentToLoansSaga),
        takeEvery(actionTypes.GET_LOAN_TRANS_TEMPLATE_BY_ID_AND_COMMAND, getLoanTransTemplateByIdAndCommandSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND, submitLoanTransByIdAndCommandSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_DISBURSEMENT_MULTIPLE, submitDisbursementMultipleSaga),
        takeEvery(actionTypes.GET_ACC_TRANSFER_TEMPLATE, getAccTransferTemplateSaga),
        takeEvery(actionTypes.SUBMIT_TRANSFER_OVERPAID_LOAN, submitTransferOverpaidLoanSaga),
        takeEvery(actionTypes.GET_LOAN_CHARGES_TEMPLATE, getLoanChargeTemplateSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_CHARGES, submitLoanChargesSaga),
        takeEvery(actionTypes.GET_LOANS_BY_QUERY_PARAMS, getLoanTemplateByQueryParamSaga),
        takeEvery(actionTypes.EDIT_CHARGES_IN_LOAN, editChargeInLoanSaga),
        takeEvery(actionTypes.DELETE_CHARGES_IN_LOAN, deleteChargeInLoanSaga),
        takeEvery(actionTypes.GET_DETAIL_CHARGES_IN_LOAN, getDetailChargeInLoanSaga),
        takeEvery(actionTypes.SUBMIT_WAIVE_CHARGES_IN_LOAN, submitWaiveLoanChargesSaga),
        takeEvery(actionTypes.GET_LOANS_PARAM_DATA_IMPORT, getLoanDataParamDataImportSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_MULTIPLE, submitLoanTransByIdAndCommandMultipleSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_DATA_IMPORT, submitLoanTransByIdAndCommandDataImportSaga),
        takeEvery(actionTypes.GET_LOANS_DATA_PARAM, getLoansParamSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_LOANS, submitEditLoansSaga),
        takeEvery(actionTypes.GET_FIND_LOAN_ACCNUMBER, getFindLoansAccNumberSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_PASSBOOK_COVER, submitLoanPassBookCoverSaga),
        takeEvery(actionTypes.GET_LOAN_PRINT_PASSBOOK, getLoanPrintPassbookSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_PASSBOOK_RESET, submitLoanPassbookResetSaga),
        takeEvery(actionTypes.SUBMIT_LOAN_REPRINT_PASSBOOK, submitLoanRePrintPassbookSaga),
        takeEvery(actionTypes.GET_LOANS_PAGING, getLoanPagingSaga),
        takeEvery(actionTypes.GET_SUBMISSION_LOAN, getSubmissionLoanPagingSaga),
        takeEvery(actionTypes.GET_NOTES_LOAN, getNotesLoanSaga),
        takeEvery(actionTypes.SUBMIT_NOTES_LOAN, submitNotesLoanSaga),
        takeEvery(actionTypes.GET_LIST_DOCUMENT_LOAN, getListDocumentLoanSaga),
        takeEvery(actionTypes.DOWNLOAD_DOCUMENT_LOAN, getDownloadDocumentLoanSaga),
        takeEvery(actionTypes.DELETE_DOCUMENT_LOAN, deleteDocumentLoanSaga),
        takeEvery(actionTypes.SUBMIT_ADD_DOCUMENT_LOAN, submitAddDocumentLoanSaga),
        takeEvery(actionTypes.GET_PRODUCTLOAN_DATA, getProductLoanSaga),
        takeEvery(actionTypes.SUBMIT_ADD_PRODUCTLOAN, submitAddProductLoanSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_PRODUCTLOAN, submitEditProductLoanSaga),
        takeEvery(actionTypes.SUBMIT_INACTIVE_LOAN_CHARGES, submitInactiveChargeLoanSaga),
        takeEvery(actionTypes.GET_DATA_PROVOSIONING_CRITERIA, getProvosioningCriteriaSaga),
        takeEvery(actionTypes.SUBMIT_PROVOSIONING_CRITERIA, submitProvosioningCriteriaSaga),
        
        takeEvery(actionTypes.GET_SAVINGS_CHARGES, getSavingsChargesSaga),
        takeEvery(actionTypes.GET_LOANS, getLoansSaga),
        takeEvery(actionTypes.SUBMIT_LOANS, submitLoansSaga),
        
    ]);
}

export function* watchMember() {
    yield all([
        takeEvery(actionTypes.INIT_MEMBER_LIST, initMemberListSaga),
        takeEvery(actionTypes.INIT_MEMBER_TEMPLATE, initMemberTemplateSaga),
        takeEvery(actionTypes.CHECK_DUPLICATE, checkDuplicateMemberSaga),
        takeEvery(actionTypes.INIT_MEMBER_ADD, memberAddSaga),
        takeEvery(actionTypes.MEMBER_ADD_IMAGE, memberAddImageSaga),
        takeEvery(actionTypes.MEMBER_ADD_DOCUMENT, memberAddDocumentSaga),
        takeEvery(actionTypes.REPORTS_MEMBER, reportsMemberSaga),
        takeEvery(actionTypes.INIT_MEMBER_TEMPLATE_2, initMemberTemplateSaga2),
        takeEvery(actionTypes.GET_SAVINGS_BY_CLIENTID, initGetSavingsByClientIDSaga),
        takeEvery(actionTypes.GET_TEMPLATE_CLIENT_CLOSE, getTemplateClientCloseSaga),
        takeEvery(actionTypes.SUBMIT_CLOSE_MEMBER, CloseMemberSaga),
        
        takeEvery(actionTypes.GET_PARSING_NIK, getParsingNIKSaga),
        takeEvery(actionTypes.SUBMIT_COMMAND_MEMBER_POST, submitMemberCommandPostSaga),
        takeEvery(actionTypes.GET_LIST_MEMBER, initMemberList2Saga),
        takeEvery(actionTypes.GET_DATA_CLIENTJOB, getDataClientJobsSaga),
        takeEvery(actionTypes.SUBMIT_ADD_CLIENTJOB, submitAddClientJobsSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_CLIENTJOB, submitEditClientJobsSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_CLIENTJOB, submitDeleteClientJobsSaga),
        takeEvery(actionTypes.GET_CLIENT_APPROVAL_DATA, getDataClientApprovalSaga),
        takeEvery(actionTypes.SUBMIT_CLIENT_APPROVAL, submitClientApprovalSaga),
        takeEvery(actionTypes.SUBMIT_CLIENT_APPROVAL_MULTIPLE, submitClientApprovalMultipleSaga),
        takeEvery(actionTypes.GET_CLIENT_APPROVAL_DATA_LIST_ACTIVATION, getDataClientApprovalListActivationSaga),
        takeEvery(actionTypes.GET_DATA_MEMBER_TEMPLATE, getDataMemberTemplateSaga),
        takeEvery(actionTypes.CHECK_DUPLICATE_MEMBER, getCheckDuplicateMemberSaga),
        takeEvery(actionTypes.SUBMIT_ADD_ENTITY_MEMBER, submitAddMemberEntitySaga),
        takeEvery(actionTypes.GET_DATA_ENTITY_MEMBER, getDataEntityMemberSaga),
        takeEvery(actionTypes.GET_DATA_PERSON_MEMBER, getDataPersonMemberSaga),
        takeEvery(actionTypes.GET_DATA_MEMBER_WITH_PARAM, getDataMemberWithParamSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_ENTITY_MEMBER, submitEditMemberEntitySaga),
        takeEvery(actionTypes.SUBMIT_UPDATE_EMPLOYEE_INFORMATION_DATA_IMPORT, submitUpdateEmployeeInformationSaga),
        takeEvery(actionTypes.SUBMIT_DATA_IMPORT_UPLOAD_CLIENT, submitDataImportUpdateClientSaga),
        takeEvery(actionTypes.GET_DATA_MEMBER, getDataMemberParamSaga),
        takeEvery(actionTypes.GET_TRANSFER_CLIENT_HISTORY, getTransferClientHistorySaga),
        takeEvery(actionTypes.SUBMIT_COMMAND_MEMBER_POST_MULTIPLE, submitMemberCommandPostMultipleSaga),
        
    ]);
}

export function* watchMemberDetail() {
    yield all([
        takeEvery(actionTypes.INIT_GET_MEMBER_ID, initGetMemberDetailSaga),
        takeEvery(actionTypes.INIT_GET_MEMBER_IMAGE, initGetMemberImageSaga),
        takeEvery(actionTypes.INIT_GET_MEMBER_ACCOUNT, initGetMemberAccountSaga),
        takeEvery(actionTypes.INIT_GET_MEMBER_SUMMARY, initGetMemberSummarySaga),
        takeEvery(actionTypes.INIT_GET_MEMBER_DOC, initGetMemberDocSaga),
        takeEvery(actionTypes.INIT_DELETE_MEMBER_IMAGE, initDeleteMemberImageSaga),
        takeEvery(actionTypes.INIT_DELETE_MEMBER_DOC, initDeleteMemberDocSaga),
        takeEvery(actionTypes.INIT_GET_DOCUMENT, initGetDocumentSaga),
    ]);
}

export function* watchMemberEdit() {
    yield all([
        takeEvery(actionTypes.INIT_GET_MEMBER_EDIT, initGetMemberEditSaga),
        takeEvery(actionTypes.INIT_MEMBER_EDIT, initMemberEditSaga)
    ])
}

export function* watchSavingDetail() {
    yield all([
        takeEvery(actionTypes.INIT_GET_MEMBER_SAVING_DETAIL, initGetMemberSavingDetailSaga),
        takeEvery(actionTypes.INIT_GET_MEMBER_DEPOSIT_DETAIL, initGetMemberDepositDetailSaga),
        takeEvery(actionTypes.INIT_GET_MEMBER_SAVING_QR, initGetMemberSavingQRImageSaga),
    ]);
}

export function* watchSaving() {
    yield all([
        takeEvery(actionTypes.INIT_GET_SAVING_ACC_TRANSFER, initGetSavingAccountTransferSaga),
        takeEvery(actionTypes.INIT_GET_SAVING_ACC_TX, initGetSavingAccountTransactionSaga),
        takeEvery(actionTypes.INIT_GET_PRODUCT_SAVING, initGetProductSavingSaga),
        takeEvery(actionTypes.INIT_POST_SAVING_ACC, initPostSavingAccSaga),
        takeEvery(actionTypes.INIT_GET_LIST_SAVING, initGetListSavingSaga),
        takeEvery(actionTypes.GET_FIND_ACCOUNT_AND_GL, getFindAccountAndGLSaga),
        takeEvery(actionTypes.GET_ALL_CODE_TRX, getAllCodeTrxSaga),
        takeEvery(actionTypes.GET_ALL_CODE_TRX_V2, getAllCodeTrxV2Saga),
        takeEvery(actionTypes.SAVINGS_GENERAL_TRANSACTION, savingsGeneralTransactionSaga),
        takeEvery(actionTypes.GET_FEE_BY_TRX_MODE, getFeeByTrxModeSaga),
        takeEvery(actionTypes.INIT_GET_LIST_SAVINGS_BY_ACCOUNT_NUMBER, GetListSavingsByAccountNumberSaga),
        takeEvery(actionTypes.INIT_GET_LIST_SAVINGS_INTERMEDIATE, getAllSavingsInteremediateSaga),
        takeEvery(actionTypes.INIT_GET_DETAIL_CORE_TRANS_DETAIL, getCoreTransDetailSaga),
        takeEvery(actionTypes.GET_SAVING_DETAIL_ASSOCIATIONS_EMPTY, GetSavingDetailAssociationsEmptySaga),
        
        takeEvery(actionTypes.SAVINGS_ACTION_BY_COMMAND, SavingsAccountByCommandSaga),
        takeEvery(actionTypes.INIT_GET_DETAIL_CORE_TRANS_DETAIL_PARAM, getCoreTransDetailParamSaga),
        takeEvery(actionTypes.INIT_GET_LIST_SAVINGS_INTERMEDIATE_PARAM, getAllSavingsInteremediateParamSaga),
        takeEvery(actionTypes.TRANSACTION_BY_COMMAND, transactionByCommandSaga),
        takeEvery(actionTypes.GET_SAVING_PRODUCT_BY_ID, getProductSavingByIDSaga),
        takeEvery(actionTypes.GET_INTEREST_CLOSE_ACCOUNT, getInterestCloseAccountSaga),
        takeEvery(actionTypes.GET_MAINTENANCE_SAVINGS, getMaintenanceSavingsSaga),
        takeEvery(actionTypes.GET_MAINTENANCE_CHARGES_SAVINGS, getMaintenanceChargesSavingsSaga),
        takeEvery(actionTypes.SUBMIT_MAINTENANCE_SAVINGS, submitMaintenanceSavingSaga),
        
        takeEvery(actionTypes.GET_SAVINGPRODUCT_TEMPLATE_BY_ID, getSavingProductTemplateByIDSaga),
        takeEvery(actionTypes.FIXDEPOSIT_BY_ID_AND_COMMAND, fixDepositByIdAndCommandSaga),
        takeEvery(actionTypes.GET_GL_ACCOUNT_IS_GENERAL_TRANS_TRUE, getGlAccountIsGeneralTransTrueSaga),  
        takeEvery(actionTypes.GET_FIXED_DEPOSIT_BY_QUERY_PARAM, getFixedDepositByQueryParamSaga),
        takeEvery(actionTypes.SUBMIT_MAINTENANCE_TERM_SAVINGS, submitMaintenanceTermSavingsSaga),
        takeEvery(actionTypes.SUBMIT_ADJUSMENT_TERM_SAVINGS, submitAdjusmentTermSavingsSaga),
        takeEvery(actionTypes.GET_FIND_ACCOUNTNUMBER, getFindAccountNumberSaga),
        takeEvery(actionTypes.GET_LIST_PAYLATER_BY_QUERYPARAM, getListPaylaterByParamSaga),
        
        takeEvery(actionTypes.GET_DETAIL_PAYCHARGE_SAVINGS, getDetailPayChargeSavingsSaga),
        takeEvery(actionTypes.SUBMIT_PAYCHARGE_SAVINGS, submitPayChargeSavingsSaga),
        takeEvery(actionTypes.EDIT_SAVINGS_ACCOUNT, editSavingsAccountSaga),
        takeEvery(actionTypes.GET_DATA_BLOCK_ACCOUNTS, getDataBlockedAccountSaga),
        takeEvery(actionTypes.SUBMIT_ADD_BLOCK_ACCOUNTS, submitAddBlockAccountSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_BLOCK_ACCOUNTS, submitEditBlockAccountSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_BLOCK_ACCOUNTS, submitDeleteBlockAccountSaga),        
        takeEvery(actionTypes.GET_INTERMEDIARY_WITH_QUERYPARAM, getListIntermediaryByParamSaga),
        takeEvery(actionTypes.GET_FIXDEPOSITACC_DATA, getFixDepositAccDataSaga),
        takeEvery(actionTypes.GET_DATA_SAVINGS_PRODUCT, getSavingProductDataSaga),
        takeEvery(actionTypes.SUBMIT_UPDATE_SPECIAL_RATE, submitUpdateSpecialRateSaga),
        takeEvery(actionTypes.SUBMIT_RESET_SPECIAL_RATE, submitResetSpecialRateSaga),
        
        takeEvery(actionTypes.GET_DATA_TYPE_PASSBOOK, getDataTypePassbookSaga),
        takeEvery(actionTypes.SUBMIT_ADD_SAVINGS_PRODUCT, submitAddSavingsProductSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_SAVINGS_PRODUCT, submitEditSavingsProductSaga),
        takeEvery(actionTypes.GET_DATA_PAYLATER, getDataPaylaterByQueryParamSaga),
        takeEvery(actionTypes.SUBMIT_DATA_IMPORT_PAYLATER, submitDataImportPaylaterSaga),
        takeEvery(actionTypes.SUBMIT_SAVING_TRANSACTION, submitSavingsTransactionSaga),
        takeEvery(actionTypes.GET_SAVINGS_ACCOUNT_PARAM, getSavingSAccountParamSaga),
        takeEvery(actionTypes.GET_SAVING_TEMPLATE_PAYLATER, getSavingTemplatePaylaterSaga),
        takeEvery(actionTypes.GET_LIST_SAVINGS_ACCOUNT_PARAM, getListSavingByParamSaga),
        takeEvery(actionTypes.GET_PRINT_PASSBOOK, getPrintPassbookParamSaga),
        takeEvery(actionTypes.GET_TERMSAVING_PAGING, getTermSavingListPagingSaga),
        takeEvery(actionTypes.GET_DEPOSIT_RECURRING_DATA, getRecurringDepositSaga),
        takeEvery(actionTypes.GET_SAVING_DEPOSIT_RECURRING_DATA, getListSavingRecurringByParamSaga),
        takeEvery(actionTypes.GET_ACCOUNT_TRANSFER_DATA, getAccountTransferDataSaga),
        takeEvery(actionTypes.SUBMIT_DEPOSIT_RECURRING, submitRecurringDepositSaga),
        takeEvery(actionTypes.GET_FIX_DEPOSIT_PARAM, getFixDepositAccountDataSaga),
        takeEvery(actionTypes.SUBMIT_POST_FIX_DEPOSIT_PARAM, submitFixDepositAccountSaga),
        takeEvery(actionTypes.GET_FIND_SAVINGRECURRING, getFindSavingRecurringSaga),
        takeEvery(actionTypes.EDIT_DEPOSIT_RECURRING, editRecurringDepositSaga),
        takeEvery(actionTypes.SUBMIT_SAVINGS_CHARGES, submitSavingsChargesSaga),
        takeEvery(actionTypes.SUBMIT_MAINTENANCE_SAVINGS_CHARGES, submitMaintenanceSavingsChargesSaga),
        takeEvery(actionTypes.GET_DATA_SAVINGS_TRANSACTION, getSavingsTransactionSaga),
    ])
}

export function* watchDeposit() {
    yield all([
        takeEvery(actionTypes.INIT_GET_DEPOSIT_ACC_TX, initGetDepositAccountTransactionSaga),
        takeEvery(actionTypes.INIT_GET_DEPOSIT_ACC_TRANSFER, initGetDepositAccountTransferSaga),
        takeEvery(actionTypes.GET_DEPOSIT_TEMPLATE, getDepositTemplateSaga),
        takeEvery(actionTypes.GET_DEPOSIT_CHART, getDepositChartSaga),
        takeEvery(actionTypes.DEPOSIT_CREATE, depositCreateSaga),
        takeEvery(actionTypes.GET_LIST_DEPOSIT_BILLER, getDepositBillerSaga),
        takeEvery(actionTypes.SUBMIT_ADD_DEPOSIT_BILLER, submitaddDepositBillerSaga),
        takeEvery(actionTypes.SUBMIT_CANCEL_DEPOSIT_BILLER, submitcancelDepositBillerSaga),
        takeEvery(actionTypes.SUBMIT_ADD_LIST_DEPOSIT_BILLER_PHOTO, submitaddListDepositBillerPhotoSaga),
    ])
}

export function* watchDashboard() {
    yield all([
        takeEvery(actionTypes.INIT_FETCH_DASHBOARD_GENDER, fetchDashboardGenderSaga),
        takeEvery(actionTypes.INIT_FETCH_DASHBOARD_AGE, fetchDashboardAgeSaga),
        takeEvery(actionTypes.INIT_FETCH_DASHBOARD_PROVINCE, fetchDashboardProvinceSaga),
        takeEvery(actionTypes.INIT_FETCH_DASHBOARD_CLIENTMONTH, fetchDashboardClientMonthSaga),
        takeEvery(actionTypes.INIT_FETCH_DASHBOARD_LOAN, fetchDashboardLoanSaga),
        takeEvery(actionTypes.GET_DASHBOARD_TOTAL_APEX, fetchDashboardTotalApexSaga),
        takeEvery(actionTypes.GET_DASHBOARD_MEMBER_APEX, fetchDashboardMemberApexSaga),
        takeEvery(actionTypes.GET_DASHBOARD_CITY_APEX, fetchDashboardCityApexSaga),
        takeEvery(actionTypes.GET_DASHBOARD_CLIENTTENANT_APEX, fetchDashboardClientTenantApexSaga),
        takeEvery(actionTypes.GET_DASHBOARD_GEOCITY_APEX, fetchDashboardGeoCityTenantApexSaga),
        takeEvery(actionTypes.GET_DASHBOARD_SYNC, fetchDashboardSyncApexSaga),
        takeEvery(actionTypes.GET_DASHBOARD_ACTIVE_MOBILE_USER, fetchDashboardActiveMobileUserApexSaga),
        takeEvery(actionTypes.GET_DASHBOARD_AGE_APEX, getDashboardAgeSaga),
        takeEvery(actionTypes.GET_DASHBOARD_GENDER_APEX, getDashboardGenderSaga),
        takeEvery(actionTypes.GET_DASHBOARD_LOAN_APEX, getDashboardLoanSaga),
        takeEvery(actionTypes.GET_DASHBOARD_LOAN_DISBURSE_APEX, getDashboardLoanDisburseSaga),
        takeEvery(actionTypes.GET_DATA_DASHBOARD_TOKEN, getDataDashboardTokenParamSaga)
    ])
}

export function* watchGeneral() {
    yield all([
        takeEvery(actionTypes.GET_OFFICE, getOfficeSaga),
        takeEvery(actionTypes.GET_CURRENCY, getCurrencySaga),
        takeEvery(actionTypes.GET_GL_ACCOUNT_BY_QUERYPARAM, getGlAccountByQueryParamSaga),
        takeEvery(actionTypes.GET_CHARGES, getChargeSaga),
        takeEvery(actionTypes.GET_CHARGES_WITH_QUERYPARAM, getChargeWithQueryParamSaga),
        takeEvery(actionTypes.DELETE_CHARGES, deleteChargeSaga),
        takeEvery(actionTypes.SUBMIT_CHARGES, submitChargeSaga),
        takeEvery(actionTypes.GET_DETAIL_EDIT_CHARGES, getChargeEditWithQueryParamSaga),
        takeEvery(actionTypes.EDIT_CHARGES, editChargeSaga),
        takeEvery(actionTypes.GET_VALUE_RUNREPORT, getValueRunReportsSaga),
        takeEvery(actionTypes.SUBMIT_BATCHES, submitBatchesSaga),
        takeEvery(actionTypes.GET_ALL_CODE_TRX_NON_CASH, getAllCodeTrxNonCashSaga),
        takeEvery(actionTypes.GET_PRODUCT_GROUP_PPOB, getProductGroupPPOBSaga),
        takeEvery(actionTypes.GET_FUNDS_WITH_PARAM, getFundsWithParamSaga),
        takeEvery(actionTypes.GET_CURRENCY_WITH_PARAM, getCurrencyWithParamSaga),
        takeEvery(actionTypes.GET_ALL_CODE_TRX_CASH, getAllCodeTrxCashSaga),
        takeEvery(actionTypes.GET_DATA_DENOMINATION, getDataDenominationSaga),
        takeEvery(actionTypes.GET_DATA_FINANCIALACTIVITYACCOUNT, getDataFinancialActivitySaga),
        takeEvery(actionTypes.SUBMIT_ADD_DENOMiNATION, submitAddDenominationSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_DENOMiNATION, submitEditDenominationSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_DENOMiNATION, submitDeleteDenominationSaga),
        takeEvery(actionTypes.GET_DATA_MAKERCHEKER, getDataMakerChekerSaga),
        takeEvery(actionTypes.SUBMIT_MAKER_CHEKER_MULTIPLE, submitMakerChekerMultipleSaga),
        takeEvery(actionTypes.GET_DATA_AUDITS, getDataAuditsSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_MAKERCHEKER, submitDeleteMakerChekeSaga),
        takeEvery(actionTypes.GET_DATA_TELLER_DASHBOARD, getDataTellerDashboardSaga),
        takeEvery(actionTypes.SUBMIT_ADD_TELLER_OPERATION, submitAddTellerOperationSaga),
        takeEvery(actionTypes.SUBMIT_EDIT_TELLER_OPERATION, submitEditTellerOperationSaga),
        takeEvery(actionTypes.SUBMIT_DELETE_TELLER_OPERATION, submitDeleteTellerOperationSaga),
        takeEvery(actionTypes.SUBMIT_SETTLE_TELLER, submitSettleTellerSaga),
        takeEvery(actionTypes.SUBMIT_ALLOCATE_TELLER, submitAllocateTellerSaga),
        takeEvery(actionTypes.GET_QUICK_SEARCH, getDataQuickSearchSaga),
        takeEvery(actionTypes.GET_RUN_REPORT_CHECK, getRunreportCheckSaga),
        takeEvery(actionTypes.GET_DATA_TELLER_DASHBOARD_PARAM, getDataTellerDashboardParamSaga),
        takeEvery(actionTypes.GET_DATA_FORMAT_REPORT_CODE, getDataFormatReportsCodeSaga),
    ])
}

export function* watchMobile() {
    yield all([
        takeEvery(actionTypes.GET_MOBILE_MENU_DATA, getMobileMenuDataSaga),
        takeEvery(actionTypes.SUBMIT_MOBILE_MENU, submitMobileMenuSaga),
        takeEvery(actionTypes.GET_MOBILE_VERSION_DATA, getMobileVersionDataSaga),
        takeEvery(actionTypes.SUBMIT_MOBILE_VERSION_DATA, submitMobileVersionSaga),
        takeEvery(actionTypes.GET_MOBILE_COMMERCE_DATA, getMobileCommerceDataSaga),
        takeEvery(actionTypes.SUBMIT_MOBILE_COMMERCE, submitMobileCommerceSaga),
    ])
}

export function* watchDataImport() {
    yield all([
        takeEvery(actionTypes.SUBMIT_DATA_IMPORT_FILE_FINANCING_REPAYMENT, submitDataImportFileFinancingRepaymentSaga),
        takeEvery(actionTypes.GET_TEMPLATE_SIMPOOL_BULK, getTemplateSimpoolBulkSaga),
        takeEvery(actionTypes.UPLOAD_FILE_SIMPOOL_BULK, uploadFileSimpoolBulkSaga),
        takeEvery(actionTypes.DOWNLOAD_FILE_RESULT_SIMPOOL_BULK, downloadFileResultSimpoolBulkSaga),
        takeEvery(actionTypes.PROGRESS_SIMPOOL_BULK, progressSimpoolBulkSaga),
        takeEvery(actionTypes.LIST_SIMPOOL_BULK, getListSimpoolBulkSaga),
    ])
}