import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
// import * as actions from '../actions';
import {
    baseAutoSweepURL,baseAutoSweepGroupURL,baseAutoSweepBulkURL
}                   from '../../shared/apiUrl';

import {toLogout} from '../../shared/maskFunc';

export function* getDataAutoSweepSaga(action) {    
    try {
        const response = yield axios.get(baseAutoSweepURL+action.param).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddAutoSweepSaga(action) {
    try {
        const response = yield axios.post(baseAutoSweepURL,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteAutoSweepSaga(action) {
    try {
        const response = yield axios.delete(baseAutoSweepURL+'/'+action.id).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorHandler(errMessages);
    }
}

export function* submitEditAutoSweepSaga(action) {
    try {
        const response = yield axios.put(baseAutoSweepURL+'/'+action.id,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataAutoSweepGroupSaga(action) {    
    try {
        const response = yield axios.get(baseAutoSweepGroupURL+action.param).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddAutoSweepGroupSaga(action) {
    try {
        const response = yield axios.post(baseAutoSweepGroupURL,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteAutoSweepGroupSaga(action) {
    try {
        const response = yield axios.delete(baseAutoSweepGroupURL+'/'+action.id).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorHandler(errMessages);
    }
}

export function* submitEditAutoSweepGroupSaga(action) {
    try {
        const response = yield axios.put(baseAutoSweepGroupURL+'/'+action.id,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataAutoSweepBulkSaga(action) {    
    try {
        const response = yield axios.get(baseAutoSweepBulkURL+action.param).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAutoSweepBulkSaga(action) {
    try {
        const response = yield axios.post(baseAutoSweepBulkURL,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorHandler(errMessages);
    }
}


export function* submitUpdateAutoSweepBulkSaga(action) {
    try {
        const response = yield axios.put(baseAutoSweepBulkURL,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}