import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    getListUserByParamURL,getUserTemplateURL,submitInternalUserURL,
    detailInternalUserURL,updateInternalUserURL,getOTPChangePassowrdInternalUserURL,
    submitChangePassowrdInternalUserURL,getOTPUnlockInternalUserURL,submitUnlockInternalUserURL,
    unitUserURL,submitInternalLockUserURL,userParamUrl
} from '../../shared/apiUrl';
import { toLogout,msgErrorHandler } from '../../shared/maskFunc';

export function* getAllInternaUserSaga(action) {
    try {
        const response = yield axios.get(getListUserByParamURL(action.queryparam)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getUserTemplateSaga(action) {
    try {
        const response = yield axios.get(getUserTemplateURL).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitInternalUserSaga(action) {
    try {
        const response = yield axios.post(submitInternalUserURL,action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* detailInternalUserSaga(action) {
    try {
        let queryparam = action.queryparam !== ''?'?'+action.queryparam:'';
        const response = yield axios.get(detailInternalUserURL(action.userid,queryparam)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* updateInternalUserSaga(action) {
    try {
        const response = yield axios.put(updateInternalUserURL(action.userid),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* deleteInternalUserSaga(action) {
    try {
        const response = yield axios.delete(updateInternalUserURL(action.userid)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getOtpChangePassowrdInternalUserSaga(action) {
    try {
        const response = yield axios.get(getOTPChangePassowrdInternalUserURL(action.userid)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitChangePasswordInternalUserSaga(action) {
    try {
        const response = yield axios.post(submitChangePassowrdInternalUserURL(action.userid),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        action.errorHandler(error);
        // if(error.data.errors !== null && error.data.errors !== undefined){
        //     const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        //     action.errorHandler(errMessages.join('\n'));
        // }else{
        //     const errMessages = yield error.data.defaultUserMessage;//reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        //     action.errorHandler(errMessages);
        // }
        
    }
}

export function* getOtpUnlockInternalUserSaga(action) {
    try {
        const response = yield axios.post(getOTPUnlockInternalUserURL(action.userid),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitUnlockInternalUserSaga(action) {
    try {
        const response = yield axios.post(submitUnlockInternalUserURL(action.userid),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* getDataUnitUserSaga(action) {
    try {
        const response = yield axios.get(unitUserURL(action.param)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitAddUnitUserSaga(action) {
    try {
        const response = yield axios.post(unitUserURL(''),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response,action.memberid);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditUnitUserSaga(action) {
    try {
        const response = yield axios.put(unitUserURL('/'+action.id),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteUnitUserSaga(action) {
    try {
        const response = yield axios.delete(unitUserURL('/'+action.id)).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitLockInternalUserSaga(action) {
    try {
        const response = yield axios.post(submitInternalLockUserURL(action.id,action.typelock),action.payload).then(response => response.data);
        //officeId,resourceId,isTellerTransaction
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getInternalUserListPagingSaga(action) {
    try {
        const response = yield axios.get(userParamUrl(action.queryparam)).then(response => response.data);
        action.successHandler(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}