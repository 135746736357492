import * as actions from './actionTypes';

export const getDataAutoSweep = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_AUTO_SWEEP,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddAutoSweep = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_AUTOSWEEP,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteAutoSweep = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_AUTOSWEEP,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitEditAutoSweep = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_AUTOSWEEP,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataAutoSweepGroup = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_AUTO_SWEEP_GROUP,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddAutoSweepGroup = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_AUTOSWEEPGROUP,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteAutoSweepGroup = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_AUTOSWEEPGROUP,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitEditAutoSweepGroup = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_AUTOSWEEPGROUP,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataAutoSweepBulk = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_AUTO_SWEEP_BULK,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAutoSweepBulk = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_AUTO_SWEEP_BULK,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitUpdateAutoSweepBulk = (payload,successHandler, errorHandler) => {
    return {
        type: actions.UPDATE_AUTO_SWEEP_BULK,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

