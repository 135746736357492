import * as actions from './actionTypes';

export const getDataPassbookType = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_PASSBOOKTYPE,
        param:param,
        successHandler,
        errorHandler,
    }
};
export const getDataReferralCode = (param,template,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_REFERRAL_CODE,
        param:param,
        template:template,
        successHandler,
        errorHandler,
    }
};

export const submitAddPassbookType = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_PASSBOOKTYPE,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitAddReferralCode = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_REFERRAL_CODE,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeletePassbookType = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_PASSBOOKTYPE,
        id:id,
        successHandler,
        errorHandler,
    }
};
export const submitDeleteReferralCode = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_REFERRAL_CODE,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitResetPassbook = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_RESET_PASSBOOK,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitPrintPassbook = (accnumber,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_PRINT_PASSBOOK,
        accnumber:accnumber,
        successHandler,
        errorHandler,
    }
};

export const submitPrintPassbookCover = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_PRINT_PASSBOOKCOVER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitPassbookCover = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_PASSBOOKCOVER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitPassbookPost = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_PRINT_PASSBOOK_POST,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitRePrintPassbook = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_RE_PRINT_PASSBOOK,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const submitEditReferralCode = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_REFERRAL_CODE,
        payload:payload,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const getProductTemplateData = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_PRODUCTTEMPLATE_DATA,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataLoanPassbookType = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_LOAN_PASSBOOKTYPE,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddProductTemplate = (payload,param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_PRODUCTTEMPLATE,
        payload:payload,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddLoanPassbookType = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_LOAN_PASSBOOKTYPE,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteLoanPassbookType = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_LOAN_PASSBOOKTYPE,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitEditLoanPassbookType = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_LOAN_PASSBOOKTYPE,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataTelleLimit = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_TELLER_LIMIT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditProductTemplate = (payload,param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_PRODUCTTEMPLATE,
        payload:payload,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddTelleLimit = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_TELLER_LIMIT,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditTelleLimit = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_TELLER_LIMIT,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteTelleLimit = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_TELLER_LIMIT,
        param:param,
        successHandler,
        errorHandler,
    }
};


export const submitPrintPassbookParameter = (accnumber,param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_PRINT_PASSBOOK_PARAM,
        param:param,
        accnumber:accnumber,
        successHandler,
        errorHandler,
    }
};

export const getProductGroupData = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_PRODUCT_GROUP,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitProductGroup = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_PRODUCT_GROUP,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitProductGroupDetails = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_PRODUCT_GROUP_DETAILS,
        param:param,
        successHandler,
        errorHandler,
    }
};