import * as actionTypes from '../actions/actionTypes';
import {updateObject}   from "../../shared/utility";
import moment           from 'moment';

const initialState = {
    rows: [],
    error: false,
    lastUpdate: null,
};

const fetchSavingList = (state, action) => {
    return updateObject(state, {
        rows: action.data,
        error: false,
        lastUpdate: moment()
    })
};


const reduce = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SAVING_LIST:
            return fetchSavingList(state, action);
        default:
            return state;
    }
};

export default reduce;