import * as actions from './actionTypes';

export const setListMerchant = (data) => {
    return {
        type: actions.FETCH_MERCHANT_LIST,
        data: data,
    };
};

export const initGetListMerchant = (tenantid,successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_MERCHANT,
        tenantid:tenantid,
        successCallback,
        errorCallback
    }
};

export const getDetailMerchant = (id,successCallback, errorCallback) => {
    return {
        type: actions.GET_MERCHANT_DETAIL,
        id:id,
        successCallback,
        errorCallback
    }
};

export const getMerchantPayment = (payload,successCallback, errorCallback) => {
    return {
        type: actions.GET_MERCHANT_PAYMENT,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getMerchantQris = (payload,successCallback, errorCallback) => {
    return {
        type: actions.GET_MERCHANT_QRIS,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const createMerchantSimpoolPaymentQR = (payload,successCallback, errorCallback) => {
    return {
        type: actions.CREATE_MERCHANT_SIMPOOLPAYMENT_QR,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getMerchantTemplate = (tenantid,param,successCallback, errorCallback) => {
    return {
        type: actions.GET_MERCHANT_TEMPLATE,
        tenantid:tenantid,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getMerchantSavings = (tenantid,savingsacc,successCallback, errorCallback) => {
    return {
        type: actions.GET_MERCHANT_SAVING,
        tenantid:tenantid,
        savingsacc:savingsacc,
        successCallback,
        errorCallback
    }
};

export const submitAddMerchant = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_MERCHANT,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getDataMerchant = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_MERCHANT,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitEditMerchant = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_EDIT_MERCHANT,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitRejectMerchant = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_REJECT_MERCHANT,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitApproveMerchant = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_APPROVE_MERCHANT,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitAddReCreateMerchant = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_RECREATE_MERCHANT,
        payload:payload,
        successCallback,
        errorCallback
    }
};