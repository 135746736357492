import * as actions from './actionTypes';

export const initSavingAccountTransaction = (clientId, id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_SAVING_ACC_TX,
        clientId: clientId,
        id: id,
        successCallback,
        errorCallback
    }
};
export const savingAccountTransactionStart = () => {
    return {
        type: actions.GET_SAVING_ACC_TX_START
    }
};
export const savingAccountTransactionFailed = () => {
    return {
        type: actions.GET_SAVING_ACC_TX_FAILED
    }
};
export const savingAccountTransactionSuccess = () => {
    return {
        type: actions.GET_SAVING_ACC_TX_SUCCESS
    }
};

export const initSavingAccountTransfer = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_SAVING_ACC_TRANSFER,
        id: id,
        successCallback,
        errorCallback
    }
};
export const savingAccountTransferStart = () => {
    return {
        type: actions.GET_SAVING_ACC_TRANSFER_START
    }
};
export const savingAccountTransferFailed = () => {
    return {
        type: actions.GET_SAVING_ACC_TRANSFER_FAILED
    }
};
export const savingAccountTransferSuccess = () => {
    return {
        type: actions.GET_SAVING_ACC_TRANSFER_SUCCESS
    }
};

export const initPostSavingAccount = (payload, successCallback, errorCallback) => {
    return {
        type: actions.INIT_POST_SAVING_ACC,
        payload,
        successCallback,
        errorCallback
    }
};
export const postSavingAccountStart = () => {
    return {
        type: actions.POST_SAVING_ACC_START
    }
};
export const postSavingAccountFailed = () => {
    return {
        type: actions.POST_SAVING_ACC_FAILED
    }
};
export const postSavingAccountSuccess = () => {
    return {
        type: actions.POST_SAVING_ACC_SUCCESS
    }
};

export const initGetProductSaving = (clientId, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_PRODUCT_SAVING,
        clientId: clientId,
        successCallback,
        errorCallback
    }
};
export const getProductSavingStart = () => {
    return {
        type: actions.GET_PRODUCT_SAVING_START
    }
};
export const getProductSavingFailed = () => {
    return {
        type: actions.GET_PRODUCT_SAVING_FAILED
    }
};
export const getProductSavingSuccess = () => {
    return {
        type: actions.GET_PRODUCT_SAVING_SUCCESS
    }
};

export const setListSavings = (data) => {
    return {
        type: actions.FETCH_SAVING_LIST,
        data: data,
    };
};

export const initGetListSaving = (successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_SAVING,
        successCallback,
        errorCallback
    }
};
export const getListSavingStart = () => {
    return {
        type: actions.GET_LIST_SAVING_START
    }
};
export const getListSavingFailed = () => {
    return {
        type: actions.GET_LIST_SAVING_FAILED
    }
};
export const getListSavingSuccess = () => {
    return {
        type: actions.GET_LIST_SAVING_SUCCESS
    }
};

export const getFindAccountANDGL = (accountnumber,kodetrx,successCallback, errorCallback) => {
    return {
        type: actions.GET_FIND_ACCOUNT_AND_GL,
        accountnumber:accountnumber,
        kodetrx:kodetrx,
        successCallback,
        errorCallback
    }
};

export const getAllCodeTrx = (successCallback, errorCallback) => {
    return {
        type: actions.GET_ALL_CODE_TRX,
        successCallback,
        errorCallback
    }
};

export const savingGeneralTransaction = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SAVINGS_GENERAL_TRANSACTION,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const GetFeeByTrxMode = (trxmode,successCallback, errorCallback) => {
    return {
        type: actions.GET_FEE_BY_TRX_MODE,
        trxmode:trxmode,
        successCallback,
        errorCallback
    }
};

export const GetListSavingsByAccountNumber = (accountnumber,successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_SAVINGS_BY_ACCOUNT_NUMBER,
        accountnumber:accountnumber,
        successCallback,
        errorCallback
    }
};

export const getListSavingsIntermediate = (successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_SAVINGS_INTERMEDIATE,
        successCallback,
        errorCallback
    }
};

export const GetCoreTransDetail = (coreid,successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_DETAIL_CORE_TRANS_DETAIL,
        coreid:coreid,
        successCallback,
        errorCallback
    }
};
export const SavingsAccountActionByCommand = (savingsid,command,payload,successCallback, errorCallback) => {
    return {
        type: actions.SAVINGS_ACTION_BY_COMMAND,
        savingsid:savingsid,
        command:command,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const GetCoreTransDetailAddParam = (param,successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_DETAIL_CORE_TRANS_DETAIL_PARAM,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getListSavingsIntermediateParam = (param,successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_SAVINGS_INTERMEDIATE_PARAM,
        param:param,
        successCallback,
        errorCallback
    }
}
export const GetSavingProductByID = (productid,successCallback, errorCallback) => {
    return {
        type: actions.GET_SAVING_PRODUCT_BY_ID,
        productid:productid,
        successCallback,
        errorCallback
    }
}
export const getSavingDetailAssociationsEmpty = (savingid,dataloan,successCallback, errorCallback) => {
    return {
        type: actions.GET_SAVING_DETAIL_ASSOCIATIONS_EMPTY,
        savingid:savingid,
        dataloan:dataloan,
        successCallback,
        errorCallback
    }
};

export const getInterestCloseAccount = (savingid,successCallback, errorCallback) => {
    return {
        type: actions.GET_INTEREST_CLOSE_ACCOUNT,
        savingid:savingid,
        successCallback,
        errorCallback
    }
};
export const getAllCodeTrxV2 = (successCallback, errorCallback) => {
    return {
        type: actions.GET_ALL_CODE_TRX_V2,
        successCallback,
        errorCallback
    }
};

export const getMaintenanceSavings = (savingid,successCallback, errorCallback) => {
    return {
        type: actions.GET_MAINTENANCE_SAVINGS,
        savingid:savingid,
        successCallback,
        errorCallback
    }
};

export const getMaintenanceChargesSavings = (savingid,successCallback, errorCallback) => {
    return {
        type: actions.GET_MAINTENANCE_CHARGES_SAVINGS,
        savingid:savingid,
        successCallback,
        errorCallback
    }
};
export const transactionByCommand = (payload,command,successCallback, errorCallback) => {
    return {
        type: actions.TRANSACTION_BY_COMMAND,
        payload:payload,
        command:command,
        successCallback,
        errorCallback
    }
};

export const SubmitMaintenanceSavings = (savingsid,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_MAINTENANCE_SAVINGS,
        savingsid:savingsid,
        payload:payload,
        successCallback,
        errorCallback
    }
};
export const getsavingproductTemplateByID = (clientid,productid,successCallback, errorCallback) => {
    return {
        type: actions.GET_SAVINGPRODUCT_TEMPLATE_BY_ID,
        productid:productid,
        clientid:clientid,
        successCallback,
        errorCallback
    }
};

export const fixDepositByIdAndCommand = (id,command,payload,successCallback, errorCallback) => {
    return {
        type: actions.FIXDEPOSIT_BY_ID_AND_COMMAND,
        id:id,
        command:command,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getGlAccountIsGeneralTransTrue = (successCallback, errorCallback) => {
    return {
        type: actions.GET_GL_ACCOUNT_IS_GENERAL_TRANS_TRUE,
        successCallback,
        errorCallback
    }
};

export const getFixDepositByQueryParam = (id,queryparam,successCallback, errorCallback) => {
    return {
        type: actions.GET_FIXED_DEPOSIT_BY_QUERY_PARAM,
        id:id,
        queryparam:queryparam,
        successCallback,
        errorCallback
    }
};

export const submitMaintenanceTermSavings = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_MAINTENANCE_TERM_SAVINGS,
        id:id,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitAdjusmentTermSavings = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADJUSMENT_TERM_SAVINGS,
        id:id,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getFindAccountNumber = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_FIND_ACCOUNTNUMBER,
        param:param,
        successCallback,
        errorCallback
    }
};
export const getListPaylaterByQueryParam = (queryparam,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_PAYLATER_BY_QUERYPARAM,
        actiontype:actiontype,
        queryparam:queryparam,
        successCallback,
        errorCallback
    }
};
export const getDetailPayChargeSavings = (savingid,paychargeid,command,successCallback, errorCallback) => {
    return {
        type: actions.GET_DETAIL_PAYCHARGE_SAVINGS,
        savingid:savingid,
        paychargeid:paychargeid,
        command:command,
        successCallback,
        errorCallback
    }
};

export const submitPayChargeSavings = (savingid,paychargeid,command,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_PAYCHARGE_SAVINGS,
        savingid:savingid,
        paychargeid:paychargeid,
        command:command,
        payload:payload,
        successCallback,
        errorCallback
    }
};


export const editSavingAccount = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.EDIT_SAVINGS_ACCOUNT,
        id:id,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getDataBlockedAccount = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_BLOCK_ACCOUNTS,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitAddBlockAccount = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_BLOCK_ACCOUNTS,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitEditBlockAccount = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_EDIT_BLOCK_ACCOUNTS,
        id:id,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitDeleteBlockAccount = (id,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_DELETE_BLOCK_ACCOUNTS,
        id:id,
        successCallback,
        errorCallback
    }
};

export const getListIntermediaryByQueryParam = (queryparam,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.GET_INTERMEDIARY_WITH_QUERYPARAM,
        actiontype:actiontype,
        queryparam:queryparam,
        successCallback,
        errorCallback
    }
};

export const getFixDepositAccData = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_FIXDEPOSITACC_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getDataSavingProduct = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_SAVINGS_PRODUCT,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitUpdateSpecialRate = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_UPDATE_SPECIAL_RATE,
        payload:payload,
        id:id,
        successCallback,
        errorCallback
    }
};
export const getDataTypePassbook = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_TYPE_PASSBOOK,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitAddSavingsProduct = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_SAVINGS_PRODUCT,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitResetSpecialRate = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_RESET_SPECIAL_RATE,
        payload:payload,
        id:id,
        successCallback,
        errorCallback
    }
}
export const submitEditSavingsProduct = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_EDIT_SAVINGS_PRODUCT,
        payload:payload,
        id:id,
        successCallback,
        errorCallback
    }
};

export const getDataPaylater = (queryparam,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_PAYLATER,
        queryparam:queryparam,
        successCallback,
        errorCallback
    }
};
export const submitSavingTransaction = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_SAVING_TRANSACTION,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitDataImportPaylater = (id,command,payload,to,row,extractresult,listdata,listerr,listdone,dispatch,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_DATA_IMPORT_PAYLATER,
        id:id,
        row:row,
        command:command,
        payload:payload,
        listdata:listdata,
        extractresult:extractresult,
        to:to,
        listerr:listerr,
        dispatch:dispatch,
        listdone:listdone,
        successCallback,
        errorCallback
    }
};
export const getSavingAccountParam = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_SAVINGS_ACCOUNT_PARAM,
        param:param,
        successCallback,
        errorCallback
    }
};


export const getSavingTemplatePaylter = (id,successCallback, errorCallback) => {
    return {
        type: actions.GET_SAVING_TEMPLATE_PAYLATER,
        id:id,
        successCallback,
        errorCallback
    }
};

export const getListSavingByQueryParam = (queryparam,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_SAVINGS_ACCOUNT_PARAM,
        actiontype:actiontype,
        queryparam:queryparam,
        successCallback,
        errorCallback
    }
};


export const getPrintPassbookParam = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_PRINT_PASSBOOK,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getTermSavingListPaging = (queryparam,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_TERMSAVING_PAGING,
        actiontype:actiontype,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const getRecurringDepositData = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DEPOSIT_RECURRING_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getListSavingRecurringByQueryParam = (queryparam,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.GET_SAVING_DEPOSIT_RECURRING_DATA,
        actiontype:actiontype,
        queryparam:queryparam,
        successCallback,
        errorCallback
    }
};

export const getAccountTransferData = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_ACCOUNT_TRANSFER_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitDepositRecurring = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_DEPOSIT_RECURRING,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getFixDepositAccountData = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_FIX_DEPOSIT_PARAM,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitPostFixDepositAccount = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_POST_FIX_DEPOSIT_PARAM,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getFindSavingRecurring = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_FIND_SAVINGRECURRING,
        param:param,
        successCallback,
        errorCallback
    }
};

export const editDepositRecurring = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.EDIT_DEPOSIT_RECURRING,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getSavingsCharges = (savingsid,chargeId,param,successCallback, errorCallback) => {
    return {
        type: actions.GET_SAVINGS_CHARGES,
        param:param,
        savingsid:savingsid,
        chargeId:chargeId,
        successCallback,
        errorCallback
    }
};

export const submitSavingsCharges = (payload,savingsid,chargeId,param,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_SAVINGS_CHARGES,
        param:param,
        savingsid:savingsid,
        chargeId:chargeId,
        actiontype:actiontype,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitMaintenanceSavingsCharges = (payload,param,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_MAINTENANCE_SAVINGS_CHARGES,
        param:param,
        actiontype:actiontype,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getSavingTransaction = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_SAVINGS_TRANSACTION,
        param:param,
        successCallback,
        errorCallback
    }
};