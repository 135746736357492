import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {useTranslation}             from 'react-i18next';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 180,
    },
  });

  const TablerProfileRole = props => {
    const classes = useStyles();
    const {i18n} = useTranslation();
    const [rows, setRows] = useState(props.rowsschedule ?props.rowsschedule :[]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const columns = [
        { id: 'role', label: i18n.t('label_ROLE'), minWidth: 170 },
        { id: 'description', label: i18n.t('label_DESCRIPTION'), minWidth: 170 },
    ]
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
        const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
                {
                    columns.map(
                        (column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        )
                    )
                }
            </TableRow>
            </TableHead>
            <TableBody>
                {
                    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                        (row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.periode}>
                                    {
                                        columns.map(
                                            (column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                )
                                            }
                                        )
                                    }
                                </TableRow>
                            )
                        }
                    )


                }
            </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
  }
  export default TablerProfileRole;