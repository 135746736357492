import * as actions from './actionTypes';

export const initGetMemberSavingDetail = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_SAVING_DETAIL,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberSavingDetailStart = () => {
    return {
        type: actions.GET_MEMBER_SAVING_DETAIL_START
    };
};

export const getMemberSavingDetailFailed = () => {
    return {
        type: actions.GET_MEMBER_SAVING_DETAIL_FAILED
    };
};
export const getMemberSavingDetailSuccess = () => {
    return {
        type: actions.GET_MEMBER_SAVING_DETAIL_SUCCESS
    };
};

export const initGetMemberSavingQRCode = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_SAVING_QR,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberSavingQRCodeStart = () => {
    return {
        type: actions.GET_MEMBER_SAVING_QR_START
    };
};

export const getMemberSavingQRCodeFailed = () => {
    return {
        type: actions.GET_MEMBER_SAVING_QR_FAILED
    };
};
export const getMemberSavingQRCodeSuccess = () => {
    return {
        type: actions.GET_MEMBER_SAVING_QR_SUCCESS
    };
};

export const initGetMemberDepositDetail = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_DEPOSIT_DETAIL,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberDepositDetailStart = () => {
    return {
        type: actions.GET_MEMBER_DEPOSIT_DETAIL_START
    };
};

export const getMemberDepositDetailFailed = () => {
    return {
        type: actions.GET_MEMBER_DEPOSIT_DETAIL_FAILED
    };
};
export const getMemberDepositDetailSuccess = () => {
    return {
        type: actions.GET_MEMBER_DEPOSIT_DETAIL_SUCCESS
    };
};