import * as actionTypes from '../actions/actionTypes';
import {updateObject}   from "../../shared/utility";
// import moment           from 'moment';

const initialState = {
    data: null,
};

const fetchData = (state, action) => {
    return updateObject(state, {
        data: action.data
    })
};

const reduce = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DATA_STATUS_MERCHANT:
            return fetchData(state, action);
        default:
            return state;
    }
};

export default reduce;