import {numToMoney} from './maskFunc';

export const handleMappingMessage = (data,parameter)  =>{
    let flag = data.data?.errors?true:false;
    let flagErrors = data.status?true:false;
    let lang = localStorage.getItem('simpool-lng');
    let teks = '';
    if(flag){
        let arrErrors = data.data.errors;
        if(arrErrors.length > 0){
            if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.length.does.not.match'){
                let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                let max = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                if(lang === 'en'){
                    teks = 'Password Length, Minimum '+min+ ', Maximum '+max;
                }else{
                    teks = 'Panjang Password Minimal '+min+ ', Maksimal '+max;
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.numeric.does.not.match'){
                let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                if(lang === 'en'){
                    teks = 'The Password Must Contain a Minimum '+min+ ' numbers';
                }else{
                    teks = 'Password Harus Mengandung Minimal '+min+ ' Angka';
                }
                
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.lower.letter.does.not.match'){
                let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                if(lang === 'en'){
                    teks = 'The Password Must Contain a Minimum '+min+ ' Lowercase';
                }else{
                    teks = 'Password Harus Mengandung Minimal '+min+ ' Huruf Kecil';
                }
                
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.upper.letter.does.not.match'){
                let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                if(lang === 'en'){
                    teks = 'The Password Must Contain a Minimum '+min+ ' Uppercase';
                }else{
                    teks = 'Password Harus Mengandung Minimal '+min+ ' Huruf Besar';
                }
                
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.special.characters.does.not.match'){
                let spesial = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                if(lang === 'en'){
                    teks = 'Password Must Contain Special Character '+spesial;
                }else{
                    teks = 'Password Harus Mengandung Karater spesial '+spesial;
                }
                
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.otp.code.not.valid'){
                let kode = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                if(lang === 'en'){
                    teks = 'Invalid OTP Code '+kode;
                }else{
                    teks = 'Kode OTP '+kode+' Tidak Valid';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'validation.msg.savingsaccount.minAmountOfTransaction.is.less.than.min'){
                let value = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                let valuemin = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                if(!isNaN(valuemin)){
                    valuemin = numToMoney(parseFloat(valuemin));
                }
                if(lang === 'en'){
                    teks = 'Your Transaction Amount Less Than The Limit (Single Minimum Transaction Limit: '+valuemin+')';
                }else{
                    teks = 'Transaksi Anda Kurang Dari Limit (Batas Minimal Per Transaksi: '+valuemin+' )';
                }

            }else if(arrErrors[0].userMessageGlobalisationCode === 'validation.msg.savingsaccount.maxAmmountOfTransaction.is.greater.than.max'){
                let valuemax = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                if(!isNaN(valuemax)){
                    valuemax = numToMoney(parseFloat(valuemax));
                }
                if(lang === 'en'){
                    teks = 'Your Transaction Amount Exceeds The Limit (Single Maximum Transaction Limit: '+valuemax+')';
                }else{
                    teks = 'Transaksi Anda Melebihi Limit (Batas Maksimal Per Transaksi: '+valuemax+')';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'validation.msg.savingsaccount.maxAmountOfPeriod.is.greater.than.max'){
                let sisalimit = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                if(!isNaN(sisalimit)){
                    sisalimit = numToMoney(parseFloat(sisalimit));
                }
                if(lang === 'en'){
                    teks = 'Your Transaction Amount Exceeds The Limit (Remaining Limit in Period: '+sisalimit+')';
                }else{
                    teks = 'Transaksi Anda Melebihi Limit (Sisa Batas per Periode: '+sisalimit+')';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'validation.msg.savingsaccount.maxAttemptOfPeriod.is.greater.than.max'){
                let limitfreqperiod = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                if(lang === 'en'){
                    teks = 'Your Total Transaction Exceeds The Limit (Total Limit In Period: '+limitfreqperiod+')';
                }else{
                    teks = 'Total Transaksi Anda Telah Mencapai Limit (Total Batas per Periode: '+limitfreqperiod+')';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.employmentlevel.loan.product.number.of.account'){
                let val = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                if(lang === 'en'){
                    teks = 'Number Of Loan Account Must: '+val+'';
                }else{
                    teks = 'Jumlah Pinjaman Harus: '+val+'';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.employmentlevel.loan.product.min.max.principal'){
                let val1 = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                let val2 = arrErrors[0]?.args[2]?arrErrors[0].args[2].value:'?';
                if(lang === 'en'){
                    teks = 'Principal Amount Must Beetween: '+val1+' And '+val2+'';
                }else{
                    teks = 'Pokok Pinjaman Harus Diantara: '+val1+' Dan '+val2+'';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.employmentlevel.loan.product.nfactor'){
                let val1 = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                let val2 = arrErrors[0]?.args[2]?arrErrors[0].args[2].value:'?';
                if(lang === 'en'){
                    teks = 'Basic Salary Must Greater Than Or Equal: '+val1+' X '+val2+'';
                }else{
                    teks = 'Gaji Pokok Harus Lebih Besar Atau Sama Dengan: '+val1+' X '+val2+'';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.employmentlevel.paylater.product.min.max.principal'){
                let val1 = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                let val2 = arrErrors[0]?.args[2]?arrErrors[0].args[2].value:'?';
                if(lang === 'en'){
                    teks = 'Overdraft Limit Must Equal: '+val1+' And '+val2+'';
                }else{
                    teks = 'Batas Maksimal Cerukan Harus Sama Dengan: '+val1+' And '+val2+'';
                }
            }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.employmentlevel.paylater.product.number.of.account'){
                let val = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                if(lang === 'en'){
                    teks = 'Number Of Paylater Account Must: '+val+'';
                }else{
                    teks = 'Jumlah Paylater Harus: '+val+'';
                }
            }

            else if(listMsgError(arrErrors[0].userMessageGlobalisationCode,lang) !== ''){
                teks = listMsgError(arrErrors[0].userMessageGlobalisationCode,lang,arrErrors[0]);
            }else{
                teks = arrErrors[0].defaultUserMessage;
            }
        }
    }else if(data === 'ECONNABORTED'){
        if(lang === 'en'){
            teks = 'Sorry, our system is under maintenance, please wait for any moment and make sure you have internet connection ';
        }else{
            teks = 'Mohon maaf, system kami sedang dalam pemeliharaan, Mohon tunggu beberapa saat lagi dan pastikan Anda terhubung ke jaringan internet ';
        }
    }else if(data.data.defaultUserMessage){
        if(listMsgError(data.data.userMessageGlobalisationCode,lang) !== ''){
            teks = listMsgError(data.data.userMessageGlobalisationCode,lang);
        }else{
            teks = data.data.defaultUserMessage;
        }
    }else if(flagErrors){
        let arrErrors = data.status;
        if(arrErrors == 403){
            if(parameter.paramreport){
                let arrparamreport = parameter.paramreport.split('?');
                let name = arrparamreport[0].replaceAll('%20',' ');
                teks = 'Not authorised '+name;
            }
        }
        
    }else{
        teks = data;
    }

    return teks;
}


export const listMsgError = (code,lang,param)  =>{
    let teks = '';
    if(code === 'error.msg.password.already.used'){
        if(lang === 'en'){
            teks = 'Password Has Been Used';
        }else{
            teks = 'Password Sudah Pernah Digunakan';
        }
        
    }else if(code === 'error.msg.password.already.used'){
        if(lang === 'en'){
            teks = 'Repetition of Entering the Password Does not Match the Password';
        }else{
            teks = 'Pengulangan Memasukan Kata Sandi Tidak Sesuai Dengan Kata Sandi';
        }
    }else if(code === 'error.msg.client.close.loan.non-closed'){
        if(lang === 'en'){
            teks = 'Member cannot be closed because of non-closed financing';
        }else{
            teks = 'Anggota tidak dapat ditutup karena pembiayaan belum ditutup';
        }
    }else if(code === 'error.msg.client.close.non-closed.savings.account'){
        if(lang === 'en'){
            teks = 'Member cannot be closed because of non-closed savings account';
        }else{
            teks = 'Anggota tidak dapat ditutup karena simpanan belum ditutup';
        }
    }else if(code === 'validation.msg.clientImage.cannot.be.blank'){
        if(lang === 'en'){
            teks = 'Please upload your Bank Transfer Receipt';
        }else{
            teks = 'Mohon upload bukti transfer Anda';
        }
    }else if(code === 'error.msg.CoreTransactionCodeDetail.data.duplicate'){
        teks = 'Transaction Code with These Details is Already Exists';
    }else if(code === 'error.msg.clientjob.currently.used.duplicate'){
        teks = 'Employment Information With Currently Used True Already Exist';
    }else if(code === 'validation.msg.savingsaccount.overdraftLimit.cannot.exceed.product.value'){
        teks = 'Overdraft Limit Cannot Be More Than Overdraft Limit in Product Setting';
    }else if(code == 'error.msg.transaction.teller.netcash.lessthan.withdrawal.amount'){
        if(lang === 'en'){
            teks = 'Transaction failed because teller balance is not enough for cash withdrawal';
        }else{
            teks = 'Transaksi gagal karena saldo teller kurang untuk penarikan tunai';
        }
    }else if(code == 'error.msg.deposit.transaction.this.user.is.not.teller'){
        if(lang === 'en'){
            teks = 'Your userID is not registered as a Teller/Cashier';
        }else{
            teks = 'UserID Anda tidak terdaftar sebagai Teller/Kasir';
        }
    }else if(code == 'error.msg.tellerlimit.cannot.allocation'){
        if(lang === 'en'){
            teks = 'Cannot doing allocation before teller status Pre-Open';
        }else{
            teks = 'Gagal melakukan allocation sebelum status teller Pre-Open';
        }
    }else if(code == 'error.teller.limit.approval.not.allowed'){
        if(lang === 'en'){
            teks = 'You are not authorize to approve this transaction';
        }else{
            teks = 'Anda tidak berwenang untuk menyetujui transaksi ini';
        }
    }else if(code == 'error.msg.tellerstatus.not.closed'){
        if(lang === 'en'){
            teks = 'Teller must be closed first';
        }else{
            teks = 'Status Teller harus Close';
        }
    }else if(code == 'validation.msg.savingsaccount.transaction.invalid.charge.amount.paid.in.access'){
        if(lang === 'en'){
            teks = 'Paid amount should not be more than outstanding payable amount';
        }else{
            teks = 'Jumlah yang dibayar tidak boleh melebihi jumlah tertunggak';
        }
    }else if(code == 'validation.msg.client.identityName.does.not.match.regexp' || code == 'validation.msg.client.mobileUser.does.not.match.regexp'){
        if(lang === 'en'){
            teks = 'The field of mobile user must match the provided expression: length (min 6, max 30), alphanumeric (a..z, A..Z, 0..9), special character ("_",".","@") ';
        }else{
            teks = 'Kolom isian Nama Pengguna harus sesuai dengan ketentuan berikut: panjang (min 6, max 30), huruf dan angka (a..z, A..Z, 0..9), karakter khusus ("_",".","@")';
        }
    }else if(code == 'error.msg.token.mitra.is.not.active'){
        if(lang === 'en'){
            teks = 'Your System Token is not active yet, please contact the Admin';
        }else{
            teks = 'Token Sistem Anda belum aktif, silakan hubungi Admin';
        }
    }else if(code == 'error.msg.tellerlimit.transaction.not.approved'){
        let name = 'Teller';
        if(param != undefined && param.parameterName){
            name = param.parameterName;
        }
        if(lang === 'en'){
            teks = name+' has unapproved transactions';
        }else{
            teks = name+' masih memiliki transaksi yang belum disetujui';
        }
    }else if(code == 'recurring.deposit.account.fulfilled.deposit' || code == 'error.msg.depositaccounttype.recurringdeposit.id.invalid'){
        if(lang === 'en'){
            teks = 'This account has fulfilled the deposit plan amount';
        }else{
            teks = 'Rekening ini sudah memenuhi jumlah rencana setoran';
        }
    }else if(code == 'error.msg.multiple.reason.insufficient.balance'){
        let norek = '';
        if(param != undefined ){
            norek = param?.args[0]?param.args[0].value:'';
        }
        if(lang === 'en'){
            teks = 'Insufficient account balance with saving acount '+norek;
        }else{
            teks = 'Saldo rekening ini '+norek+' tidak mencukupi';
        }
    }else if(code == 'error.msg.transaction.teller.netcash.lessthan.withdrawal.amount'){
        if(lang === 'en'){
            teks = 'Transaction failed because teller balance is not enough for cash withdrawal';
        }else{
            teks = 'Transaksi gagal karena saldo teller kurang untuk penarikan tunai';
        }
    }
    //Transaction failed because teller balance is not enough for cash withdrawal

    //

    //
    return teks;
}