import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    listMerchant,merchantDetail,merchantPaymentURL,merchantQrisURL,merchantCreateSimpoolPaymentQRURL, merchantTemplateUrl,
    getSavingmerchantUrl,
    merchantUrl
} from '../../shared/apiUrl';
import { toLogout } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* initGetListMerchantSaga(action) {
    try {
        const response = yield axios.get(listMerchant(action.tenantid)).then(response => response.data);
        action.successCallback(response, 'api');
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* getDetailMerchantSaga(action) {
    // yield put(actions.getListMobileUserStart());
    try {
        const response = yield axios.get(merchantDetail(action.id)).then(response => response.data);
        // yield put(actions.getListMobileSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // yield put(actions.getListMobileUserFailed());
        action.errorCallback(error.message ?? error.data.message);
    }
}

export function* getMerchantPaymentSaga(action) {
    // yield put(actions.getListMobileUserStart());
    try {
        const response = yield axios.post(merchantPaymentURL,action.payload).then(response => response.data);
        // yield put(actions.getListMobileSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // yield put(actions.getListMobileUserFailed());
        action.errorCallback(error.message ?? error.data.message);
    }
}

export function* getMerchantQrisSaga(action) {
    // yield put(actions.getListMobileUserStart());
    try {
        const response = yield axios.post(merchantQrisURL,action.payload).then(response => response.data);
        // yield put(actions.getListMobileSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // yield put(actions.getListMobileUserFailed());
        action.errorCallback(error.message ?? error.data.message);
    }
}

export function* createMerchantSimpoolPaymentQRisSaga(action) {
    // yield put(actions.getListMobileUserStart());
    try {
        const response = yield axios.post(merchantCreateSimpoolPaymentQRURL,action.payload).then(response => response.data);
        // yield put(actions.getListMobileSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // yield put(actions.getListMobileUserFailed());
        // action.errorCallback(error.message ?? error.data.message);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* getMerchantTemplateSaga(action) {
    try {
        const response = yield axios.get(merchantTemplateUrl(action.tenantid)).then(response => response.data);
        action.successCallback(response,action.param);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* getSavingsMerchantSaga(action) {
    try {
        const response = yield axios.get(getSavingmerchantUrl(action.tenantid,action.savingsacc)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitAddMerChantSaga(action) {
    try {
        const response = yield axios.post(merchantUrl(''),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* getDataMerchantSaga(action) {
    try {
        const response = yield axios.get(merchantUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitEditMerChantSaga(action) {
    try {
        const response = yield axios.put(merchantUrl(''),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitRejectMerChantSaga(action) {
    try {
        const response = yield axios.post(merchantUrl('/reject'),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitApproveMerChantSaga(action) {
    try {
        const response = yield axios.post(merchantUrl('/approve'),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitAddReCreateMerChantSaga(action) {
    try {
        const response = yield axios.post(merchantUrl('/recreate'),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        console.log('error ',error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}