import * as actions from './actionTypes';

export const addWebMenuParameter  = (payload,successHandler,errorHandler) => {
    return {
        type: actions.ADD_WEB_MENU_PARAMETER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getAllWebMenuParameter  = (successHandler,errorHandler) => {
    return {
        type: actions.GET_ALL_WEB_MENU_PARAMETER,
        successHandler,
        errorHandler,
    }
};

export const getDetailWebMenuParameter  = (id,successHandler,errorHandler) => {
    return {
        type: actions.GET_DETAIL_WEB_MENU_PARAMETER,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const deleteWebMenuParameter  = (id,successHandler,errorHandler) => {
    return {
        type: actions.DELETE_WEB_MENU_PARAMETER,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const editWebMenuParameter  = (id,payload,successHandler,errorHandler) => {
    return {
        type: actions.EDIT_WEB_MENU_PARAMETER,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getListRoles  = (successHandler,errorHandler) => {
    return {
        type: actions.GET_LIST_ROLES,
        successHandler,
        errorHandler,
    }
};

export const getListRoleMenuByRolesID  = (id,datawebmenuparam,successHandler,errorHandler) => {
    return {
        type: actions.GET_LIST_ROLE_MENU_BY_ROLES_ID,
        id:id,
        datawebmenuparam:datawebmenuparam,
        successHandler,
        errorHandler,
    }
};

export const addRoleMenu  = (payload,successHandler,errorHandler) => {
    return {
        type: actions.ADD_ROLE_MENU,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const deleteRoleMenu  = (id,successHandler,errorHandler) => {
    return {
        type: actions.DELETE_ROLE_MENU,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const editRoleMenu  = (id,payload,successHandler,errorHandler) => {
    return {
        type: actions.EDIT_ROLE_MENU,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};