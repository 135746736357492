import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    mobileMenuUrl,mobileVersionUrl, mobileCommerceUrl
} from '../../shared/apiUrl';
import { toLogout } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';


export function* getMobileMenuDataSaga(action) {
    let url = action.param.url;
    try {
        const response = yield axios.get(mobileMenuUrl(url)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}


export function* submitMobileMenuSaga(action) {
    let url = action.param.url;
    let type = action.param.type;
    try {
        let response = {};
        if(type == "POST"){
            response = yield axios.post(mobileMenuUrl(url),action.payload).then(response => response.data);
        }else if(type == "PUT"){
            response = yield axios.put(mobileMenuUrl(url),action.payload).then(response => response.data);
        }
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* getMobileVersionDataSaga(action) {
    let url = action.param.url;
    try {
        const response = yield axios.get(mobileVersionUrl(url)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitMobileVersionSaga(action) {
    let url = action.param.url;
    let type = action.param.type;
    try {
        let response = {};
        if(type == "POST"){
            response = yield axios.post(mobileVersionUrl(url),action.payload).then(response => response.data);
        }else if(type == "PUT"){
            response = yield axios.put(mobileVersionUrl(url),action.payload).then(response => response.data);
        }
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* getMobileCommerceDataSaga(action) {
    let url = action.param.url;
    try {
        const response = yield axios.get(mobileCommerceUrl(url)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitMobileCommerceSaga(action) {
    let url = action.param.url;
    let type = action.param.type;
    try {
        let response = {};
        if(type == "POST"){
            response = yield axios.post(mobileCommerceUrl(url),action.payload).then(response => response.data);
        }else if(type == "PUT"){
            response = yield axios.put(mobileCommerceUrl(url),action.payload).then(response => response.data);
        }
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}