import CryptoJS from 'crypto-js';
import { keyEcncrypt,keyLoginDataLocStorage, keyToken } from './globalConfig';
import { checkParameter } from './utility';

export const dataLoginEnc = (response) => {
    let roles = [];
    if(response.roles){
        roles = listRoles(response.roles);
    }

    const obj = new Object();
    obj.roles = roles;
    obj.officeid = response.officeId;
    obj.tenantid = response.actionuser?response.actionuser.tenant:'';
    obj.token = response.base64EncodedAuthenticationKey;

    const enc = CryptoJS.AES.encrypt(JSON.stringify(obj),keyEcncrypt).toString();

    localStorage.setItem(keyLoginDataLocStorage,enc);

    return "";
}

export const getInfoLogin = () => {
    let value = localStorage.getItem(keyLoginDataLocStorage);
    let flag = checkParameter(value);
    if(flag){
        const bytes = CryptoJS.AES.decrypt(value, keyEcncrypt);
        const valDecrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log("valDecrypt ",valDecrypt);
        return valDecrypt;
    }
    return null;
}

const listRoles = (value) => {
    let listroles = [];
    for(let i=0; i < value.length; i++){
        listroles.push(value[i].name);
    }

    return listroles;
}

export const getToken = () => {
    return localStorage.getItem(keyToken);
}