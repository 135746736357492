import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    accountTransactionsUrl,
    accountTransfersUrl,
    productSavingsTemplateUrl,
    savingsAccountUrl,
    savingsAccountListUrl,findAccountAndGLUrl,getAllCodeTrx,
    generalTransactionURL,feeByTrxModeURL,findSavingsByAccNumber,
    savingsAccountItermediateUrl,getCoreTransactionDetailURL,
    savingsByActionCommand,savingsProductByIDURL,
    savingsAccountAssosiationsEmptyUrl,savingsAccountCloseUrl,maintenanceAavingsAccountsUrl,
    maintenanceChargesSavingsAccountsUrl,getAllCodeTrxV2,transactionByCommandURL,fixDepositByIdAndCommandsURL,getGlAccountIsGeneralTrueURL,
    fixDepositByQueryParamURL,fixDepositByParamIDURL,adjusmentTermSavingsURL,findAccountTransferWithParamUrl,getListPaylaterByQueryParamURL,accountChargesUrl,
    baseBlockAccountsURL,getListIntermediaryByQueryParamURL,basedFixDepositWithParamUrl,savingsProductUrl,typePassBookUrl,savingTransactionURL,getListDataPaylaterByQueryParamURL,getListSavingsByQueryParamURL,productSavingsTemplatePaylaterUrl,
    baseprintPassbookUrl,
    termSavingParamUrl,
    recurringdepositaccountsUrl,
    savingRecurringParamUrl,
    accountTransferParamUrl,basedFixDepositParamUrl, findSavingRecurringferUrl
} from '../../shared/apiUrl';
import { msgErrorHandler, toLogout } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* initGetSavingAccountTransactionSaga(action) {
    yield put(actions.savingAccountTransactionStart());
    try {
        const response = yield axios.get(accountTransactionsUrl(action.clientId, action.id)).then(response => response.data);
        yield put(actions.savingAccountTransactionSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        yield put(actions.savingAccountTransactionFailed());
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* initGetSavingAccountTransferSaga(action) {
    yield put(actions.savingAccountTransferStart());
    try {
        const response = yield axios.get(accountTransfersUrl(action.id)).then(response => response.data);
        yield put(actions.savingAccountTransferSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        yield put(actions.savingAccountTransferFailed());
        action.errorCallback(error.message ?? error.data.message);
    }
}

export function* initGetProductSavingSaga(action) {
    yield put(actions.getProductSavingStart());
    try {
        const response = yield axios.get(productSavingsTemplateUrl(action.clientId)).then(response => response.data);
        yield put(actions.getProductSavingSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        yield put(actions.getProductSavingFailed());
        action.errorCallback(error.message ?? error.data.message);
    }
}

export function* initPostSavingAccSaga(action) {
    yield put(actions.postSavingAccountStart());
    try {
        // console.log(action.payload);
        const response = yield axios.post(savingsAccountUrl, action.payload).then(response => response.data);
        console.log("response ",response);
        yield put(actions.getProductSavingSuccess());
        action.successCallback(response);
    } catch (error) {
        console.log("error ",error);
        yield put(actions.postSavingAccountFailed());
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }

}

export function* initGetListSavingSaga(action) {
    yield put(actions.getListSavingStart());
    try {
        const response = yield axios.get(savingsAccountListUrl).then(response => response.data);
        yield put(actions.getListSavingSuccess());
        action.successCallback(response, 'api');
    } catch (error) {
        toLogout(error);
        yield put(actions.getListSavingFailed());
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getFindAccountAndGLSaga(action) {
    try {
        const response = yield axios.get(findAccountAndGLUrl,{
            params: {
                accountNumber:action.accountnumber,
                trxCode:action.kodetrx
            }
        }).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getAllCodeTrxSaga(action) {
    try {
        const response = yield axios.get(getAllCodeTrx).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        console.log('getAllCodeTrxSaga ',error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* savingsGeneralTransactionSaga(action) {
    try {
        const response = yield axios.post(generalTransactionURL,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        console.log('error ',error)
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getFeeByTrxModeSaga(action) {
    try {
        const response = yield axios.get(feeByTrxModeURL(action.trxmode)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* GetListSavingsByAccountNumberSaga(action) {
    try {
        // let url = savingsAccountUrl+"?sqlSearch="+action.accountnumber;
        // console.log('GetListSavingsByAccountNumberSaga ',url)
        const response = yield axios.get(findSavingsByAccNumber(action.accountnumber)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getAllSavingsInteremediateSaga(action) {
    try {
        const response = yield axios.get(savingsAccountItermediateUrl).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getCoreTransDetailSaga(action) {
    try {
        const response = yield axios.get(getCoreTransactionDetailURL(action.coreid)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}
export function* SavingsAccountByCommandSaga(action) {
    try {
        // let url = savingsAccountUrl+"?sqlSearch="+action.accountnumber;
        // console.log('GetListSavingsByAccountNumberSaga ',url)
        const response = yield axios.post(savingsByActionCommand(action.savingsid,action.command),action.payload).then(response => response.data);
        action.successCallback(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}
export function* GetSavingDetailAssociationsEmptySaga(action) {
    try {
        // let url = savingsAccountUrl+"?sqlSearch="+action.accountnumber;
        // console.log('GetListSavingsByAccountNumberSaga ',url)
        const response = yield axios.get(savingsAccountAssosiationsEmptyUrl(action.savingid)).then(response => response.data);
        action.successCallback(response,action.dataloan);
    } catch (error) {
        
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getCoreTransDetailParamSaga(action) {
    let coreid = action.param.get('coreid');
    console.log('coreid ',coreid);
    try {
        const response = yield axios.get(getCoreTransactionDetailURL(coreid)).then(response => response.data);
        action.successCallback(response,action.param);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getAllSavingsInteremediateParamSaga(action) {
    try {
        const response = yield axios.get(savingsAccountItermediateUrl).then(response => response.data);
        action.successCallback(response,action.param);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}
export function* getProductSavingByIDSaga(action) {
    try {
        const response = yield axios.get(savingsProductByIDURL(action.productid)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getInterestCloseAccountSaga(action) {
    try {
        const response = yield axios.get(savingsAccountCloseUrl(action.savingid)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}
export function* getAllCodeTrxV2Saga(action) {
    try {
        const response = yield axios.get(getAllCodeTrxV2).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getMaintenanceSavingsSaga(action) {
    try {
        const response = yield axios.get(maintenanceAavingsAccountsUrl(action.savingid)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}
export function* getSavingProductTemplateByIDSaga(action) {
    try {
        const response = yield axios.get(productSavingsTemplateUrl(action.clientid)+'&productId='+action.productid).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getMaintenanceChargesSavingsSaga(action) {
    try {
        const response = yield axios.get(maintenanceChargesSavingsAccountsUrl(action.savingid)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitMaintenanceSavingSaga(action) {
    try {
        const response = yield axios.put(maintenanceAavingsAccountsUrl(action.savingsid), action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* transactionByCommandSaga(action) {
    try {
        const response = yield axios.post(transactionByCommandURL(action.command),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* fixDepositByIdAndCommandSaga(action) {
    try {
        const response = yield axios.post(fixDepositByIdAndCommandsURL(action.id,action.command),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getGlAccountIsGeneralTransTrueSaga(action) {
    try {
        const response = yield axios.get(getGlAccountIsGeneralTrueURL).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getFixedDepositByQueryParamSaga(action) {
    try {
        const response = yield axios.get(fixDepositByQueryParamURL(action.id,action.queryparam)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitMaintenanceTermSavingsSaga(action) {
    try {
        const response = yield axios.put(fixDepositByParamIDURL(action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitAdjusmentTermSavingsSaga(action) {
    try {
        const response = yield axios.put(adjusmentTermSavingsURL(action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getFindAccountNumberSaga(action) {
    try {
        const response = yield axios.get(findAccountTransferWithParamUrl(action.param)).then(response => response.data);
        action.successCallback(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}
export function* getListPaylaterByParamSaga(action) {
    try {
        const response = yield axios.get(getListPaylaterByQueryParamURL(action.queryparam)).then(response => response.data);
        action.successCallback(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getDetailPayChargeSavingsSaga(action) {
    try {
        const response = yield axios.get(accountChargesUrl(action.savingid,action.paychargeid,action.command)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitPayChargeSavingsSaga(action) {
    try {
        const response = yield axios.post(accountChargesUrl(action.savingid,action.paychargeid,action.command),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        // const errMessages = msgErrorHandler(error);
        // action.errorCallback(errMessages);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* editSavingsAccountSaga(action) {
    try {
        const response = yield axios.put(savingsAccountUrl+'/'+action.id, action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getDataBlockedAccountSaga(action) {
    try {
        const response = yield axios.get(baseBlockAccountsURL(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}
export function* getListIntermediaryByParamSaga(action) {
    try {
        const response = yield axios.get(getListIntermediaryByQueryParamURL(action.queryparam)).then(response => response.data);
        action.successCallback(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitAddBlockAccountSaga(action) {
    try {
        const response = yield axios.post(baseBlockAccountsURL(''),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* submitEditBlockAccountSaga(action) {
    try {
        const response = yield axios.put(baseBlockAccountsURL('/'+action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* submitDeleteBlockAccountSaga(action) {
    try {
        const response = yield axios.delete(baseBlockAccountsURL('/'+action.id)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getFixDepositAccDataSaga(action) {
    try {
        const response = yield axios.get(basedFixDepositWithParamUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getSavingProductDataSaga(action) {
    try {
        const response = yield axios.get(savingsProductUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitUpdateSpecialRateSaga(action) {
    try {
        const response = yield axios.post(savingsProductUrl('/updatespecialrate/'+action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}
export function* getDataTypePassbookSaga(action) {
    try {
        const response = yield axios.get(typePassBookUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitAddSavingsProductSaga(action) {
    try {
        const response = yield axios.post(savingsProductUrl(''),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}

export function* submitResetSpecialRateSaga(action) {
    try {
        const response = yield axios.post(savingsProductUrl('/resetspecialrate/'+action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}
export function* submitEditSavingsProductSaga(action) {
    try {
        const response = yield axios.put(savingsProductUrl('/'+action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}

export function* getDataPaylaterByQueryParamSaga(action) {
    try {
        const response = yield axios.get(getListDataPaylaterByQueryParamURL(action.queryparam)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitDataImportPaylaterSaga(action) {
    try {
        const response = yield axios.put(maintenanceAavingsAccountsUrl(action.id), action.payload).then(response => response.data);
        action.successCallback(response,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    }
}

export function* submitSavingsTransactionSaga(action) {
    try {
        const response = yield axios.post(savingTransactionURL,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}
        
export function* getSavingSAccountParamSaga(action) {
    try {
        const response = yield axios.get(savingsAccountUrl+'/'+action.param).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}


export function* getSavingTemplatePaylaterSaga(action) {
    try {
        const response = yield axios.get(productSavingsTemplatePaylaterUrl(action.id)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}
export function* getListSavingByParamSaga(action) {
    try {
        const response = yield axios.get(getListSavingsByQueryParamURL(action.queryparam)).then(response => response.data);
        action.successCallback(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getPrintPassbookParamSaga(action) {
    try {
        const response = yield axios.get(baseprintPassbookUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getTermSavingListPagingSaga(action) {
    try {
        const response = yield axios.get(termSavingParamUrl(action.queryparam)).then(response => response.data);
        action.successHandler(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getRecurringDepositSaga(action) {
    try {
        const response = yield axios.get(recurringdepositaccountsUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getListSavingRecurringByParamSaga(action) {
    try {
        const response = yield axios.get(savingRecurringParamUrl(action.queryparam)).then(response => response.data);
        action.successCallback(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getAccountTransferDataSaga(action) {
    try {
        const response = yield axios.get(accountTransferParamUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        let data = error;
        let flag = data.data?.errors?true:false;
        let code = '';
        if(flag){
            let arrErrors = data.data.errors;
            if(arrErrors.length > 0){
                code = arrErrors[0].userMessageGlobalisationCode;
            }
        }
        action.errorCallback(errMessages,code);
    }
}

export function* submitRecurringDepositSaga(action) {
    try {
        const response = yield axios.post(recurringdepositaccountsUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getFixDepositAccountDataSaga(action) {
    try {
        const response = yield axios.get(basedFixDepositParamUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* submitFixDepositAccountSaga(action) {
    try {
        const response = yield axios.post(basedFixDepositParamUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}

export function* getFindSavingRecurringSaga(action) {
    try {
        const response = yield axios.get(findSavingRecurringferUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* editRecurringDepositSaga(action) {
    try {
        const response = yield axios.put(recurringdepositaccountsUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error?.data?.errors?error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []):'Error';
        action.errorCallback(errMessages);
    }
}

export function* getSavingsChargesSaga(action) {
    try {
        const response = yield axios.get(accountChargesUrl(action.savingsid,action.chargeId,action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitSavingsChargesSaga(action) {
    try {
        if(action.actiontype == "POST"){
            const response = yield axios.post(accountChargesUrl(action.savingsid,action.chargeId,action.param),action.payload).then(response => response.data);
            action.successCallback(response);
        }
        
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitMaintenanceSavingsChargesSaga(action) {
    try {
        if(action.actiontype == "PUT"){
            const response = yield axios.put(maintenanceChargesSavingsAccountsUrl(action.param),action.payload).then(response => response.data);
            action.successCallback(response);
        }
        
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}


export function* getSavingsTransactionSaga(action) {
    let urlParam = action.param.url?action.param.url:'';
    try {
        let urlAPI = savingTransactionURL+urlParam;
        const response = yield axios.get(urlAPI).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}