import React, {useState, useRef, useEffect} from 'react';
import '../CSS/chat.css';
import {useTranslation}             from 'react-i18next';
import ClickAwayListener          from '@material-ui/core/ClickAwayListener';
import Grow                       from '@material-ui/core/Grow';
import Paper                      from '@material-ui/core/Paper';
import Popper                     from '@material-ui/core/Popper';
import {makeStyles}               from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
import * as actions                 from '../../store/actions';
import { withRouter  } from 'react-router-dom';
import Dialog                       from '@material-ui/core/Dialog';
import styled                       from "styled-components";
import MuiDialogTitle               from '@material-ui/core/DialogTitle';
import {withStyles}                 from '@material-ui/core/styles';
import Typography                   from '@material-ui/core/Typography';
import CloseIcon                    from '@material-ui/icons/Close';
import IconButton                   from '@material-ui/core/IconButton';
import ChangePassworddDialog from './ChangePasswordDialog';
// import * as actions                 from '../../store/actions';
import {Loading}                    from '../../components/Common/Loading';
import Swal             from "sweetalert2";
import {useHistory}                 from 'react-router-dom';
import { isGetPortalMenuPermission, msgErrorHandler } from '../../shared/maskFunc';
import UserProfilDialog from './UserProfileDialog';
import {weburlLite}                 from '../../shared/apiUrl';
import Chat                    from '@material-ui/icons/ContactSupport';
import Tooltip                      from '@material-ui/core/Tooltip';
import ChatBotSvg from '../../components/icons/chatbot_white.svg';
// import {isGetPermissions} from '../../shared/maskFunc';
// import { Portal_Header } from '../../shared/PermissionForFeatures';
// import ChatDialog from './ChatDialog';

const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    height: 500px;
  }
`;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    button: {
        color: 'white',
        textTransform: 'none',
    },
    chat: {
        color: 'white',
        textTransform: 'none'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    svgIcon: {
        marginRight: '10px',
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
  });

function MenuListComposition(props) {
    const classes = useStyles();
    const history = useHistory();
    const i18n = useTranslation('translations');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [changepassword, setShowChangePassword] = useState(false);
    const [showuserprofile, setShowUserProfile] = useState(false);
    const anchorRef = useRef(null);
    const dispatch = useDispatch();
    const username = useSelector(state => state.auth.username);
    const [referrence, setReferrence] = useState('');
    const [expmilis, setExpMilis] = useState(0);
    const [UrlRooc, setUrlRooc] = useState('');
    const [IsShowChat, setIsShowChat] = useState(false);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
        setLoading(true);
        dispatch(actions.getServiceChatBot(succesHandler, errorHandler));
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleShowUserProfile = (event) => {
        // console.log('handleShowUserProfile ',getuserid());
        setShowUserProfile(true);
    }
    const handleChangePassword = (event) => {
        setLoading(true);
        setShowChangePassword(true);
        // dispatch(actions.createChangePasswordUserWebOTP(successHandler,errorHandler));
        
    };
    // const HandlePasswordvalidation = (data) => {
        
    // }
    // const successHandler = (data) => {
    //     console.log('successHandler ',data);
    //     setReferrence(data.reference);
    //     setExpMilis(data.expiredMillis);
    //     // setLoading(false);
    //     // dispatch(actions.getPasswordValidation(HandlePasswordvalidation, errorHandler));
    // }

    const errorHandler = (data) => {
        let teks = msgErrorHandler(data);
        setLoading(false);
        setShowChangePassword(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: teks
        })
      }
    const successHandlerConfirmPW = (data) =>{
        setShowChangePassword(false);
        let teks = i18n.t('label_SUCCESS');
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: teks
        }).then(res => {            
            // window.location.reload(true);
            history.go('/logout');
        });
        setLoading(false);
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function handleLogout() {
        props.history.push('/logout');
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
    }, []);

    

    useEffect(() => {
        dispatch(actions.getServiceChatBot(succesHandlerCek, errorHandler));
    }, [dispatch]);
    function succesHandlerCek(data) {
        if(data.data.username && data.data.password && data.data.url){
            setIsShowChat(true);
        }else{
            setIsShowChat(false);
        }
    }
    function succesHandler(data) {
        if(data.data.username && data.data.password && data.data.url){
            setIsShowChat(true);
            sessionStorage.setItem('URL-ROOC',data.data.url);
            let obj = new Object();
            obj.username = data.data.username;
            obj.password = data.data.password;
            dispatch(actions.getTokenRooc(obj,succesHandlerRooc, errorHandlerRooc));
            //https://lite.rooc.egeroo.ai/v2/embededapps.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJzeXNhZG1pbiIsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSJdLCJvcmdhbml6YXRpb24iOiJzeXNhZG1pbiIsImV4cCI6MTY1OTEwNjM1MCwiYXV0aG9yaXRpZXMiOlsiUk9MRV9TWVNBRE1JTiJdLCJqdGkiOiIxZGZiODNmNS1mNjQ3LTQ3ZmEtYjY0Ni0yYWUxMjFhOGI1OWUiLCJjbGllbnRfaWQiOiJhZG1pbiJ9.O80HP0fFRSnoE7HjfOBxVdTr2W9P_jCxJzx0nNf8RW4&server=https://channel.rooc.egeroo.ai/simpool
            // console.log("succesHandler ",data);
        }else{
            setIsShowChat(false);
        }
        
    }
    const errorHandlerRooc = (data) => {
        setLoading(false);
        console.log("errorHandlerRooc ",data);
    }

    function succesHandlerRooc(data) {
        let urlRooc = weburlLite(data.token,data.server);
        setUrlRooc(urlRooc);
        setLoading(false);
    }

    return (
        <div className={classes.root}>
           
           <Tooltip title={i18n.t('Sinta')}>
            <IconButton
                color={'default'}
                className={classes.chat}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{height:'54px',padding:'0px'}}
                hidden={!IsShowChat}
            >
                {/* <a href="https://lite.rooc.egeroo.ai/v2/embededapps.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJzeXNhZG1pbiIsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSJdLCJvcmdhbml6YXRpb24iOiJzeXNhZG1pbiIsImV4cCI6MTY1OTAzOTczNCwiYXV0aG9yaXRpZXMiOlsiUk9MRV9TWVNBRE1JTiJdLCJqdGkiOiJlNTA5YzMzNi0wYWZlLTQzYTctYTU0MC0yNzliZGExZjEzNzQiLCJjbGllbnRfaWQiOiJhZG1pbiJ9.San2kjMKFlDvLFXL0XMeYfG241Xw4yTlDD6oow1mUHQ&server=https://channel.rooc.egeroo.ai/simpool" target="_blank">ChatBot</a> */}
                {/* <ChatBot/> */}
                <img style={{width:'38px'}}  src= {ChatBotSvg}
                                alt="Image"  />
            </IconButton>
            </Tooltip>
            
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                {
                                    UrlRooc !== '' ?
                                    <iframe id='chat' className="formchat" src={UrlRooc}
                                    title={"Chat Sinta"}
                                    /> :(<div></div>)
                                }
                                
                            
                            </ClickAwayListener>
                        </Paper>
                        
                    </Grow>
                )}
                
            </Popper>
            
            <StyledDialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="lg"
                fullWidth={true}
                style={{height: '80vh'}}
                open={showuserprofile}
                >
                    <UserProfilDialog
                        errorHandler = {errorHandler}
                        setloading = {setLoading}
                        showflag = {setShowUserProfile}
                    />
                    {loading && <Loading/>}
                </StyledDialog>
            <StyledDialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="sm"
                fullWidth={true}
                style={{height: '80vh'}}
                open={changepassword}
                >
                <ChangePassworddDialog
                    showflag = {setShowChangePassword}
                    reference = {referrence}
                    errorHandler = {errorHandler}
                    successHandlerConfirmPW = {successHandlerConfirmPW}
                    expmilis = {expmilis}
                    setloading = {setLoading}
                />
                {loading && <Loading/>}
            </StyledDialog>
            
        </div>
        
        
        
    );
}

export default withRouter(MenuListComposition);