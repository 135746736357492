import * as actions from './actionTypes';

export const initGetMember = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_ID,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberStart = () => {
    return {
        type: actions.GET_MEMBER_ID_START
    };
};

export const getMemberSuccess = () => {
    return {
        type: actions.GET_MEMBER_ID_SUCCESS
    };
};

export const getMemberFailed = () => {
    return {
        type: actions.GET_MEMBER_ID_FAILED
    };
};

export const initGetMemberImage = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_IMAGE,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberImageStart = () => {
    return {
        type: actions.GET_MEMBER_IMAGE_START
    };
};

export const getMemberImageSuccess = () => {
    return {
        type: actions.GET_MEMBER_IMAGE_SUCCESS
    };
};

export const getMemberImageFailed = () => {
    return {
        type: actions.GET_MEMBER_IMAGE_FAILED
    };
};

export const initGetMemberAccount = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_ACCOUNT,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberAccountStart = () => {
    return {
        type: actions.GET_MEMBER_ACCOUNT_START
    };
};

export const getMemberAccountSuccess = () => {
    return {
        type: actions.GET_MEMBER_ACCOUNT_SUCCESS
    };
};

export const getMemberAccountFailed = () => {
    return {
        type: actions.GET_MEMBER_ACCOUNT_FAILED
    };
};

export const initGetMemberSummary = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_SUMMARY,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberSummaryStart = () => {
    return {
        type: actions.GET_MEMBER_SUMMARY_START
    };
};

export const getMemberSummarySuccess = () => {
    return {
        type: actions.GET_MEMBER_SUMMARY_SUCCESS
    };
};

export const getMemberSummaryFailed = () => {
    return {
        type: actions.GET_MEMBER_SUMMARY_FAILED
    };
};

export const initGetMemberDoc = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_DOC,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const getMemberDocStart = () => {
    return {
        type: actions.GET_MEMBER_DOC_START
    };
};

export const getMemberDocSuccess = () => {
    return {
        type: actions.GET_MEMBER_DOC_SUCCESS
    };
};

export const getMemberDocFailed = () => {
    return {
        type: actions.GET_MEMBER_DOC_FAILED
    };
};

export const initDeleteMemberImage = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_DELETE_MEMBER_IMAGE,
        id: id,
        successCallback,
        errorCallback,
    }
};

export const deleteMemberImageStart = () => {
    return {
        type: actions.DELETE_MEMBER_IMAGE_START
    };
};

export const deleteMemberImageSuccess = () => {
    return {
        type: actions.DELETE_MEMBER_IMAGE_SUCCESS
    };
};

export const deleteMemberImageFailed = () => {
    return {
        type: actions.DELETE_MEMBER_IMAGE_FAILED
    };
};

export const initDeleteMemberDoc = (id, docId, successCallback, errorCallback) => {
    return {
        type: actions.INIT_DELETE_MEMBER_DOC,
        id: id,
        docId: docId,
        successCallback,
        errorCallback,
    }
};

export const deleteMemberDocStart = () => {
    return {
        type: actions.DELETE_MEMBER_DOC_START
    };
};

export const deleteMemberDocSuccess = () => {
    return {
        type: actions.DELETE_MEMBER_DOC_SUCCESS
    };
};

export const deleteMemberDocFailed = () => {
    return {
        type: actions.DELETE_MEMBER_DOC_FAILED
    };
};

export const initGetDocument = (id, document, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_DOCUMENT,
        id: id,
        document: document,
        successCallback,
        errorCallback,
    }
};

export const getDocumentStart = () => {
    return {
        type: actions.GET_DOCUMENT_START
    };
};

export const getDocumentSuccess = () => {
    return {
        type: actions.GET_DOCUMENT_SUCCESS
    };
};

export const getDocumentFailed = () => {
    return {
        type: actions.GET_DOCUMENT_FAILED
    };
};



