export const tenantApex = '990000';

export const months = () => {
    let datamonth = [];
        let month = {value:1,label:'January'} 
        datamonth.push(month);
        month = {value:2,label:'February'};
        datamonth.push(month);
        month = {value:3,label:'Maret'};
        datamonth.push(month);
        month = {value:4,label:'April'};
        datamonth.push(month);
        month = {value:5,label:'Mei'};
        datamonth.push(month);
        month = {value:6,label:'Juni'};
        datamonth.push(month);
        month = {value:7,label:'Juli'};
        datamonth.push(month);
        month = {value:8,label:'Agustus'};
        datamonth.push(month);
        month = {value:9,label:'September'};
        datamonth.push(month);
        month = {value:10,label:'Oktober'};
        datamonth.push(month);
        month = {value:11,label:'November'};
        datamonth.push(month);
        month = {value:12,label:'Desember'};
        datamonth.push(month);
    return datamonth;
}

export const years = (currYear) => {
    let datayears = [];
    for(var i=0; i < 40 ; i++){
        let tempyears = currYear - i;
        let temp = {value:tempyears,label:tempyears}
        datayears.push(temp);
    }
    return datayears;
}

export const maxDataCSV = 500; //-1 = ALL DATA
export const defaultListPageSizes = () => {
    return [10, 25, 50];
}

export const defaultPageSizes = () => {
    return 10;
}

export const img = {
    width: "50%",
    height: "50%"
};

export const addDefaultSrc = (ev) => {
    return ev.target.src = '/img/Simpool_Full_Logo_mobile.png'
}
export const arrayPingURL = () => {
    //https://8.8.8.8 = Google DNS
    //https://8.8.4.4 = Google DNS
    //https://1.1.1.1 = CloudFlare
    //https://1.0.0.1 = CloudFlare
    //https://208.67.222.222 = OpenDNS
    //https://208.67.220.220 = OpenDNS

    //https://puredns.org
    //https://dns.bebasid.com/
    const list =  ["https://dns.bebasid.com","https://208.67.220.220","https://208.67.222.222","https://puredns.org","https://1.0.0.1","https://1.1.1.1","https://www.google.com/","https://8.8.4.4","https://8.8.8.8"];
    let shuffled = list
    .map(value => ({ value, sort: Math.random() }))
    // .sort((a, b) => a.sort - b.sort)
    .sort(() => 0.5 - Math.random())
    .map(({ value }) => value)

    const randomArray = shuffled;
    return randomArray;
}

export const timeOutProgressDataImportBulkData = (param) => {
    /** 1000 = 1 sec */
    return 5000;
}