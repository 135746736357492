import axios        from '../../Axios-Simpool';
// import {put}        from 'redux-saga/effects';
// import * as actions from '../actions';
import {
    getListPromotionManagementURL,getDetailPromotionManagementURL,basePromotionManagementURL,
    depositBillerServiceURL,getListDepositBillerMitraURL,getDepositBillerMitraByTenantURL,
    getTemplateDepositBillerMitraByTenantURL,baseAgentGroupURL,baseAgentURL, loanCollectionSheetURL,baseOfficeUrl,
    getDepositBillerListSettlementURL,baseOfficeApprovalURL,transactionlimitUrl,employmentLevelUrl,baseorganisationpositionURL, baseorganisationstructureURL,
    baseOfficeApprovalLoanURL, baseUserGroupURL,loanCollectionSheetPagingURL,
    loanCollectionSheetParamURL
}                   from '../../shared/apiUrl';

import {toLogout} from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* getListPromotionManagementSaga(action) {    
    try {
        const response = yield axios.get(getListPromotionManagementURL).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* getDepositBillerServiceSaga(action) {    
    try {
        const response = yield axios.get(depositBillerServiceURL(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}


export function* submitAddDepositBillerServiceSaga(action) {    
    try {
        const response = yield axios.post(depositBillerServiceURL(''),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDetailPromotionManagementSaga(action) {    
    try {
        const response = yield axios.get(getDetailPromotionManagementURL(action.id)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* submitEditDepositBillerServiceSaga(action) {    
    try {
        const response = yield axios.put(depositBillerServiceURL('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddPromotionManagementSaga(action) {
    try {
        const response = yield axios.post(basePromotionManagementURL,action.payload).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitEditPromotionManagementSaga(action) {
    try {
        const response = yield axios.put(getDetailPromotionManagementURL(action.id),action.payload).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitDeletePromotionManagementSaga(action) {
    try {
        const response = yield axios.delete(getDetailPromotionManagementURL(action.id)).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}
export function* submitDeleteDepositBillerServiceSaga(action) {    
    try {
        const response = yield axios.post(depositBillerServiceURL('/delete'),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getListDepositBillerMitraSaga(action) {    
    try {
        const response = yield axios.get(getListDepositBillerMitraURL).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}


export function* getDepositBillerMitraByTenantSaga(action) {    
    try {
        const response = yield axios.get(getDepositBillerMitraByTenantURL(action.tenantid)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getTemplateDepositBillerMitraByTenantSaga(action) {    
    try {
        const response = yield axios.get(getTemplateDepositBillerMitraByTenantURL(action.tenantid)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataAgentGroupSaga(action) {    
    try {
        const response = yield axios.get(baseAgentGroupURL(action.param)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddComissionAgentSaga(action) {    
    try {
        const response = yield axios.post(baseAgentGroupURL('/commission'),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteComissionAgentSaga(action) {
    try {
        const response = yield axios.delete(baseAgentGroupURL('/commission/'+action.id)).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitAddAgentGroupSaga(action) {    
    try {
        const response = yield axios.post(baseAgentGroupURL(''),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditAgentGroupSaga(action) {    
    try {
        const response = yield axios.put(baseAgentGroupURL('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteAgentGroupSaga(action) {    
    try {
        const response = yield axios.delete(baseAgentGroupURL('/'+action.id)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}


export function* submitEditComissionAgentSaga(action) {    
    try {
        const response = yield axios.put(baseAgentGroupURL('/commission/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* getDataOfficesSaga(action) {    
    try {
        const response = yield axios.get(baseOfficeUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataAgentSaga(action) {    
    try {
        const response = yield axios.get(baseAgentURL(action.param)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddSpecialComissionAgentSaga(action) {    
    try {
        const response = yield axios.post(baseAgentURL('/specialcommission'),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditSpecialComissionAgentSaga(action) {    
    try {
        const response = yield axios.put(baseAgentURL('/specialcommission/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteSpecialComissionAgentSaga(action) {    
    try {
        const response = yield axios.delete(baseAgentURL('/specialcommission/'+action.id)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* submitAddOfficesSaga(action) {    
    try {
        const response = yield axios.post(baseOfficeUrl(''),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddAgentSaga(action) {    
    try {
        const response = yield axios.post(baseAgentURL(''),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* submitEditOfficesSaga(action) {    
    try {
        const response = yield axios.put(baseOfficeUrl('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditAgentSaga(action) {    
    try {
        const response = yield axios.put(baseAgentURL('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteAgentSaga(action) {    
    try {
        const response = yield axios.delete(baseAgentURL('/'+action.id)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getLoanCollectionSheetSaga(action) {    
    try {
        const response = yield axios.get(loanCollectionSheetURL).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        // toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages);
    }
}

export function* getListDepositBillerSettlementSaga(action) {    
    try {
        const response = yield axios.get(getDepositBillerListSettlementURL(action.tenantid)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataOfficesApprovalSaga(action) {    
    try {
        const response = yield axios.get(baseOfficeApprovalURL(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddOfficeApprovalSaga(action) {    
    try {
        const response = yield axios.post(baseOfficeApprovalURL(''),action.payload).then(res => res.data);
        
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
        // action.errorHandler('error');
    }
}

export function* submitDeleteOfficeApprovalSaga(action) {    
    try {
        const response = yield axios.delete(baseOfficeApprovalURL(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitEditOfficeApprovalSaga(action) {    
    try {
        const response = yield axios.put(baseOfficeApprovalURL('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getTransactionLimitSaga(action) {    
    try {
        const response = yield axios.get(transactionlimitUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataEmploymentLevelSaga(action) {    
    try {
        const response = yield axios.get(employmentLevelUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddTransactionLimitSaga(action) {    
    try {
        const response = yield axios.post(transactionlimitUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddEmploymentLevelSaga(action) {    
    try {
        const response = yield axios.post(employmentLevelUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditEmploymentLevelSaga(action) {    
    try {
        const response = yield axios.put(employmentLevelUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteEmploymentLevelSaga(action) {    
    try {
        const response = yield axios.delete(employmentLevelUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditTransactionLimitSaga(action) {    
    try {
        const response = yield axios.put(transactionlimitUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteTransactionLimitSaga(action) {    
    try {
        const response = yield axios.delete(transactionlimitUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataOrganisationPositionSaga(action) {
    try {
        const response = yield axios.get(baseorganisationpositionURL(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitOrganisationPositionSaga(action) {    
    let url = action.param.url?action.param.url:'';
    let payload = action.param.payload?action.param.payload:'';
    let type = action.param.type?action.param.type:'';
    try {
        if(type == 'POST'){
            const response = yield axios.post(baseorganisationpositionURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'PUT'){
            const response = yield axios.put(baseorganisationpositionURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'DELETE'){
            const response = yield axios.delete(baseorganisationpositionURL(url)).then(res => res.data);
            action.successHandler(response);
        }
    }catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
        // action.errorHandler(errMessages);
    }
}
        
export function* getOfficesApprovalLoanSaga(action) {    
    try {
        let parameter = action.param.param?action.param.param:'';
        const response = yield axios.get(baseOfficeApprovalLoanURL(parameter)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
        // action.errorHandler(errMessages);
    }
}

export function* submitOfficesApprovalLoanSaga(action) {    
    try {
        let parameter = action.param?action.param.param:'';
        let payload = action.param.payload?action.param.payload:'';
        let type = action.param.type?action.param.type:'';
        if(type == 'POST'){
            const response = yield axios.post(baseOfficeApprovalLoanURL(parameter),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'PUT'){
            const response = yield axios.put(baseOfficeApprovalLoanURL(parameter),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'DELETE'){
            const response = yield axios.delete(baseOfficeApprovalLoanURL(parameter)).then(res => res.data);
            action.successHandler(response);
        }

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getDataOrganisationStructureSaga(action) {
    try {
        const response = yield axios.get(baseorganisationstructureURL(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getUserGroupSaga(action) {    
    try {
        let parameter = action.param.param?action.param.param:'';
        const response = yield axios.get(baseUserGroupURL(parameter)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitOrganisationStructureSaga(action) {    
    let url = action.param.url?action.param.url:'';
    let payload = action.param.payload?action.param.payload:'';
    let type = action.param.type?action.param.type:'';
    try {
        if(type == 'POST'){
            const response = yield axios.post(baseorganisationstructureURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'PUT'){
            const response = yield axios.put(baseorganisationstructureURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'DELETE'){
            const response = yield axios.delete(baseorganisationstructureURL(url)).then(res => res.data);
            action.successHandler(response);
        }
        
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitUserGroupSaga(action) {    
    try {
        let parameter = action.param?action.param.param:'';
        let payload = action.param.payload?action.param.payload:'';
        let type = action.param.type?action.param.type:'';
        if(type == 'POST'){
            const response = yield axios.post(baseUserGroupURL(parameter),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'PUT'){
            const response = yield axios.put(baseUserGroupURL(parameter),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'DELETE'){
            const response = yield axios.delete(baseUserGroupURL(parameter)).then(res => res.data);
            action.successHandler(response);
        }

    } catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getDataCollectionSheetPagingSaga(action) {    
    try {
        const response = yield axios.get(loanCollectionSheetPagingURL(action.queryparam)).then(res => res.data);
        action.successHandler(response,action.actiontype);

    } catch (error) {
        // toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getDataCollectionSheetParamSaga(action) {    
    const paramurl = action.param?.url?action.param.url:'';
    const data = action.param?.data?action.param.data:[];
    try {
        const response = yield axios.get(loanCollectionSheetParamURL(paramurl)).then(res => res.data);
        action.successHandler(response,data);

    } catch (error) {
        // toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}