import axios        from '../../Axios-Simpool';
import { submitDataImportFileFinancingRepaymentURL,getTemplateSimpoolBulkURL, uploadFileSimpoolBulkURL, downloadResultFileSimpoolBulkURL, progressSimpoolBulkURL, listSimpoolBulkURL } from '../../shared/apiUrl';
import {toLogout} from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* submitDataImportFileFinancingRepaymentSaga(action) {    
    let propsdata = action.param.propsdata?action.param.propsdata:[];
    try {
        let parameter = action.param?.parameter?action.param.parameter:'';
        let payload = action.param.payload?action.param.payload:'';
        let type = action.param.type?action.param.type:'';
        if(type == 'POST'){
            const response = yield axios.post(submitDataImportFileFinancingRepaymentURL(parameter),payload).then(res => res.data);
            action.successHandler(response,propsdata);
        }
    } catch (error) {
        // toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}),propsdata);
    }
}

export function* getTemplateSimpoolBulkSaga(action) {    
    let propsdata = action.param.propsdata?action.param.propsdata:[];
    try {
        let url = action.param?.url?action.param.url:'';
        let resType = 'arraybuffer';
        let typeFile = '';
        // if(action.typefile === 'application/pdf' || action.typefile === 'application/vnd.ms-excel' || action.typefile === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        if(action.typefile === 'excel'){
            // typeFile = 'application/vnd.ms-excel';
            typeFile = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }

        const response = yield axios.get(getTemplateSimpoolBulkURL(url),{
            responseType: resType,
            headers: {
                Accept: typeFile,
            },
        }).then(res => res.data);
        action.successCallback(response,propsdata);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}),propsdata);
    }
}

export function* downloadFileResultSimpoolBulkSaga(action) {    
    let propsdata = action.param.propsdata?action.param.propsdata:[];
    try {
        let url = action.param?.url?action.param.url:'';
        let resType = 'arraybuffer';
        let actTypeFile = action.param?.typefile?action.param.typefile:'';
        let typeFile = '';
        // if(action.typefile === 'application/pdf' || action.typefile === 'application/vnd.ms-excel' || action.typefile === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        if(actTypeFile === 'excel'){
            // typeFile = 'application/vnd.ms-excel';
            typeFile = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }

        const response = yield axios.get(downloadResultFileSimpoolBulkURL(url),{
            responseType: resType,
            headers: {
                Accept: typeFile,
            },
        }).then(res => res.data);
        action.successCallback(response,propsdata);
        // const response = yield axios.get(downloadResultFileSimpoolBulkURL(url)).then(res => res.data);
        // action.successCallback(response,propsdata);
    } catch (error) {
        console.log('error ',error);
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}),propsdata);
    }
}

export function* uploadFileSimpoolBulkSaga(action) {    
    let propsdata = action.param.propsdata?action.param.propsdata:[];
    try {
        let url = action.param?.url?action.param.url:'';
        let payload = action.param?.payload?action.param.payload:'';
        const response = yield axios.post(uploadFileSimpoolBulkURL(url),payload).then(res => res.data);
        action.successCallback(response,propsdata);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(getErrorJsonData({errors:error}),propsdata);
    }
}

export function* progressSimpoolBulkSaga(action) {    
    let propsdata = action.param.propsdata?action.param.propsdata:[];
    try {
        let url = action.param?.url?action.param.url:'';
        const response = yield axios.get(progressSimpoolBulkURL(url)).then(res => res.data);
        action.successCallback(response,propsdata);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(getErrorJsonData({errors:error}),propsdata);
    }
}

export function* getListSimpoolBulkSaga(action) {    
    let propsdata = action.param.propsdata?action.param.propsdata:[];
    try {
        let url = action.param?.url?action.param.url:'';
        const response = yield axios.get(listSimpoolBulkURL(url)).then(res => res.data);
        action.successCallback(response,propsdata);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(getErrorJsonData({errors:error}),propsdata);
    }
}