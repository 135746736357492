import * as actions from './actionTypes';

export const getTokenService = (successCallback, errorCallback) => {
    return {
        type: actions.GET_TOKEN_SERVIE,
        successCallback,
        errorCallback
    }
};

export const getListTransactionHistory = (payload,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_TRANSACTION_HISTORY,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getListTransactionHistoryTopUp = (payload,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_TRANSACTION_HISTORY_TOP_UP,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getTemplateToken = (successCallback, errorCallback) => {
    return {
        type: actions.GET_TEMPLATE_TOKEN,
        successCallback,
        errorCallback
    }
};

export const createToken = (payload,successCallback, errorCallback) => {
    return {
        type: actions.CREATE_TOKEN,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getTemplateServiceToken = (successCallback, errorCallback) => {
    return {
        type: actions.GET_TEMPLATE_SERVICE_TOKEN,
        successCallback,
        errorCallback
    }
};

export const insertServiceToken = (payload,successCallback, errorCallback) => {
    return {
        type: actions.INSERT_SERVICE_TOKEN,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getServiceChatBot = (successCallback, errorCallback) => {
    return {
        type: actions.EXTERNAL_SERVICE_CHAT_BOT,
        successCallback,
        errorCallback
    }
};

export const getTokenRooc = (payload,successCallback, errorCallback) => {
    return {
        type: actions.GET_TOKEN_ROOC,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getListTransactionHistoryParam = (payload,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_TRANSACTION_HISTORY_PARAM,
        payload:payload,
        successCallback,
        errorCallback,
        actiontype:actiontype,
    }
};

export const getListTransactionHistoryTopUpParam = (payload,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_TRANSACTION_HISTORY_TOP_UP_PARAM,
        payload:payload,
        successCallback,
        errorCallback,
        actiontype:actiontype,
    }
};

export const getTokenData = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_TOKEN_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitToken = (param,payload,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_TOKEN,
        param:param,
        payload:payload,
        actiontype:actiontype,
        successCallback,
        errorCallback,
        
    }
};

export const getTopUpData = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_TOPUP,
        param:param,
        successCallback,
        errorCallback
    }
};