import * as actions from './actionTypes';

export const initMemberList = query => {
    return {
        type: actions.INIT_MEMBER_LIST,
        query: query
    };
};

export const setMemberData = (data) => {
    return {
        type: actions.FETCH_MEMBER_LIST,
        data: data,
    };
};

export const fetchMemberFailed = () => {
    return {
        type: actions.FETCH_MEMBER_FAILED
    };
};

export const initMemberTemplate = () => {
    return {
        type: actions.INIT_MEMBER_TEMPLATE,
    };
};

export const fetchMemberTemplate = (data) => {
    return {
        type: actions.FETCH_MEMBER_TEMPLATE,
        template: data,
    };
};

export const fetchMemberTemplateFailed = () => {
    return {
        type: actions.FETCH_MEMBER_TEMPLATE_FAILED
    };
};

export const checkDuplicateMember = (payload, checkDuplicateResult, errorHandler) => {
    return {
        type: actions.CHECK_DUPLICATE,
        payload: payload,
        checkDuplicateResult,
        errorHandler
    };
};

export const initMemberAdd = (payload, uploadFiles, errorHandler) => {
    return {
        type: actions.INIT_MEMBER_ADD,
        payload: payload,
        uploadFiles,
        errorHandler,
    };
};

export const memberAddStart = () => {
    return {
        type: actions.MEMBER_ADD_START
    };
};

export const memberAddFailed = () => {
    return {
        type: actions.MEMBER_ADD_FAILED,
    };
};

export const memberAddSuccess = () => {
    return {
        type: actions.MEMBER_ADD_SUCCESS,
    };
};

export const memberAddImage = (id, image, successHandler, errorHandler) => {
    return {
        type: actions.MEMBER_ADD_IMAGE,
        id: id,
        image: image,
        successHandler,
        errorHandler,
    }
}

export const memberAddImageStart = () => {
    return {
        type: actions.MEMBER_ADD_IMAGE_START
    };
};

export const memberAddImageFailed = () => {
    return {
        type: actions.MEMBER_ADD_IMAGE_FAILED
    };
};

export const memberAddImageSuccess = () => {
    return {
        type: actions.MEMBER_ADD_IMAGE_SUCCESS,
    };
};

export const memberAddDocument = (id, document, successHandler, errorHandler) => {
    return {
        type: actions.MEMBER_ADD_DOCUMENT,
        id: id,
        document: document,
        successHandler,
        errorHandler,
    }
};

export const memberAddDocumentStart = () => {
    return {
        type: actions.MEMBER_ADD_DOCUMENT_START
    };
};

export const memberAddDocumentFailed = () => {
    return {
        type: actions.MEMBER_ADD_DOCUMENT_FAILED
    };
};

export const memberAddDocumentSuccess = () => {
    return {
        type: actions.MEMBER_ADD_DOCUMENT_SUCCESS,
    };
};


export const ReportMember = (param,typefile,successHandler, errorHandler) => {
    return {
        type: actions.REPORTS_MEMBER,
        param:param,
        typefile:typefile,
        successHandler,
        errorHandler,
    }
};

export const listTemplate2 = (successHandler, errorHandler) => {
    return {
        type: actions.INIT_MEMBER_TEMPLATE_2,
        successHandler,
        errorHandler,
    }
};

export const getSavingsByClientID = (clientid,successHandler, errorHandler) => {
    return {
        type: actions.GET_SAVINGS_BY_CLIENTID,
        clientid:clientid,
        successHandler,
        errorHandler,
    }
};

export const getTemplateClientClose = (successHandler, errorHandler) => {
    return {
        type: actions.GET_TEMPLATE_CLIENT_CLOSE,
        successHandler,
        errorHandler,
    }
};

export const submitCloseMember = (clientid,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_CLOSE_MEMBER,
        clientid:clientid,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const getParsingNIK = (payload,successHandler, errorHandler) => {
    return {
        type: actions.GET_PARSING_NIK,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitCommandMemberPost = (id,command,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_COMMAND_MEMBER_POST,
        id:id,
        command:command,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getListMember = (query,successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_MEMBER,
        query:query,
        successHandler,
        errorHandler,
    }
};


export const getDataClientJob = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_CLIENTJOB,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddClientJob = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_CLIENTJOB,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditClientJob = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_CLIENTJOB,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteClientJob = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_CLIENTJOB,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataClientApproval = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_CLIENT_APPROVAL_DATA,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitClientApproval = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_CLIENT_APPROVAL,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitClientApprovalMultiple = (param,payload,arrIds,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_CLIENT_APPROVAL_MULTIPLE,
        param:param,
        arrids:arrIds,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataClientApprovalListActivation = (param,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_CLIENT_APPROVAL_DATA_LIST_ACTIVATION,
        param:param,
        actiontype:actiontype,
        successHandler,
        errorHandler,
    }
};

export const getDataMemberTemplate = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_MEMBER_TEMPLATE,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getCheckDuplicateMember = (param,successHandler, errorHandler) => {
    return {
        type: actions.CHECK_DUPLICATE_MEMBER,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddMemberEntity = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_ENTITY_MEMBER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataEntityMember = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_ENTITY_MEMBER,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataPersonMember = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_PERSON_MEMBER,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataMemberWithParam = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_MEMBER_WITH_PARAM,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditMemberEntity = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_ENTITY_MEMBER,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitUpdateEmployeeInformation = (id,command,payload,to,row,extractresult,listdata,listerr,listdone,dispatch,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_UPDATE_EMPLOYEE_INFORMATION_DATA_IMPORT,
        id:id,
        row:row,
        command:command,
        payload:payload,
        listdata:listdata,
        extractresult:extractresult,
        to:to,
        listerr:listerr,
        dispatch:dispatch,
        listdone:listdone,
        successHandler,
        errorHandler
    }
};

export const submitDataImportUploadClient = (payload,to,row,extractresult,listdata,listerr,listdone,dispatch,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_DATA_IMPORT_UPLOAD_CLIENT,
        row:row,
        payload:payload,
        listdata:listdata,
        extractresult:extractresult,
        to:to,
        listerr:listerr,
        dispatch:dispatch,
        listdone:listdone,
        successCallback,
        errorCallback
    }
};

export const getDataMemberParam = (param,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_MEMBER,
        param:param,
        actiontype:actiontype,
        successHandler,
        errorHandler,
    }
};

export const getTransferClientHistory = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_TRANSFER_CLIENT_HISTORY,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitCommandMemberPostMultiple = (id,command,payload,listid,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_COMMAND_MEMBER_POST_MULTIPLE,
        id:id,
        command:command,
        payload:payload,
        listid:listid,
        successHandler,
        errorHandler,
    }
};