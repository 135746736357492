import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { Link, withRouter  } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect,useSelector,useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as actions from '../../store/actions/actionTypes';
import * as actionss from '../../store/actions';

import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';
import Swal                     from 'sweetalert2';

import Menu from '../../Menu.js';
import { numToMoney,getPermissions,isGetPortalMenuPermission } from '../../shared/maskFunc';
import * as pathmenu from '../../shared/pathMenu';
// import { Portal_Teller,Portal_HeadTeller} from '../../shared/PermissionForFeatures';

import IconAccounting                 from '../../components/IconMenuUtama/IconAccounting';
import IconApproval                 from '../../components/IconMenuUtama/IconApproval';
import IconBiller                 from '../../components/IconMenuUtama/IconBiller';
import IconCashier                 from '../../components/IconMenuUtama/IconCashier';
import IconCoopUnit                 from '../../components/IconMenuUtama/IconCoopUnit';
import IconDashboard                 from '../../components/IconMenuUtama/IconDashboard';
import IconDataImport                 from '../../components/IconMenuUtama/IconDataImport';
import IconReport                 from '../../components/IconMenuUtama/IconReport';
import IconToken                 from '../../components/IconMenuUtama/IconToken';
import IconMonitoring                 from '../../components/IconMenuUtama/IconMonitoring';
import { Portal_HeadTeller, Portal_Teller } from '../../shared/PermissionForFeatures';
// import {useDispatch, useSelector}   from 'react-redux';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)
const shownumber = (path,subMenuCount) => {
    let flag = subMenuCount?.dataar ? true:false;
    if(flag){
        for(var i=0; i < subMenuCount.dataar.length; i++){
            if(path === subMenuCount.dataar[i].path){
                let number = '0';
                if(!isNaN(subMenuCount.dataar[i].number) ){
                    number = numToMoney(subMenuCount.dataar[i].number);
                }
                return number;
            }
        }
    }
    return '';   
}
const checkedMenu = (path) => {
    let flag = false;
    if(path === '/approval/financing-approval'){
        flag = true;
    }else if(path === '/approval/financing-disbursment'){
        flag = true;
    }
    return flag;
}
const customFontSize = (path) => {
    let flag = checkedMenu(path);
    let hasil = new Map();
    //large
    //medium
    //small
    //smaller
    if(flag){
        hasil.set('menuname','small');
        hasil.set('number','smaller');
    }
    return hasil;
}

const cekPortal = (portalmenu,value) => {
    let isarray = Array.isArray(portalmenu);
    let flag = false;
    if(isarray){
        if(portalmenu.indexOf(value) > -1){
            flag = true;
        }
    }
    return flag;
}
const hideMenuItem = (path,submenupermission,type,portalmenu,portalgrup) => {
    const length = submenupermission.length;
    
    if(path == pathmenu.memberapproval && !sessionStorage.getItem('iskopkar')){
        return true
    }else if(path == pathmenu.reportBiller && localStorage.getItem('tenant') !== '990000'){
        return true
    }else if(path === '/token/tokenservice' && localStorage.getItem('tenant') === '990000'){
        return true
    }else if(path == pathmenu.monitoring && localStorage.getItem('tenant') !== '990000'){
        return true
    }
    // else if(cekPortal(portalmenu,'CEK_CASHIER')){
    //     let flag = isGetPortalMenuPermission(Portal_HeadTeller,[]);
    //     if(flag){
    //         flag = isGetPortalMenuPermission(Portal_Teller,[]);
    //     }
    //      return flag;
    // }
    else {
        return  isGetPortalMenuPermission(portalmenu,portalgrup);
        // if(type === 'READ'){
        //     if(getPermissions().indexOf('ALL_FUNCTIONS_READ') > -1 || getPermissions().indexOf('ALL_FUNCTIONS') > -1){
        //         return false;
        //     }
        // }

        // if(type === 'TRANSACTION'){
        //     if(getPermissions().indexOf('ALL_FUNCTIONS') > -1){
        //         return false;
        //     }
        // }
        // // if(portalmenu.length > 0){
            
        //     // if(portalmenu.indexOf('ISHEADER') == -1 || (!flagGetPortal && portalmenu.indexOf('ISHEADER') > -1)){
        //     //     return flagGetPortal;
        //     // }
        //     // return flagGetPortal;
        // // }
        //  if(length > 0){
        //     let countPermission = 0;
        //     let countingReport = 0;
        //     for (var i = 0; i < length; i++) {
        //         if(submenupermission[i].permissionsreport){
        //             let permissionsrport = submenupermission[i].permissionsreport;
        //             let counting = 0;
                    
        //             for (var x = 0; x < permissionsrport.length; x++) {
        //                 if(getPermissions().indexOf(permissionsrport[x]) > -1){
        //                     counting++;
        //                 }
        //             }
        //             if(counting == permissionsrport.length){
        //                 countingReport++;
        //             }
                    
        //         }else{
        //             if(getPermissions().indexOf(submenupermission[i]) > -1){
        //                 countPermission++;
        //             }
        //         }
        //     }
        //     if(countingReport > 0){
        //         return false;
        //     }
        //     return !(length == countPermission);
        // }
    }
    return true
}
const hideMenuHeader = (item) => {
    
    if(item.name === 'Token' && localStorage.getItem('tenant') === '990000'){
        // if(localStorage.getItem('tenant') === '990000'){
            const length = item.submenu.length;
            let countIsFlag = 0;
            for (var i = 0; i < length; i++) {
                //dikasih flag, ini karena dilakukan pengecekan dahulu, jika semua sub menu kena hidden, 
                //maka headernya pun di Hide, tapi jika hanya beberapa Submenu yang di Hide, maka Headernya tidak ter Hide
                const flag = hideMenuItem(item.submenu[i].path,item.submenu[i].permissions,item.submenu[i].type,item.submenu[i].portalmenu,item.submenu[i].portalgrup);
                if(flag){
                    countIsFlag++;
                }
            }
            //melakukan pengecekan jika jumlah submenu yang di hide, sama dengan jumlah sub menu maka Header di Hide
            if(length == countIsFlag){
                return true
            }
            // return false
            
        // }
    }else if(item.name === 'Cashier'){
        let flagTeller = !isGetPortalMenuPermission(Portal_Teller,[]);
        let flagHeadTeller = !isGetPortalMenuPermission(Portal_HeadTeller,[]);
        if(flagTeller || flagHeadTeller){
            return false;
        }else{
            return true;
        }
        // return isGetPortalMenuPermission(item.portalmenu,[]);
    }else{
        // return isGetPortalMenuPermission(item.portalmenu,[]);
        // if(item.portalmenu.length > 0){
        //     let flagGetPortal = isGetPortalMenuPermission(item.portalmenu) ;
        //     if(item.portalmenu.indexOf('ISHEADER') == -1 || (!flagGetPortal && item.portalmenu.indexOf('ISHEADER') > -1) ){
        //         return flagGetPortal;
        //     }
        // }
            if(item.submenu){
                // console.log('name ',item.submenu.name)
            // if(item.submenu.portalmenu.length > 0){
            //     return isGetPortalMenuPermission(item.submenu.portalmenu);
            // }
            const length = item.submenu.length;
            let countIsFlag = 0;
            for (var i = 0; i < length; i++) {
                const flag = hideMenuItem(item.submenu[i].path,item.submenu[i].permissions,item.submenu[i].type,item.submenu[i].portalmenu,item.submenu[i].portalgrup);
                if(flag){
                    countIsFlag++;
                }
            }
            // if(item.name == 'Member'){
            //     console.log('Member ',countIsFlag+' | '+length);
            // }
            if(countIsFlag == 0 || (countIsFlag > 0 && length > countIsFlag) ){
                return false;
            }
            //Jika semua sub menu nya kosong, tapi menu header ingin hilang juga, remark aja else dibawah. jika ingin tampil buka remark
            // else{
            //     return hideMenuItem(item.path,item.permissions,item.type,item.portalmenu,[]);
            // }

            }else{
                return hideMenuItem(item.path,item.permissions,item.type,item.portalmenu,[]);
            }
        
        return true;
    }
    return true;
}
/** Normal items for the sidebar */
const SidebarItem = ({item, isActive, tenant,subMenuCount}) => (
    <li className={ isActive ? 'active' : '' } hidden={hideMenuItem(item.path,item.permissions,item.type,item.portalmenu,item.portalgrup?item.portalgrup:[])}>
        <Link
            to={{
                pathname: item.path,
            }}
            title={item.name}>
            {item.label && <Badge tag="div" className="float-right"  color={item.label.color}>{item.label.value}</Badge>}
            {/* {item.label && <Badge tag="div" className="float-right"  color={item.label.color}>{shownumber(item.path,subMenuCount)}</Badge>} */}
            {setIcon(item)}
            {/* {item.icon && <em className={item.icon}/>} */}
            {/* {item.icon && <em><IconTarik/></em>} */}
            <span style={{fontSize:customFontSize(item.path).get('menuname') }}><Trans i18nKey={item.translate}>{item.name}</Trans></span>
            <span style={{fontSize:customFontSize(item.path).get('number') }}>{' '+shownumber(item.path,subMenuCount)}</span>
        </Link>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' } hidden={hideMenuHeader(item)}>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {setIcon(item)}
            {/* {item.icon && <em className={item.icon}/>} */}
            {/* {item.icon && <IconButton color={'primary'} ><IconTarik/> </IconButton>} */}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

const setIcon = (item) =>{
    if(item.icon){
        if(item.icon == 'icon-home' || item.icon == 'icon-people' || item.icon == 'icon-calculator' || item.icon == 'icon-notebook' || item.icon == 'icon-equalizer'){
            return <em className={item.icon} />
        }else if(item.icon == 'icon-accounting'){
            return <em><IconAccounting/></em>
        }else if(item.icon == 'icon-approval'){
            return <em><IconApproval/></em>
        }else if(item.icon == 'icon-biller'){
            return <em><IconBiller/></em>
        }else if(item.icon == 'icon-cashier'){
            return <em><IconCashier/></em>
        }else if(item.icon == 'icon-coopunit'){
            return <em><IconCoopUnit/></em>
        }else if(item.icon == 'icon-dashboard'){
            return <em><IconDashboard/></em>
        }else if(item.icon == 'icon-dashboard'){
            return <em><IconDashboard/></em>
        }else if(item.icon == 'icon-dataimport'){
            return <em><IconDataImport/></em>
        }else if(item.icon == 'icon-report'){
            return <em><IconReport/></em>
        }else if(item.icon == 'icon-token'){
            return <em><IconToken/></em>
        }else if(item.icon == 'icon-monitoring'){
            return <em><IconMonitoring/></em>
        }
        
        
    }
    return <em/>
}

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

class Sidebar extends Component {

    state = {
        collapse: {},
        submenucount: [],
        // approvalfinancing:''
    }
    buildnumber = (query) => {
        if(getPermissions().indexOf('READ_LOAN') > -1 || getPermissions().indexOf('ALL_FUNCTIONS_READ') > -1 || getPermissions().indexOf('ALL_FUNCTIONS') > -1){
            
            // this.props.countapproval(query,this.successHandler,this.errorHandler);
        }
    }
    successHandler = (data, origin) => {
        const hasil = this.checkRedux();
        let dataApproval = [];
        let dataDisbursment = [];
        if ( hasil.get('approval') && hasil.get('disbursment') ){
            dataApproval = data.pageItems?data.pageItems.filter(s => s.status.id == 100):[];
            dataDisbursment = data.pageItems?data.pageItems.filter(s => s.status.id == 200):[];
            let dataApprovalJSON = {pageItems:dataApproval};
            let dataDisbursmentJSON = {pageItems:dataDisbursment};
            this.props.setApproval(dataApprovalJSON);
            this.props.setDisbursment(dataDisbursmentJSON);
        } else if (hasil.get('disbursment')){
            dataDisbursment = data.pageItems?data.pageItems:[];
            dataApproval = hasil.get('approvalrows');
            let dataDisbursmentJSON = {pageItems:dataDisbursment};
            this.props.setDisbursment(dataDisbursmentJSON);
        }else if (hasil.get('approval')){
            dataDisbursment = hasil.get('disbursmentrows');
            dataApproval = data.pageItems?data.pageItems:[];
            let dataApprovalJSON = {pageItems:dataApproval};
            this.props.setApproval(dataApprovalJSON);
        }
        let dataar = [];
        let val = {path:'/approval/financing-approval',number:dataApproval.length};
        dataar.push(val);
        val = {path:'/approval/financing-disbursment',number:dataDisbursment.length};
        dataar.push(val);
        this.setState({submenucount:{dataar}});
    };

    errorHandler = (error) => {
        const swalOption = {
            icon: 'error',
            title: 'Oops...',
        };
        Swal.fire(Object.assign(swalOption, {text: error}));
    }

    checkRedux = () => {
        let hasil = new Map();
        hasil.set('approval',true);
        hasil.set('approvalrows',[]);
        hasil.set('disbursment',true);
        hasil.set('disbursmentrows',[]);
        const listDataDisbursment = this.props.dataDisbursment;
        const listDataApproval = this.props.dataApproval;
        if (listDataDisbursment.rows == [] || listDataDisbursment.lastUpdate === null){
        }else{
            hasil.set('disbursment',false);
            hasil.set('disbursmentrows',listDataDisbursment.rows);
        }
        if (listDataApproval.rows == [] || listDataApproval.lastUpdate === null){
        }else{
            hasil.set('approval',false);
            hasil.set('approvalrows',listDataApproval.rows);
        }
        
        return hasil;
    }
    
    componentDidMount() {
        const hasil = this.checkRedux();
        if(getPermissions().indexOf('READ_LOAN') > -1 || getPermissions().indexOf('ALL_FUNCTIONS_READ') > -1 || getPermissions().indexOf('ALL_FUNCTIONS') > -1){
            // if ( hasil.get('approval') && hasil.get('disbursment') ){
            //     this.buildnumber('l.loan_status_id in (100,200)');
            // }else if (hasil.get('disbursment')){
            //     this.buildnumber('l.loan_status_id in (200)');
            // }else if (hasil.get('approval')){
            //     this.buildnumber('l.loan_status_id in (100)');
            // }
        }
        
        // pass navigator to access router api
        SidebarRun(this.navigator, this.closeSidebar);
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()

        // Listen for routes changes in order to hide the sidebar on mobile
        this.props.history.listen(this.closeSidebar);
    }

    // componentDidUpdate(){
    //     this.buildnumber();
    // }

    closeSidebar = () => {
        this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};
        Menu
            .filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
            })
        this.setState({collapse:{collapse}});
    }

    navigator = route => {
        this.props.history.push(route.replace('#','')); // remove '#' in case of use HashRouter
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({path}) => path)

    /** map menu config to string to determine which element to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    render() {
        return (
            <aside className='aside-container' >
                { /* START Sidebar (left) */ }
                <div className="aside-inner" >
                    <nav data-sidebar-anyclick-close="" className="sidebar" >
                        { /* START sidebar nav */ }
                        <ul className="sidebar-nav" >
                            { /* START user info */ }
                            <li className="has-user-block">
                                <SidebarUserBlock/>
                            </li>
                            { /* END user info */ }

                            { /* Iterates over all sidebar items */ }
                            {
                                Menu.map((item, i) => {
                                    // heading
                                    if(this.itemType(item) === 'heading')
                                        return (
                                            <SidebarItemHeader item={item} key={i} />
                                        )
                                    else {
                                        if(this.itemType(item) === 'menu')
                                            return (
                                                <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                            )
                                        if(this.itemType(item) === 'submenu')
                                            return [
                                                <SidebarSubItem item={item} isOpen={this.state.collapse[item.name]} handler={ this.toggleItemCollapse.bind(this, item.name) } isActive={this.routeActive(this.getSubRoutes(item))} key={i} >
                                                    <SidebarSubHeader item={item} key={i} />
                                                    {
                                                        item.submenu.map((subitem, i) =>
                                                            <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} subMenuCount={this.state.submenucount} />
                                                        )
                                                    }
                                                </SidebarSubItem>
                                            ]
                                    }
                                    return null; // unrecognized item
                                })
                            }
                        </ul>
                        { /* END sidebar nav */ }
                    </nav>
                </div>
                { /* END Sidebar (left) */ }
            </aside>
        );
    }
}

Sidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    unwrap: PropTypes.bool
};

const mapStateToProps = state => ({ 
    settings: state.settings,
    dataDisbursment: state.loandisbursment,
    dataApproval: state.loanneedapproval
    

});
const mapDispatchToProps = dispatch => ({ 
    actions: bindActionCreators(actions, dispatch),
    countapproval:(query,successHandler, errorHandler) => dispatch(actionss.getListLoanSearchQuery(query,successHandler, errorHandler)),
    setApproval:(data) => dispatch(actionss.setListLoanNeedApproval(data)),
    setDisbursment:(data) => dispatch(actionss.setListLoanNeedDisbursment(data)),
    });
    
export default compose(connect(mapStateToProps, mapDispatchToProps),(withTranslation('translations')))(withRouter(Sidebar));
