import * as actions from './actionTypes';


export const setListMobileUser = (data) => {
    return {
        type: actions.FETCH_MOBILEUSER_LIST,
        data: data,
    };
};

export const initGetListMobileUser = (successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_MOBILEUSER,
        successCallback,
        errorCallback
    }
};
export const getListMobileUserStart = () => {
    return {
        type: actions.GET_LIST_MOBILEUSER_START
    }
};
export const getListMobileUserFailed = () => {
    return {
        type: actions.GET_LIST_MOBILEUSER_FAILED
    }
};
export const getListMobileSuccess = () => {
    return {
        type: actions.GET_LIST_MOBILEUSER_SUCCESS
    }
};

export const getmobileuserbyid  = (userid,successHandler, errorHandler) => {
    return {
        type: actions.GET_MOBILEUSER_BY_ID,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const deletemobileuserbyid  = (userid,successHandler,errorHandler) => {
    return {
        type: actions.DELETE_MOBILEUSER_BY_ID,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const unlockmobileuserbyid  = (userid,successHandler,errorHandler) => {
    return {
        type: actions.UNLOCK_MOBILEUSER_BY_ID,
        userid:userid,
        successHandler,
        errorHandler,
    }
};


export const resendPasswordMobileUser  = (username,successHandler,errorHandler) => {
    return {
        type: actions.RESEND_PASSWORD_MOBILEUSER,
        username:username,
        successHandler,
        errorHandler,
    }
};

export const createChangePasswordMobileUser  = (userid,successHandler,errorHandler) => {
    return {
        type: actions.CREATE_CHANGE_PASSWORD_MOBILEUSER,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const confirmChangePasswordMobileUser  = (payload,userid,successHandler,errorHandler) => {
    return {
        type: actions.CONFIRM_CHANGE_PASSWORD_MOBILEUSER,
        payload:payload,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const updateMobileUser  = (payload,clientid,userid,successHandler,errorHandler) => {
    return {
        type: actions.UPDATE_MOBILE_USER,
        payload:payload,
        userid:userid,
        clientid:clientid,
        successHandler,
        errorHandler,
    }
};

export const resetPinMobileUser  = (payload,successHandler,errorHandler) => {
    return {
        type: actions.RESET_PIN_MOBILE_USER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const unlockconfirmmobileuserbyid  = (userid,payload,successHandler,errorHandler) => {
    return {
        type: actions.UNLOCK_CONFIRM_MOBILEUSER_BY_ID,
        userid:userid,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getMobileUserByUsername  = (username,successHandler,errorHandler) => {
    return {
        type: actions.GET_MOBILEUSER_BY_USERNAME,
        username:username,
        successHandler,
        errorHandler,
    }
};

export const getOTPResetMobileUser  = (payload,successHandler,errorHandler) => {
    return {
        type: actions.GET_OTP_RESET_MOBILEUSER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitOTPResetMobileUser  = (payload,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_OTP_RESET_MOBILEUSER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getListUserResetPasswordMobile  = (successHandler,errorHandler) => {
    return {
        type: actions.GET_LIST_USER_RESET_PASSWORD_MOBILE,
        successHandler,
        errorHandler,
    }
};

export const submitListUserResetPasswordMobile  = (payload,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_LIST_USER_RESET_PASSWORD_MOBILE,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getExternalUserListPaging = (queryparam,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_EXTERNAL_USER_PAGING,
        actiontype:actiontype,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};