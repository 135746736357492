import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function IconApproval(props) {
    return (
        <SvgIcon {...props} viewBox='0 -30 190 190'>
            <path fillRule='evenodd' className="cls-1" d="M62.72,69.75H99.84a9,9,0,0,1,9,9V113.9a9,9,0,0,1-9,9H62.72a9,9,0,0,1-9-9V78.72a9,9,0,0,1,9-9Zm24-33.24H94.6a6.82,6.82,0,0,1,4.88,2,6.91,6.91,0,0,1,2,4.88V60.21h-6V43.42a1,1,0,0,0-.28-.7,1,1,0,0,0-.71-.28H86.75V60.2H80.44V35H54.21A3.17,3.17,0,0,1,51,31.83V6.29H6.32v95.48H43.76v6.31H16.37V116a1,1,0,0,0,1,1H43.74v5.93H17.39A7,7,0,0,1,10.47,116v-7.88H5.66a5.66,5.66,0,0,1-4-1.65,5.6,5.6,0,0,1-1.66-4V5.65A5.65,5.65,0,0,1,5.66,0H54.21a3.09,3.09,0,0,1,2.42,1.16l27.83,27a3.15,3.15,0,0,1,2.29,3v5.29ZM20,66.89A2,2,0,0,0,18.06,69,2,2,0,0,0,20,71.15h23.7V66.89Zm0,15.26a2,2,0,0,0-1.92,2.13A2,2,0,0,0,20,86.41h23.7V82.15Zm0-45.79A2,2,0,0,0,18,38.49,2,2,0,0,0,20,40.62H41.89a2,2,0,0,0,1.91-2.13,2,2,0,0,0-1.91-2.13ZM20,21.1a2,2,0,0,0-1.92,2.13A2,2,0,0,0,20,25.36H32a2,2,0,0,0,1.91-2.13A2,2,0,0,0,32,21.1Zm0,30.52A2.05,2.05,0,0,0,18,53.76,2,2,0,0,0,20,55.89H57A2.05,2.05,0,0,0,59,53.76,2,2,0,0,0,57,51.62Zm37.4-40.56L76.24,28.69H57.35V11.06ZM72.92,91.7l4.73,4.45L88.94,84.7c1-1,1.63-1.84,2.87-.57l4,4.11c1.32,1.3,1.25,2.06,0,3.28L79.94,107.15c-2.62,2.57-2.17,2.73-4.82.09l-9-8.91a1.16,1.16,0,0,1,.1-1.81l4.66-4.83c.71-.73,1.27-.68,2,0Z"/>
        </SvgIcon>
    );
}

export default IconApproval;