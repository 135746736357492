import React                 from 'react';
// import Tooltip               from '@material-ui/core/Tooltip';
import Button                from '@material-ui/core/Button';
// import Menu                  from '@material-ui/core/Menu';
// import MenuItem              from '@material-ui/core/MenuItem';
import {makeStyles}          from '@material-ui/core/styles';
// import TranslateIcon         from '@material-ui/icons/Translate';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {useTranslation}      from 'react-i18next';
import moment          from 'moment';
import CryptoJS from 'crypto-js';
import { keyEcncrypt } from '../../shared/globalConfig';
import { withRouter  } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    buttonWhite: {
        color: 'white'
    },
    buttonBlack: {
        color: 'white',
        textTransform: 'none'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

function MenuListComposition(props) {
    const classes = useStyles();
    const i18n = useTranslation('translations');

    const handleDateSystem = () => {
        let dateSys = localStorage.getItem('dateSystem') !== undefined && localStorage.getItem('dateSystem') !== null ?localStorage.getItem('dateSystem'):'';
        if(dateSys !== ''){
            try{
            const bytes = CryptoJS.AES.decrypt(dateSys, keyEcncrypt);
            const parseBytes = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            dateSys = parseBytes;
            }catch(err){
                dateSys = '';
            }
        }
        return i18n.t('label_DATE_SYSTEM')+' : '+moment(new Date(dateSys)).format('DD MMM YYYY')  +' / '+moment(new Date()).format('DD MMM YYYY')
    };

    return (
        <div className={classes.root}>
            <Button
                className={props.onMenu ? classes.buttonWhite : classes.buttonBlack}
                // ref={anchorRef}
                aria-controls={'simple-menu'}
                aria-haspopup="true"
                // onClick={handleToggle}
            >
             {/* <span style={{fontSize:'10px'}}>   {i18n.t('label_DATE_SYSTEM')+' : '+moment(new Date(dateSystem)).format('DD MMM YYYY')  +' / '+moment(new Date()).format('DD MMM YYYY')}</span> */}
             <span style={{fontSize:'11px'}}>   {handleDateSystem()}</span>
            </Button>
        </div>
    )
}

export default withRouter(MenuListComposition);