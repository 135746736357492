import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function IconBiller(props) {
    return (
        <SvgIcon {...props} viewBox='0 -30 150 150'>
            <path fillRule='evenodd' className="cls-1" d="M42.29,7.26Zm67.63,84.09L77.43,122.88,60.71,106.14l1.89-2.45,12.25,12.17L75,116a3,3,0,0,0,4.2,0l1.06-1.08,0,0,5-5.17L107.4,88.83l2.52,2.52ZM94.8,59.91V20.06H56.13A30.5,30.5,0,0,0,55,15.15H97.26a2.45,2.45,0,0,1,2.45,2.45V63.88l-2.39-2.39a6.54,6.54,0,0,0-2.52-1.58ZM81.74,44.67H56.32V39.76H81.74v4.91Zm-2,16.3H26.85V56.06H79.74V61Zm-8,16.3H26.85V72.36h50l-5,4.91ZM54.65,103.64l-.54-.46-7.42,7.19a2.46,2.46,0,0,1-3.39,0l-7.67-7.22-7.82,7a2.44,2.44,0,0,1-3.4,0l-8-7.57-3.73,2.21a2.46,2.46,0,0,1-3.83-2V52.21a32,32,0,0,0,4.91,2.32v44l1.75-1a2.46,2.46,0,0,1,3,.41l7.59,7.22L34.05,98a2.44,2.44,0,0,1,3.3,0L45,105.23l7.35-7.13a2.51,2.51,0,0,1,1.32-.66,6.53,6.53,0,0,0,1.83,4.76,6,6,0,0,0-.81,1.44Zm54.74-20.78L76.9,114.39,60.18,97.64,92.67,66.11l16.72,16.75ZM89.39,89a5.38,5.38,0,1,1-6.75-3.49A5.38,5.38,0,0,1,89.39,89Zm12.07-3.33-21.7,20.74a3.49,3.49,0,0,0-4.94,0L68.2,99.82a3.48,3.48,0,0,0,0-4.94L89.9,74.12a3.5,3.5,0,0,0,4.95,0l6.61,6.62a3.51,3.51,0,0,0,0,5ZM19.56,11.75H30a2.91,2.91,0,0,1,2.09.87A3,3,0,0,1,33,14.71V34.85a2.91,2.91,0,0,1-.87,2.09,3,3,0,0,1-2.09.87H19.56a3,3,0,0,1-3-3V14.71a3,3,0,0,1,3-3Zm-.87,21.57H30.86v-18H18.69v18Zm6.09,1.31A1.2,1.2,0,0,1,26,35.84a1.22,1.22,0,1,1-1.21-1.21Zm0-31A21.11,21.11,0,1,1,3.67,24.78,21.11,21.11,0,0,1,24.78,3.67ZM42.3,42.29Zm-35,0Zm0-35A24.75,24.75,0,1,1,0,24.78,24.72,24.72,0,0,1,7.27,7.26Z"/>
        </SvgIcon>
    );
}

export default IconBiller;