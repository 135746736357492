import axios        from '../../Axios-Simpool';
import {
    basePassbookUrl,resetPassbookUrl,printPassbookUrl,passbookCoverUrl,rePrintPassbookUrl,baseReferralCodeURL,loantypePassBookUrl,
    tellerlimitUrl,baseProductTemplateURL, baseProductGroupURL, baseProductGroupDetailsURL
}                   from '../../shared/apiUrl';
import {toLogout} from '../../shared/maskFunc';
import {msgErrorHandler} from '../../shared/globalLangMessage';
import { getErrorJsonData } from '../../shared/utility';

export function* getDataPassbookTypeSaga(action) {    
    try {
        const response = yield axios.get(basePassbookUrl(action.param)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitAddPassbookTypeSaga(action) {    
    try {
        const response = yield axios.post(basePassbookUrl(action.id),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitDeletePassbookTypeSaga(action) {    
    try {
        const response = yield axios.delete(basePassbookUrl(action.id)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitResetPassbookSaga(action) {    
    try {
        const response = yield axios.post(resetPassbookUrl,action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* getPrintPassbookSaga(action) {    
    try {
        const response = yield axios.get(printPassbookUrl(action.accnumber)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitPassbookCoverSaga(action) {    
    try {
        const response = yield axios.post(passbookCoverUrl(''),action.payload).then(res => res.data);
        action.successHandler(response,action.payload);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitPrintPassbookCoverSaga(action) {    
    try {
        const response = yield axios.post(passbookCoverUrl('/printed'),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitPrintPassbookPOSTSaga(action) {    
    try {
        const response = yield axios.post(printPassbookUrl(''),action.payload).then(res => res.data);
        action.successHandler(response,action.payload);
    } catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitRePrintPassbookSaga(action) {    
    try {
        const response = yield axios.post(rePrintPassbookUrl,action.payload).then(res => res.data);
        action.successHandler(response,action.payload);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* getDataReferralCodeSaga(action) {    
    try {
        const response = yield axios.get(baseReferralCodeURL(action.param)).then(res => res.data);
        action.successHandler(response,action.template);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddReferralCodeSaga(action) {    
    try {
        const response = yield axios.post(baseReferralCodeURL(''),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteReferralCodeSaga(action) {
    try {
        const response = yield axios.delete(baseReferralCodeURL('/'+action.id)).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages.join('\n'));
    }
}

export function* submitEditReferralCodeSaga(action) {    
    try {
        const response = yield axios.put(baseReferralCodeURL('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getProductTemplateDataSaga(action) {    
    try {
        const response = yield axios.get(baseProductTemplateURL(action.param)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitAddProductTemplateSaga(action) {    
    try {
        const response = yield axios.post(baseProductTemplateURL(action.param),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitEditProductTemplateSaga(action) {    
    try {
        const response = yield axios.put(baseProductTemplateURL(action.param),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getDataLoanPassbookTypeSaga(action) {    
    try {
        const response = yield axios.get(loantypePassBookUrl(action.param)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitAddLoanPassbookTypeSaga(action) {    
    try {
        const response = yield axios.post(loantypePassBookUrl(''),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitDeleteLoanPassbookTypeSaga(action) {    
    try {
        const response = yield axios.delete(loantypePassBookUrl(action.id)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitEditLoanPassbookTypeSaga(action) {    
    try {
        const response = yield axios.put(loantypePassBookUrl('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* getDataTellerLimitSaga(action) {    
    try {
        const response = yield axios.get(tellerlimitUrl(action.param)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitAddTellerLimitSaga(action) {    
    try {
        const response = yield axios.post(tellerlimitUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitEditTellerLimitSaga(action) {    
    try {
        const response = yield axios.put(tellerlimitUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* submitDeleteTellerLimitSaga(action) {    
    try {
        const response = yield axios.delete(tellerlimitUrl(action.param)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* getPrintPassbookParamSaga01(action) {    
    try {
        const response = yield axios.get(printPassbookUrl(action.accnumber)).then(res => res.data);
        action.successHandler(response,action.param);
    } catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(msgErrorHandler(error));
    }
}

export function* getProductGroupSaga(action) {    
    let url = action.param.url?action.param.url:'';
    try {
        const response = yield axios.get(baseProductGroupURL(url)).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitProductGroupSaga(action) {    
    let url = action.param.url?action.param.url:'';
    let payload = action.param.payload?action.param.payload:'';
    let type = action.param.type?action.param.type:'';
    try {
        if(type == 'POST'){
            const response = yield axios.post(baseProductGroupURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'PUT'){
            const response = yield axios.put(baseProductGroupURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'DELETE'){
            const response = yield axios.delete(baseProductGroupURL(url)).then(res => res.data);
            action.successHandler(response);
        }
        
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitProductGroupDetailsSaga(action) {    
    let url = action.param.url?action.param.url:'';
    let payload = action.param.payload?action.param.payload:'';
    let type = action.param.type?action.param.type:'';
    try {
        if(type == 'POST'){
            const response = yield axios.post(baseProductGroupDetailsURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'PUT'){
            const response = yield axios.put(baseProductGroupDetailsURL(url),payload).then(res => res.data);
            action.successHandler(response);
        }else if(type == 'DELETE'){
            const response = yield axios.delete(baseProductGroupDetailsURL(url)).then(res => res.data);
            action.successHandler(response);
        }
        
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}