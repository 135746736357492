import * as actions from './actionTypes';

export const GetStaffLoanOfficeByOffice = (officeid,successHandler, errorHandler) => {
    return {
        type: actions.GET_STAFF_LOAN_OFFICER,
        officeid:officeid,
        successHandler,
        errorHandler,
    }
};

export const GetLoanProduct = (successHandler, errorHandler) => {
    return {
        type: actions.GET_LOAN_PRODUCT,
        successHandler,
        errorHandler,
    }
};

export const GetLoanDetail = (loanid,successHandler, errorHandler) => {
    return {
        type: actions.GET_LOAN_DETAIL,
        loanid:loanid,
        successHandler,
        errorHandler,
    }
};


export const setListLoan = (data) => {
    return {
        type: actions.FETCH_LOAN_LIST,
        data: data,
    };
};

export const initGetListLoan = (successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_LOAN,
        successCallback,
        errorCallback
    }
};

export const getListLoanSearchQuery = (query,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_LOAN_SEARCH_QUERY,
        query:query,
        successCallback,
        errorCallback
    }
};

export const setListLoanNeedApproval = (data) => {
    return {
        type: actions.FETCH_LOAN_LIST_NEED_APPROVE,
        data: data,
    };
};

export const submitLoanApproveOrReject = (id,command,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_APPROVE_OR_REJECT,
        id:id,
        command:command,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const setListLoanNeedDisbursment = (data) => {
    return {
        type: actions.FETCH_LOAN_LIST_NEED_DISBURSMENT,
        data: data,
    };
};

export const getListLoanProvisioningCategory = (successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_LIST_PROVISIONINGCATEGORY,
        successCallback,
        errorCallback
    }
};


export const getLoansTemplate = (clientid,productloanid,successCallback, errorCallback) => {
    return {
        type: actions.GET_LOANS_TEMPLATE,
        clientid:clientid,
        productloanid:productloanid,
        successCallback,
        errorCallback
    }
};

export const createLoans = (payload,successCallback, errorCallback) => {
    return {
        type: actions.CREATE_LOANS,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const simulationLoansSchedule = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SIMULATION_LOANS_SCHEDULE,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const paymentToLoans = (payload,successCallback, errorCallback) => {
    return {
        type: actions.PAYMENT_TO_LOAN,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getLoanTransTemplateByIdAndCommand = (id,command,successCallback, errorCallback) => {
    return {
        type: actions.GET_LOAN_TRANS_TEMPLATE_BY_ID_AND_COMMAND,
        id:id,
        command:command,
        successCallback,
        errorCallback
    }
};

export const submitLoanTransByIdAndCommand = (id,command,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND,
        id:id,
        command:command,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitLoanDisbursementMultiple = (id,command,payload,loansid,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_DISBURSEMENT_MULTIPLE,
        id:id,
        loansid:loansid,
        command:command,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getAccTransferTemplate = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_ACC_TRANSFER_TEMPLATE,
        param:param,
        successCallback,
        errorCallback
    }
};
export const getLoanChargeTemplate = (id,successCallback, errorCallback) => {
    return {
        type: actions.GET_LOAN_CHARGES_TEMPLATE,
        id:id,
        successCallback,
        errorCallback
    }
};

export const submitTransferOverpaidLoan = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_TRANSFER_OVERPAID_LOAN,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitLoanCharge = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_CHARGES,
        id:id,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getLoansTemplateByQueryParams = (queryparam,successCallback, errorCallback) => {
    return {
        type: actions.GET_LOANS_BY_QUERY_PARAMS,
        queryparam:queryparam,
        successCallback,
        errorCallback
    }
};

export const editChargeInLoan = (loanid,chargeid,payload,successCallback, errorCallback) => {
    return {
        type: actions.EDIT_CHARGES_IN_LOAN,
        loanid:loanid,
        chargeid:chargeid,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const deleteChargeInLoan = (loanid,chargeid,successCallback, errorCallback) => {
    return {
        type: actions.DELETE_CHARGES_IN_LOAN,
        loanid:loanid,
        chargeid:chargeid,
        successCallback,
        errorCallback
    }
};

export const getDetailChargeInLoan = (loanid,chargeid,successCallback, errorCallback) => {
    return {
        type: actions.GET_DETAIL_CHARGES_IN_LOAN,
        loanid:loanid,
        chargeid:chargeid,
        successCallback,
        errorCallback
    }
};

export const submitWaiveChargeInLoan = (loanid,chargeid,command,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_WAIVE_CHARGES_IN_LOAN,
        loanid:loanid,
        chargeid:chargeid,
        command:command,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getDataLoanWithParamDataImport = (paramvalue,param,successCallback, errorCallback) => {
    return {
        type: actions.GET_LOANS_PARAM_DATA_IMPORT,
        param:param,
        paramvalue:paramvalue,
        successCallback,
        errorCallback
    }
};

export const submitLoanTransByIdAndCommandMultiple = (id,command,payload,to,row,extractresult,listdata,listerr,listdone,dispatch,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_MULTIPLE,
        id:id,
        row:row,
        command:command,
        payload:payload,
        listdata:listdata,
        extractresult:extractresult,
        to:to,
        listerr:listerr,
        dispatch:dispatch,
        listdone:listdone,
        successCallback,
        errorCallback
    }
};


export const submitLoanTransByIdAndCommandDataImport = (id,command,payload,extractresult,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_DATA_IMPORT,
        id:id,
        command:command,
        payload:payload,
        extractresult:extractresult,
        successCallback,
        errorCallback
    }
};
export const getLoansParam = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_LOANS_DATA_PARAM,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitEditLoans = (id,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_EDIT_LOANS,
        id:id,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getFindLoanAccNumber = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_FIND_LOAN_ACCNUMBER,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitLoanPassBookCover = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_PASSBOOK_COVER,
        payload:payload,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getLoanPrintPassbook = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_LOAN_PRINT_PASSBOOK,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitLoanPassbookReset = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_PASSBOOK_RESET,
        payload:payload,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitLoanRePrintPassbook = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_LOAN_REPRINT_PASSBOOK,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getLoansPaging = (queryparam,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_LOANS_PAGING,
        actiontype:actiontype,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};
export const getLoanSubmissionPaging = (queryparam,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_SUBMISSION_LOAN,
        actiontype:actiontype,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};
export const getNotesLoan = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_NOTES_LOAN,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitNotesLoan = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_NOTES_LOAN,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getListDocumentLoan = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_DOCUMENT_LOAN,
        param:param,
        successCallback,
        errorCallback
    }
};

export const downloadDocumentLoan = (idloan,iddoc,successCallback, errorCallback) => {
    return {
        type: actions.DOWNLOAD_DOCUMENT_LOAN,
        idloan:idloan,
        iddoc:iddoc,
        successCallback,
        errorCallback
    }
};

export const deleteDocumentLoan = (idloan,iddoc,successCallback, errorCallback) => {
    return {
        type: actions.DELETE_DOCUMENT_LOAN,
        idloan:idloan,
        iddoc:iddoc,
        successCallback,
        errorCallback
    }
};

export const submitAddDocumentLoan = (idloan,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_DOCUMENT_LOAN,
        idloan:idloan,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getProductLoan = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_PRODUCTLOAN_DATA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitAddProductLoan = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_PRODUCTLOAN,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitEditProductLoan = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_EDIT_PRODUCTLOAN,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitInactiveChargeLoan = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_INACTIVE_LOAN_CHARGES,
        payload:payload,
        param:param,
        successCallback,
        errorCallback
    }
};

export const getLoans = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_LOANS,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitLoans = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_LOANS,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getProvosioningCriteria = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_DATA_PROVOSIONING_CRITERIA,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitProvosioningCriteria = (param,payload,actiontype,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_PROVOSIONING_CRITERIA,
        param:param,
        payload:payload,
        actiontype:actiontype,
        successCallback,
        errorCallback
    }
};