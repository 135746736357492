import React, {Component}   from 'react';
import ToggleUserMenu       from "./ToggleUserMenu";
import ToggleLangMenu       from "./ToggleLangMenu";
import ToggleChatBot       from "./ToggleChatBot";
import ToggleDateSystem       from "./ToggleDateSystem";
import ToggleFullScreen     from '../Common/ToggleFullscreen';
import * as actions         from "../../store/actions/actionTypes";
import {connect}            from 'react-redux';
import {bindActionCreators, compose} from "redux";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


class Header extends Component {

    toggleUserblock = e => {
        e.preventDefault();
        console.log(e);
        this.props.actions.toggleSetting('showUserBlock');
    };

    toggleCollapsed = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('isCollapsed');
        this.resize();
    };

    toggleAside = e => {
        // e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    };

    resize = () => {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    };

    render() {
        return (
            <header className="topnavbar-wrapper">
                {/* START Top Navbar */}
                <nav className="navbar topnavbar">
                    {/* START navbar header */}
                    <div className="navbar-header">
                        <div className="navbar-brand">
                            <div className="brand-logo">
                                <Link to='/home'>
                                <img className="img-fluid" src="/img/simpool_full_logo.png" alt="App Logo"/></Link>
                            </div>
                            <div className="brand-logo-collapsed">
                            <Link to='/home'>
                                <img className="img-fluid-box" src="/img/simpool_box.png" alt="App Logo"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* END navbar header */}

                    {/* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <div className="nav-link d-none d-md-block d-lg-block d-xl-block cursor-pointer"
                                 onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"/>
                            </div>
                            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <div className="nav-link sidebar-toggle d-md-none cursor-pointer"
                                 onClick={this.toggleAside}>
                                <em className="fas fa-bars"/>
                            </div>
                        </li>
                    </ul>
                    {/* END Left navbar */}

                    {/* START Right Navbar */}
                    <ul className="navbar-nav flex-row" style={{marginRight: '40px'}}>
                        {/* Fullscreen (only desktops) */}
                        <ToggleDateSystem/>
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullScreen className="nav-link"/>
                        </li>
                        
                        <ToggleChatBot/>
                        <ToggleLangMenu onMenu={true}/>
                        <ToggleUserMenu/>
                        <li className="nav-item d-none d-md-block">
                        </li>
                        
                    </ul>
                </nav>
            </header>
        );
    }
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({settings: state.settings});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps
)(Header));