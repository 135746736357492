import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (data) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        tenant: data.tenant,
        token: data.base64EncodedAuthenticationKey,
        userId: data.userId,
        username: data.username,
        permissions: data.permissions,
        officeId: data.officeId,
        roles: data.roles?.reduce((obj, el) => [...obj, {id: el.id, name: el.name}],[]),
        shouldRenewPassword:data.shouldRenewPassword?true:false,
    };
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_INITIATE_LOGOUT
    };
};


export const logoutSucceed = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const logoutWithErrorSuccess = (errMessage) => {
    return {
        type: actionTypes.AUTH_LOGOUT_WITHERROR_SUCCESS,
        error: errMessage
    }
};


export const logoutWithError = (errMessage) => {
    return {
        type: actionTypes.AUTH_LOGOUT_WITHERROR,
        error: errMessage
    }
};

export const auth = (username, password, tenant, successHandler, errorHandler) => {
    return {
        type: actionTypes.AUTH_USER,
        username: username,
        password: password,
        tenant: tenant,
        successHandler,
        errorHandler
    };
};

export const authWithOtp = (username, password, tenant, ref, otpCode, rememberMe,errorHandler) => {
    return {
        type: actionTypes.AUTH_USEROTP,
        username: username,
        password: password,
        tenant: tenant,
        ref: ref,
        otpCode: otpCode,
        rememberMe: rememberMe,
        errorHandler
    };
};

export const otp = (username, password, tenant) => {
    return {
        type: actionTypes.AUTH_OTP,
        username: username,
        password: password,
        tenant: tenant,
        
    };
};

export const otpStart = () => {
    return {
        type: actionTypes.AUTH_OTP_START
    };
};

export const otpError = (error) => {
    return {
        type: actionTypes.AUTH_OTP_FAIL,
        error: error,
    };
};

export const otpSuccess = (ref,expired) => {
    return {
        type: actionTypes.AUTH_OTP_SUCCESS,
        ref: ref,
        date:new Date(),
        expired: expired
    };
};

export const authCheckState = () => {
    return {
        type: actionTypes.AUTH_CHECK_STATE
    };
};

export const retrieveUser = (data) => {
    return {
        type: actionTypes.AUTH_USER_RETRIEVE,
        tenant: data.tenant,
        token: data.base64EncodedAuthenticationKey,
        userId: data.userId,
        username: data.username,
        permissions: data.permissions,
        officeId: data.officeId,
        roles: data.roles?.reduce((obj, el) => [...obj, {id: el.id, name: el.name}],[]),
    };
};


export const createChangePasswordUserWebOTP = (successCallback, errorCallback) => {
    return {
        type: actionTypes.CREATE_CHANGE_PASSWORD_USER_WEB_OTP,
        successCallback,
        errorCallback
    }
};

export const confirmChangePasswordUserWebOTP = (payload,successCallback, errorCallback) => {
    return {
        type: actionTypes.CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getPasswordValidation = (successCallback, errorCallback) => {
    return {
        type: actionTypes.GET_PASSWORD_VALIDATION,
        successCallback,
        errorCallback
    }
};

export const getUserProfile = (successCallback, errorCallback) => {
    return {
        type: actionTypes.GET_USER_PROFILE,
        successCallback,
        errorCallback
    }
};

export const changePasswordUserWebOTPRedux = (tenant) => {
    return {
        type: actionTypes.CREATE_CHANGE_PASSWORD_USER_WEB_OTP_REDUX,
        tenant: tenant,
        
    };
};

export const confirmChangePasswordUserWebOTPRedux = (username, password,repeatpassword, tenant,reference,otpcode,errorHandler) => {
    return {
        type: actionTypes.CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP_REDUX,
        username: username,
        password: password,
        repeatpassword:repeatpassword,
        tenant: tenant,
        reference:reference,
        otpcode:otpcode,
        errorHandler
    };
};