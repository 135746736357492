import * as actions from './actionTypes';

export const getListPromotionManagement = (successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_PROMOTION_MANAGEMENT,
        successHandler,
        errorHandler,
    }
};
export const getListDepositBillerService = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_DEPOSIT_BILLER_SERVICE,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDetailPromotionManagement = (id,successHandler, errorHandler) => {
    return {
        type: actions.GET_DETAIL_PROMOTION_MANAGEMENT,
        id:id,
        successHandler,
        errorHandler,
    }
};
export const submitAddDepositBillerService = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_DEPOSIT_BILLER_SERVICE,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitaddPromotionManagement = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_PROMOTION_MANAGEMENT,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const submitEditDepositBillerService = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_DEPOSIT_BILLER_SERVICE,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submiteditPromotionManagement = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_PROMOTION_MANAGEMENT,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const submitDeleteDepositBillerService = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_DEPOSIT_BILLER_SERVICE,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitdeletePromotionManagement = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_PROMOTION_MANAGEMENT,
        id:id,
        successHandler,
        errorHandler,
    }
};
export const getListDepositBillerMitra = (successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_DEPOSIT_BILLER_MITRA,
        successHandler,
        errorHandler,
    }
};

export const getDepositBillerMitraByTenant = (tenantid,successHandler, errorHandler) => {
    return {
        type: actions.GET_DEPOSIT_BILLER_MITRA_BY_TENANT,
        tenantid:tenantid,
        successHandler,
        errorHandler,
    }
};

export const getTemplateDepositBillerMitraByTenant = (tenantid,successHandler, errorHandler) => {
    return {
        type: actions.GET_TEMPLATE_DEPOSIT_BILLER_MITRA_BY_TENANT,
        tenantid:tenantid,
        successHandler,
        errorHandler,
    }
};

export const getDataAgentGroup = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_AGENT_GROUP,
        param:param,
        successHandler,
        errorHandler,
    }
};
export const getDataOffices = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_OFFICES,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddComissionAgent = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_COMISSION_AGENT,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const submitAddOffice = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_OFFICES,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteComissionAgent = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_COMISSION_AGENT,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitAddAgentGroup = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_AGENT_GROUP,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditAgentGroup = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_AGENT_GROUP,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteAgentGroup = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_AGENT_GROUP,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitEditComissionAgent = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_COMISSION_AGENT,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataAgent = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_AGENT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddSpecialComissionAgent = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_SPECIAL_COMISSION_AGENT,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const submitEditOffice = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_OFFICES,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditSpecialComissionAgent = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_SPECIAL_COMISSION_AGENT,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteSpecialComissionAgent = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_SPECIAL_COMISSION_AGENT,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitAddAgent = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_AGENT,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditAgent = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_AGENT,
        payload:payload,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteAgent = (id,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_AGENT,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const getDataLoanCollectionSheet = (successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_LOAN_COLLECTION_SHEET,
        successHandler,
        errorHandler,
    }
};

export const getListDepositBillerSettlement = (tenantid,successHandler, errorHandler) => {
    return {
        type: actions.GET_LIST_DEPOSITBILLER_SETTLEMENT,
        tenantid:tenantid,
        successHandler,
        errorHandler,
    }
};

export const getDataOfficeApproval = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_OFFICEAPPROVAL_DATA,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddOfficeApproval = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_OFFICEAPPROVAL,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteOfficeApproval = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_OFFICEAPPROVAL,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditOfficeApproval = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_OFFICEAPPROVAL,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataTransactionLimit = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_TRANSACTION_LIMIT,
        param:param,
        successHandler,
        errorHandler,
    }
};
export const getDataEmployementLevel = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_EMPLOYMENT_LEVEL,
        param:param,
        successHandler,
        errorHandler,
    }
};


export const submitAddTransactionLimit = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_TRANSACTION_LIMIT,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};
export const submitAddEmployementLevel = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_EMPLOYMENT_LEVEL,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditEmployementLevel = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_EMPLOYMENT_LEVEL,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteEmployementLevel = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_EMPLOYMENT_LEVEL,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditTransactionLimit = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_TRANSACTION_LIMIT,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteTransactionLimit = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_TRANSACTION_LIMIT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataOrganisationPosition = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_ORGANISATION_POSITION,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataOfficeApprovalLoan = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_OFFICEAPPROVAL_LOAN,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitOrganisationPosition = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ORGANISATION_POSITION,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitOfficeApprovalLoan = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_OFFICEAPPROVAL_LOAN,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataOrganisationStructure = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_ORGANISATION_STRUCTURE,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataUserGroup = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_USER_GROUP,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitOrganisationStructure = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ORGANISATION_STRUCTURE,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitUserGroup = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_USER_GROUP,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataCollectionSheetPagingParam = (queryparam,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_COLLECTION_SHEET_PAGING,
        actiontype:actiontype,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const getDataCollectionSheetParam = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_COLLECTION_SHEET,
        param:param,
        successHandler,
        errorHandler,
    }
};