export const msgErrorHandler = (data)  =>{
    let flag = data.data?.errors?true:false;
    let lang = localStorage.getItem('simpool-lng');
        let teks = '';
        if(flag){
            let arrErrors = data.data.errors;
            if(arrErrors.length > 0){
                if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.length.does.not.match'){
                    let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                    let max = arrErrors[0]?.args[1]?arrErrors[0].args[1].value:'?';
                    if(lang === 'en'){
                        teks = 'Password Length, Minimum '+min+ ', Maximum '+max;
                    }else{
                        teks = 'Panjang Password Minimal '+min+ ', Maksimal '+max;
                    }
                }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.numeric.does.not.match'){
                    let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                    if(lang === 'en'){
                        teks = 'The Password Must Contain a Minimum '+min+ ' numbers';
                    }else{
                        teks = 'Password Harus Mengandung Minimal '+min+ ' Angka';
                    }
                    
                }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.lower.letter.does.not.match'){
                    let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                    if(lang === 'en'){
                        teks = 'The Password Must Contain a Minimum '+min+ ' Lowercase';
                    }else{
                        teks = 'Password Harus Mengandung Minimal '+min+ ' Huruf Kecil';
                    }
                    
                }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.upper.letter.does.not.match'){
                    let min = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                    if(lang === 'en'){
                        teks = 'The Password Must Contain a Minimum '+min+ ' Uppercase';
                    }else{
                        teks = 'Password Harus Mengandung Minimal '+min+ ' Huruf Besar';
                    }
                    
                }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.password.special.characters.does.not.match'){
                    let spesial = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                    if(lang === 'en'){
                        teks = 'Password Must Contain Special Character '+spesial;
                    }else{
                        teks = 'Password Harus Mengandung Karater spesial '+spesial;
                    }
                    
                }else if(arrErrors[0].userMessageGlobalisationCode === 'error.msg.otp.code.not.valid'){
                    let kode = arrErrors[0]?.args[0]?arrErrors[0].args[0].value:'?';
                    if(lang === 'en'){
                        teks = 'Invalid OTP Code '+kode;
                    }else{
                        teks = 'Kode OTP '+kode+' Tidak Valid';
                    }
                    
                }else if(listMsgError(arrErrors[0].userMessageGlobalisationCode,lang) !== ''){
                    teks = listMsgError(arrErrors[0].userMessageGlobalisationCode,lang);
                }
                
                else{
                    teks = arrErrors[0].defaultUserMessage;
                }
            }
        }else if(data.data.defaultUserMessage){
            if(listMsgError(data.data.userMessageGlobalisationCode,lang) !== ''){
                teks = listMsgError(data.data.userMessageGlobalisationCode,lang);
            }else{
                teks = data.data.defaultUserMessage;
            }
        }else{
            teks = data;
        }
        return teks;
}

export const listMsgError = (code,lang)  =>{
    let teks = '';
    if(code === 'error.msg.password.already.used'){
        if(lang === 'en'){
            teks = 'Password Has Been Used';
        }else{
            teks = 'Password Sudah Pernah Digunakan';
        }
        
    }else if(code === 'error.msg.password.already.used'){
        if(lang === 'en'){
            teks = 'Repetition of Entering the Password Does not Match the Password';
        }else{
            teks = 'Pengulangan Memasukan Kata Sandi Tidak Sesuai Dengan Kata Sandi';
        }
    }else if(code === 'error.msg.client.close.loan.non-closed'){
        if(lang === 'en'){
            teks = 'Member cannot be closed because of non-closed financing';
        }else{
            teks = 'Anggota tidak dapat ditutup karena pembiayaan belum ditutup';
        }
    }else if(code === 'error.msg.client.close.non-closed.savings.account'){
        if(lang === 'en'){
            teks = 'Member cannot be closed because of non-closed savings account';
        }else{
            teks = 'Anggota tidak dapat ditutup karena simpanan belum ditutup';
        }
    }else if(code === 'error.msg.passbookNumber.data.duplicate'){
            teks = 'Account Number Has Been Registered, Please Reset Data To Print Again';
    }else if(code === 'error.msg.tellerlimit.cannot.allocation'){
        if(lang === 'en'){
            teks = 'Cannot doing allocation before teller status Pre-Open';
        }else{
            teks = 'Gagal melakukan allocation sebelum status teller Pre-Open';
        }
    }else if(code === 'error.msg.tellerlimit.settlement.already.done'){
        if(lang === 'en'){
            teks = 'You`ve done Settlement, please do Allocate first';
        }else{
            teks = 'Sudah melakukan settlement, Mohon lakukan Allocate dahulu';
        }
    }else if(code == 'error.msg.tellerstatus.not.closed'){
        if(lang === 'en'){
            teks = 'Teller must be closed first';
        }else{
            teks = 'Status Teller harus Close';
        }
    }

    return teks;
}