import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
    token: null,
    userId: null,
    officeId: null,
    error: null,
    loading: false,
    permissions: [],
    roles:[],
    username:'',
    ref: null,
    expired:'',
    shouldRenewPassword:null
};

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
};

const authSuccess = (state, action) => {
    let isNewPass = action.shouldRenewPassword !== null && action.shouldRenewPassword !== undefined?action.shouldRenewPassword:null;
    // console.log('shouldRenewPassword ',action.shouldRenewPassword)
    return updateObject( state, {
        token: action.token,
        userId: action.userId,
        officeId: action.officeId,
        error: null,
        loading: false,
        permissions: action.permissions,
        roles: action.roles,
        username: action.username,
        expired:action.expired,
        shouldRenewPassword:isNewPass,
    });
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null,
        officeId: null,
        error: null,
        loading: false,
        permissions: [],
        roles:[],
        username:'',
        ref: null,
        expired:null,
        shouldRenewPassword:null,

    });
};

const authLogoutWithErrorSuccess = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null,
        officeId: null,
        error: action.error,
        loading: false,
        permissions: [],
        roles:[],
        username:'',
        ref: null,
        expired:null,
        shouldRenewPassword:null,
    });
};

const otpFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const otpSuccess = (state, action) => {
    return updateObject(state, {
        ref: action.ref,
        expired:action.expired,
        date:action.date,
        error: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.AUTH_OTP_FAIL: return otpFail(state, action);
        case actionTypes.AUTH_OTP_SUCCESS: return otpSuccess(state, action);
        case actionTypes.AUTH_LOGOUT_WITHERROR_SUCCESS: return authLogoutWithErrorSuccess(state, action);
        case actionTypes.AUTH_USER_RETRIEVE: return authSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;