import * as actions from './actionTypes';

export const getListUserByParamURL  = (queryparam,successHandler,errorHandler) => {
    return {
        type: actions.GET_LIST_INTERNAL_USER_BY_PARAM,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const getUserTemplate  = (successHandler,errorHandler) => {
    return {
        type: actions.GET_USER_TEMPLATE,
        successHandler,
        errorHandler,
    }
};

export const submitInternalUsers  = (payload,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_INTERNAL_USER,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDetailInternalUsers  = (userid,queryparam,successHandler,errorHandler) => {
    return {
        type: actions.GET_DETAIL_INTERNAL_USER,
        queryparam:queryparam,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const updateInternalUsers  = (userid,payload,successHandler,errorHandler) => {
    return {
        type: actions.UPDATE_INTERNAL_USER,
        payload:payload,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const deleteInternalUsers  = (userid,successHandler,errorHandler) => {
    return {
        type: actions.DELETE_INTERNAL_USER,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const getOtpChangePasswordInternalUsers  = (userid,successHandler,errorHandler) => {
    return {
        type: actions.GET_OTP_CHANGEPASSWORD_INTERNAL_USER,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const submitChangePasswordInternalUsers  = (userid,payload,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_CHANGEPASSWORD_INTERNAL_USER,
        payload:payload,
        userid:userid,
        successHandler,
        errorHandler,
    }
};

export const getOtpUnlockInternalUsers  = (userid,payload,successHandler,errorHandler) => {
    return {
        type: actions.GET_OTP_UNLOCK_INTERNAL_USER,
        userid:userid,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitUnlockInternalUsers  = (userid,payload,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_UNLOCK_INTERNAL_USER,
        userid:userid,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataUnitUser  = (param,successHandler,errorHandler) => {
    return {
        type: actions.GET_DATA_UNIT_USER,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddUnitUser  = (payload,memberid,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_UNIT_USER,
        payload:payload,
        memberid:memberid,
        successHandler,
        errorHandler,
    }
};

export const submitEditUnitUser  = (id,payload,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_UNIT_USER,
        id:id,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteUnitUser  = (id,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_UNIT_USER,
        id:id,
        successHandler,
        errorHandler,
    }
};

export const submitLockInternalUser  = (id,payload,typelock,successHandler,errorHandler) => {
    return {
        type: actions.SUBMIT_LOCK_INTERNAL_USER,
        id:id,
        payload:payload,
        typelock:typelock,
        successHandler,
        errorHandler,
    }
};

export const getInternalUserListPaging = (queryparam,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_INTERNAL_USER_PAGING,
        actiontype:actiontype,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};