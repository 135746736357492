import React, {useState, useEffect} from 'react';
import Paper                        from '@material-ui/core/Paper';
import {
    PagingState,
    SortingState,
    IntegratedSorting,
    IntegratedPaging,
    IntegratedFiltering,
    EditingState,
    TableColumnVisibility
}                                   from '@devexpress/dx-react-grid';
import {
    Grid,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
    PagingPanel,
    VirtualTable,
    Table
}                                   from '@devexpress/dx-react-grid-material-ui';
import {Loading}                    from '../../components/Common/Loading';
import {useTranslation}             from 'react-i18next';
import {useHistory}                 from 'react-router-dom';
import Tooltip                      from '@material-ui/core/Tooltip';
import IconButton                   from '@material-ui/core/IconButton';
import AddBox from '@material-ui/icons/AddBox';
import {useDispatch}   from 'react-redux';


const AddButton = ({onExecute}) => {
    const history = useHistory();
    const i18n = useTranslation('translations');
    return (
        <div style={{textAlign: 'center'}} title={i18n.t('grid.ADD')}>
            <Tooltip title={i18n.t('grid.ADD')}>
                <IconButton color={'primary'} 
                onClick={() => history.push('/member/add')}
                >
                    <AddBox/>
                </IconButton>
            </Tooltip>
            
        </div>
    );
};

const CellComponent = ({children, row, ...restProps}) => {
    const {i18n} = useTranslation('translations');
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <TableEditColumn.Cell row={row} {...restProps}>
            {children}
            {/* <Tooltip title={i18n.t('tooltip.VIEWUSER')}>
                <IconButton color={'primary'} ///MobileUser/detail
                    // onClick={() => history.push('/MobileUser/detail/' + row.id)}
                    onClick={() => {history.push('/member/financing-data-detail/' + row.id)}}
                    hidden={isGetPermissions(financingDetail,'READ')}
                >
                    <IconView/>
                </IconButton>
            </Tooltip> */}
        </TableEditColumn.Cell>
    );
};

const commandComponents = {
    add: AddButton,
};

const Command = ({id, onExecute}) => {
    const CommandButton = commandComponents[id];
    return (
        <CommandButton
            onExecute={onExecute}
        />
    );
};

const ReportsGrid = props => {
    const {i18n} = useTranslation();
    const [hiddenColumnNames] = useState(['id']);
    //#REQ-2# Menghilangkan Paging pada tampilan CSV report
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [sorting, setSorting] = useState([]);
    const [pageSizes] = useState([]);
    const [loading, setLoading] = useState(props.loading);
    const [integratedSortingColumnExtensions] = useState([]);
    
    const pagingPanelMessages = {
        showAll: i18n.t('grid.ALL'),
        rowsPerPage: i18n.t('grid.ROWS_PER_PAGE'),
        info: i18n.t('grid.INFO'),
    };
    
      const [filteringColumnExtensions] = useState([]);

        useEffect(() => {
            if (props.loading !== loading) {
                setLoading(props.loading);
            }
        }, [props.loading]);
    
        function noDataCell(props) {
            return loading
                ? <td className={'grid-nodata'} colSpan={7}>
                    <div className={'grid-nodata--text-container'}>
                        <div style={{fontSize: 'larger'}}>
                            Loading...
                        </div>
                    </div>
                </td>
                : <VirtualTable.NoDataCell {...props} />;
        }
        const rowComponent = ({ tableRow, ...restProps }) => {
            return <Table.Row {...restProps} style={{ backgroundColor: "lightgray" }} />;
          };

        return (
            <Paper style={{position: 'relative'}}>
                <Grid
                rows={props.rows}
                columns={props.columns}
                
            >
                
                <EditingState onCommitChanges={() => {
                }}/>
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                {/* <FilteringState defaultFilters={[]}/> */}
                <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                    columnExtensions={props.sortingStateColumnExtensions}
                />
                <IntegratedSorting
                    columnExtensions={integratedSortingColumnExtensions}
                />
                <IntegratedPaging/>
                <VirtualTable
                    noDataCellComponent={noDataCell}
                    columnExtensions={props.tableColumnExtensions}
                />
                <TableHeaderRow showSortingControls rowComponent={rowComponent}/>
                <TableColumnVisibility
                    hiddenColumnNames={hiddenColumnNames}
                />
                {/* <TableFilterRow
                    showFilterSelector
                    cellComponent={FilterCell}
                    iconComponent={FilterIcon}
                    messages={filterRowMessages}
                /> */}
                <TableEditRow/>
                <TableEditColumn
                    // showAddCommand
                    // showEditCommand
                    // showDeleteCommand
                    cellComponent={CellComponent}
                    commandComponent={Command}
                    width={10}
                    // messages={editColumnMessages}
                />
                <PagingPanel
                    pageSizes={pageSizes}
                    messages={pagingPanelMessages}
                />
            </Grid>
                    {props.loading && <Loading/>}
            </Paper>
        );
};
export default ReportsGrid;