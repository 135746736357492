import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

export const Loading = (props) => {
    return (
        <div className={"loading-shading-mui " + props.extClass}>
            <CircularProgress className="loading-icon-mui"/>
        </div>
    );
};

Loading.defaultProps = {
    extClass: ''
};