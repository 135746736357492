import React, {useState, useRef, useEffect} from 'react';

import {useTranslation}             from 'react-i18next';
import Button                     from '@material-ui/core/Button';
import ClickAwayListener          from '@material-ui/core/ClickAwayListener';
import Grow                       from '@material-ui/core/Grow';
import Paper                      from '@material-ui/core/Paper';
import Popper                     from '@material-ui/core/Popper';
import MenuItem                   from '@material-ui/core/MenuItem';
import MenuList                   from '@material-ui/core/MenuList';
import {makeStyles}               from '@material-ui/core/styles';
import FaceIcon                   from '@material-ui/icons/Face';
import KeyIcon                    from '@material-ui/icons/VpnKey';
import LogoutIcon                 from '@material-ui/icons/MeetingRoom';
import {useSelector, useDispatch} from 'react-redux';
import { withRouter  } from 'react-router-dom';
import Dialog                       from '@material-ui/core/Dialog';
import styled                       from "styled-components";
import MuiDialogTitle               from '@material-ui/core/DialogTitle';
import {withStyles}                 from '@material-ui/core/styles';
import Typography                   from '@material-ui/core/Typography';
import CloseIcon                    from '@material-ui/icons/Close';
import IconButton                   from '@material-ui/core/IconButton';
import ChangePassworddDialog from './ChangePasswordDialog';
// import * as actions                 from '../../store/actions';
import {Loading}                    from '../../components/Common/Loading';
import Swal             from "sweetalert2";
import {useHistory}                 from 'react-router-dom';
import { isGetPortalMenuPermission, msgErrorHandler } from '../../shared/maskFunc';
import UserProfilDialog from './UserProfileDialog';
// import {isGetPermissions} from '../../shared/maskFunc';
import { Portal_Header } from '../../shared/PermissionForFeatures';
const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    height: 500px;
  }
`;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    button: {
        color: 'white',
        textTransform: 'none',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    svgIcon: {
        marginRight: '10px',
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
  });

function MenuListComposition(props) {
    const classes = useStyles();
    const history = useHistory();
    const i18n = useTranslation('translations');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [changepassword, setShowChangePassword] = useState(false);
    const [showuserprofile, setShowUserProfile] = useState(false);
    const anchorRef = useRef(null);
    const dispatch = useDispatch();
    const username = useSelector(state => state.auth.username);
    const [referrence, setReferrence] = useState('');
    const [expmilis, setExpMilis] = useState(0);
    

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleShowUserProfile = (event) => {
        // console.log('handleShowUserProfile ',getuserid());
        setShowUserProfile(true);
    }
    const handleChangePassword = (event) => {
        setLoading(true);
        setShowChangePassword(true);
        // dispatch(actions.createChangePasswordUserWebOTP(successHandler,errorHandler));
        
    };
    // const HandlePasswordvalidation = (data) => {
        
    // }
    // const successHandler = (data) => {
    //     console.log('successHandler ',data);
    //     setReferrence(data.reference);
    //     setExpMilis(data.expiredMillis);
    //     // setLoading(false);
    //     // dispatch(actions.getPasswordValidation(HandlePasswordvalidation, errorHandler));
    // }

    const errorHandler = (data) => {
        let teks = msgErrorHandler(data);
        setLoading(false);
        setShowChangePassword(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: teks
        })
      }
    const successHandlerConfirmPW = (data) =>{
        setShowChangePassword(false);
        let teks = i18n.t('label_SUCCESS');
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: teks
        }).then(res => {            
            // window.location.reload(true);
            history.go('/logout');
        });
        setLoading(false);
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function handleLogout() {
        props.history.push('/logout');
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
    }, []);

    return (
        <div className={classes.root}>
           
            <Button
                className={classes.button}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                Hi, {username}
            </Button>
            
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {/* <MenuItem onClick={handleShowUserProfile} hidden={isGetPermissions(viewUserProfile,'TRANSACTION')} ><FaceIcon
                                        className={classes.svgIcon}/>{i18n.t('label_PROFILE')}</MenuItem>
                                    <MenuItem onClick={handleChangePassword} hidden={isGetPermissions(changePasswordUser,'TRANSACTION')}><KeyIcon className={classes.svgIcon}/>
                                    {i18n.t('label_CHANGE_PASSWORD')}</MenuItem> */}
                                    <MenuItem onClick={handleShowUserProfile} hidden={isGetPortalMenuPermission(Portal_Header,[])} ><FaceIcon
                                        className={classes.svgIcon}/>{i18n.t('label_PROFILE')}</MenuItem>
                                    <MenuItem onClick={handleChangePassword} hidden={isGetPortalMenuPermission(Portal_Header,[])}><KeyIcon className={classes.svgIcon}/>
                                    {i18n.t('label_CHANGE_PASSWORD')}</MenuItem>
                                    <MenuItem onClick={handleLogout}><LogoutIcon
                                        className={classes.svgIcon}/>{i18n.t('label_LOGOUT')}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        
                    </Grow>
                )}
                
            </Popper>
            
            <StyledDialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="lg"
                fullWidth={true}
                style={{height: '80vh'}}
                open={showuserprofile}
                >
                    <UserProfilDialog
                        errorHandler = {errorHandler}
                        setloading = {setLoading}
                        showflag = {setShowUserProfile}
                    />
                    {loading && <Loading/>}
                </StyledDialog>
            <StyledDialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="sm"
                fullWidth={true}
                style={{height: '80vh'}}
                open={changepassword}
                >
                <ChangePassworddDialog
                    showflag = {setShowChangePassword}
                    reference = {referrence}
                    errorHandler = {errorHandler}
                    successHandlerConfirmPW = {successHandlerConfirmPW}
                    expmilis = {expmilis}
                    setloading = {setLoading}
                />
                {loading && <Loading/>}
            </StyledDialog>
            
        </div>
        
        
        
    );
}

export default withRouter(MenuListComposition);