import * as actions from './actionTypes';

export const initFetchDashboardGender = (dateFrom, dateTo, successCallback, errorCallback) => {
    return {
        type : actions.INIT_FETCH_DASHBOARD_GENDER,
        dateFrom,
        dateTo,
        successCallback,
        errorCallback,
    }
};

export const fetchDashboardGenderStart = () => {
    return {
        type: actions.FETCH_DASHBOARD_GENDER_START
    }
};

export const fetchDashboardGenderFailed = (error) => {
    return {
        type: actions.FETCH_DASHBOARD_GENDER_FAILED,
        error
    }
};

export const fetchDashboardGenderSuccess = () => {
    return {
        type: actions.FETCH_DASHBOARD_GENDER_SUCCESS
    }
};

export const initFetchDashboardAge = (dateFrom, dateTo, successCallback, errorCallback) => {
    return {
        type : actions.INIT_FETCH_DASHBOARD_AGE,
        dateFrom,
        dateTo,
        successCallback,
        errorCallback,
    }
};

export const fetchDashboardAgeStart = () => {
    return {
        type: actions.FETCH_DASHBOARD_AGE_START
    }
};

export const fetchDashboardAgeFailed = (error) => {
    return {
        type: actions.FETCH_DASHBOARD_AGE_FAILED,
        error
    }
};

export const fetchDashboardAgeSuccess = () => {
    return {
        type: actions.FETCH_DASHBOARD_AGE_SUCCESS
    }
};


export const initFetchDashboardProvince = (dateFrom, dateTo, successCallback, errorCallback) => {
    return {
        type : actions.INIT_FETCH_DASHBOARD_PROVINCE,
        dateFrom,
        dateTo,
        successCallback,
        errorCallback,
    }
};

export const fetchDashboardProvinceStart = () => {
    return {
        type: actions.FETCH_DASHBOARD_PROVINCE_START
    }
};

export const fetchDashboardProvinceFailed = (error) => {
    return {
        type: actions.FETCH_DASHBOARD_PROVINCE_FAILED,
        error
    }
};

export const fetchDashboardProvinceSuccess = () => {
    return {
        type: actions.FETCH_DASHBOARD_PROVINCE_SUCCESS
    }
};


export const initFetchDashboardClientMonth = (dateFrom, dateTo, successCallback, errorCallback) => {
    return {
        type : actions.INIT_FETCH_DASHBOARD_CLIENTMONTH,
        dateFrom,
        dateTo,
        successCallback,
        errorCallback,
    }
};

export const fetchDashboardClientMonthStart = () => {
    return {
        type: actions.FETCH_DASHBOARD_CLIENTMONTH_START
    }
};

export const fetchDashboardClientMonthFailed = (error) => {
    return {
        type: actions.FETCH_DASHBOARD_CLIENTMONTH_FAILED,
        error
    }
};

export const fetchDashboardClientMonthSuccess = () => {
    return {
        type: actions.FETCH_DASHBOARD_CLIENTMONTH_SUCCESS
    }
};


export const initFetchDashboardLoan = (dateFrom, dateTo, successCallback, errorCallback) => {
    return {
        type : actions.INIT_FETCH_DASHBOARD_LOAN,
        dateFrom,
        dateTo,
        successCallback,
        errorCallback,
    }
};

export const fetchDashboardLoanrStart = () => {
    return {
        type: actions.FETCH_DASHBOARD_LOAN_START
    }
};

export const fetchDashboardLoanFailed = (error) => {
    return {
        type: actions.FETCH_DASHBOARD_LOAN_FAILED,
        error
    }
};

export const fetchDashboardLoanSuccess = () => {
    return {
        type: actions.FETCH_DASHBOARD_LOAN_SUCCESS
    }
};

export const initFetchDashboardTotalApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_TOTAL_APEX,
        successCallback,
        errorCallback,
    }
};

export const initFetchDashboardMemberApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_MEMBER_APEX,
        successCallback,
        errorCallback,
    }
};

export const initFetchDashboardCityApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_CITY_APEX,
        successCallback,
        errorCallback,
    }
};

export const initFetchDashboardClientTenantApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_CLIENTTENANT_APEX,
        successCallback,
        errorCallback,
    }
};

export const initFetchDashboardGeoCityTenantApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_GEOCITY_APEX,
        successCallback,
        errorCallback,
    }
};

export const initFetchDashboardSyncTenantApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_SYNC,
        successCallback,
        errorCallback,
    }
};

export const initFetchDashboardActiveMobileUserApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_ACTIVE_MOBILE_USER,
        successCallback,
        errorCallback,
    }
};

export const getDashboardAgeApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_AGE_APEX,
        successCallback,
        errorCallback,
    }
};

export const getDashboardGenderApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_GENDER_APEX,
        successCallback,
        errorCallback,
    }
};

export const getDashboardLoanApex = (successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_LOAN_APEX,
        successCallback,
        errorCallback,
    }
};

export const getDashboardLoanDisburseApex = (param,successCallback, errorCallback) => {
    return {
        type : actions.GET_DASHBOARD_LOAN_DISBURSE_APEX,
        param:param,
        successCallback,
        errorCallback,
    }
};

export const getDataDashboardToken = (param,successCallback, errorCallback) => {
    return {
        type : actions.GET_DATA_DASHBOARD_TOKEN,
        param:param,
        successCallback,
        errorCallback,
    }
};