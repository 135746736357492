import * as actions from './actionTypes';

export const initGetMemberEdit = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_MEMBER_EDIT,
        id: id,
        successCallback,
        errorCallback,
    }
};


export const getMemberEditStart = () => {
    return {
        type: actions.GET_MEMBER_EDIT_START
    };
};

export const getMemberEditSuccess = () => {
    return {
        type: actions.GET_MEMBER_EDIT_SUCCESS
    };
};

export const getMemberEditFailed = () => {
    return {
        type: actions.GET_MEMBER_EDIT_FAILED
    };
};

export const initMemberEdit = (id, payload,successCallback, errorCallback) => {
    return {
        type: actions.INIT_MEMBER_EDIT,
        id: id,
        payload: payload,
        successCallback,
        errorCallback,
    }
};


export const memberEditStart = () => {
    return {
        type: actions.MEMBER_EDIT_START
    };
};

export const memberEditSuccess = () => {
    return {
        type: actions.MEMBER_EDIT_SUCCESS
    };
};

export const memberEditFailed = () => {
    return {
        type: actions.MEMBER_EDIT_FAILED
    };
};