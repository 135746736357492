import * as actions from './actionTypes';

export const initDepositAccountTransaction = (clientId, id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_DEPOSIT_ACC_TX,
        clientId: clientId,
        id: id,
        successCallback,
        errorCallback
    }
};

export const depositAccountTransactionStart = () => {
    return {
        type: actions.GET_DEPOSIT_ACC_TX_START
    }
};
export const depositAccountTransactionFailed = () => {
    return {
        type: actions.GET_DEPOSIT_ACC_TX_FAILED
    }
};
export const depositAccountTransactionSuccess = () => {
    return {
        type: actions.GET_DEPOSIT_ACC_TX_SUCCESS
    }
};

export const initDepositAccountTransfer = (id, successCallback, errorCallback) => {
    return {
        type: actions.INIT_GET_DEPOSIT_ACC_TRANSFER,
        id: id,
        successCallback,
        errorCallback
    }
};

export const depositAccountTransferStart = () => {
    return {
        type: actions.GET_DEPOSIT_ACC_TRANSFER_START
    }
};
export const depositAccountTransferFailed = () => {
    return {
        type: actions.GET_DEPOSIT_ACC_TRANSFER_FAILED
    }
};
export const depositAccountTransferSuccess = () => {
    return {
        type: actions.GET_DEPOSIT_ACC_TRANSFER_SUCCESS
    }
};

export const getDepositTemplate = (clientid,productid,successCallback, errorCallback) => {
    return {
        type: actions.GET_DEPOSIT_TEMPLATE,
        productid:productid,
        clientid:clientid,
        successCallback,
        errorCallback
    }
};

export const getDepositChart = (productid,successCallback, errorCallback) => {
    return {
        type: actions.GET_DEPOSIT_CHART,
        productid:productid,
        successCallback,
        errorCallback
    }
};

export const depositCreate = (payload,successCallback, errorCallback) => {
    return {
        type: actions.DEPOSIT_CREATE,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const getListDepositBiller = (param,successCallback, errorCallback) => {
    return {
        type: actions.GET_LIST_DEPOSIT_BILLER,
        param:param,
        successCallback,
        errorCallback
    }
};

export const submitaddDepositBiller = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_DEPOSIT_BILLER,
        payload:payload,
        successCallback,
        errorCallback
    }
};

export const submitCancelDepositBiller = (payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_CANCEL_DEPOSIT_BILLER,
        payload:payload,
        successCallback,
        errorCallback
    }
};


export const submitAddListDepositBillerPhoto = (param,payload,successCallback, errorCallback) => {
    return {
        type: actions.SUBMIT_ADD_LIST_DEPOSIT_BILLER_PHOTO,
        param:param,
        payload:payload,
        successCallback,
        errorCallback
    }
};