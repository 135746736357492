import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';
import 'react-datetime/css/react-datetime.css';
// import Hello from './hello';
import {ConnectedRouter} from 'connected-react-router';
import {history} from './store/history';
class App extends Component {
  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    //const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (

        <ConnectedRouter history={history} basename={'/'}>
            <Routes/>
        </ConnectedRouter>
    );

  }
}

export default App;
