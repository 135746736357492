import * as actions from './actionTypes';

export const getDataExternalServiceSMTP = (successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_EXTERNAL_SERVICE_SMTP,
        successHandler,
        errorHandler,
    }
};

export const editExternalServiceSMTP = (payload,successHandler, errorHandler) => {
    return {
        type: actions.EDIT_EXTERNAL_SERVICE_SMTP,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getDataJobs = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_JOBS,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataJobsPaging = (param,paramvalue,successHandler, errorHandler) => {
    return {
        type: actions.GET_JOBS_PAGING,
        param:param,
        paramvalue:paramvalue,
        successHandler,
        errorHandler,
    }
};