import axios        from '../../Axios-Simpool';
import * as actions from '../actions';
import {
    clientIdUrl,
    clientDetailUrl,
}                   from '../../shared/apiUrl'
import {put}        from "redux-saga/effects";
import {push}       from "connected-react-router";
import {msgErrorHandler} from '../../shared/maskFunc';

export function* initGetMemberEditSaga(action) {
    yield put(actions.getMemberEditStart());
    try {
        const response = yield axios.get(clientDetailUrl(action.id)).then(res => res.data);
        yield put(actions.getMemberEditSuccess());
        action.successCallback(response);
    } catch (error) {
        console.log('error initGetMemberEditSaga', error);
        if (error.status === 404) {
            yield put(push('/notfound', {backToPath: '/member', backToTitle: 'Member'}));
        } else {
            yield put(actions.getMemberEditFailed());
            action.errorCallback(error.data.message);
        }
    }
}

export function* initMemberEditSaga(action){
    yield put(actions.memberEditStart());
    try {
        const response = yield axios.put(clientIdUrl(action.id), action.payload).then(res => res.data);
        yield put(actions.memberEditSuccess());
        console.log(response);
        action.successCallback(response);
    } catch (error){
        // console.log('error initMemberEditSaga', error);
        yield put(actions.memberEditFailed());
        // var strerror = 'Something Error';
        // if(error.data.errors.length > 0){
        //     strerror = error.data.errors[0].defaultUserMessage;
        // }else{
        //     strerror = error.data.defaultUserMessage;
        // }
        // var strerror = error.data.defaultUserMessage;
        action.errorCallback(msgErrorHandler(error));
        // action.errorCallback(strerror);
    }
}