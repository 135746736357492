import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    depositAccountTransaction,
    accountTransfersUrl,depositAccountTemplate,depositChartsURL,
    depositCreateURL,depositBillerURL
} from '../../shared/apiUrl';
import { msgErrorHandler, toLogout } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* initGetDepositAccountTransactionSaga(action) {
    yield put(actions.depositAccountTransactionStart());
    try {
        const response = yield axios.get(depositAccountTransaction(action.clientId, action.id)).then(response => response.data);
        yield put(actions.depositAccountTransactionSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        yield put(actions.depositAccountTransactionFailed());
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* initGetDepositAccountTransferSaga(action) {
    yield put(actions.depositAccountTransferStart());
    try {
        const response = yield axios.get(accountTransfersUrl(action.id)).then(response => response.data);
        yield put(actions.depositAccountTransferSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        yield put(actions.depositAccountTransferFailed());
        action.errorCallback(error.data.message ?? error.message);
    }
}

export function* getDepositTemplateSaga(action) {
    try {
        const response = yield axios.get(depositAccountTemplate(action.clientid,action.productid)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getDepositChartSaga(action) {
    try {
        const response = yield axios.get(depositChartsURL(action.productid)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* depositCreateSaga(action) {
    try {
        const response = yield axios.post(depositCreateURL,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        console.log('depositCreateSaga ',error);
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getDepositBillerSaga(action) {
    try {
        const response = yield axios.get(depositBillerURL(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitaddDepositBillerSaga(action) {
    try {
        const response = yield axios.post(depositBillerURL(''),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(getErrorJsonData({errors:error}));
        // action.errorCallback(errMessages);
    }
}

export function* submitcancelDepositBillerSaga(action) {
    try {
        const response = yield axios.post(depositBillerURL('/cancel'),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitaddListDepositBillerPhotoSaga(action) {
    try {
        const response = yield axios.post(depositBillerURL(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // msgErrorHandler(error)
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}