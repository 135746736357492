// const apiEntryBiller =
//   'http://demo.ikkat.datacomm.co.id:8081/biller-management/api';
export const apiEntryRooc =
    'https://dev.simpool.id/fineract-provider/api/v1';
export const apiEntrySimpool =
    // 'https://dev.simpool.id/fineract-provider/api/v1';
    // 'https://demo.simpool.id/fineract-provider/api/v1';
    'https://simpool.id/fineract-provider/api/v1';
    // 'https://localhost:8443/fineract-provider/api/v1';

export const getBaseApi = (apiBaseURL) => {
    let value = 'https://simpool.id';
    if (apiBaseURL === 'https://dev.simpool.id/fineract-provider/api/v1') {
        value = 'https://dev.simpool.id';
    } else if (apiBaseURL === 'https://simpool.id/fineract-provider/api/v1') {
        value = 'https://simpool.id';
    } else if (apiBaseURL === 'https://demo.simpool.id/fineract-provider/api/v1') {
        value = 'https://demo.simpool.id';
    }

    return value;
}

//prod 1700
//demo 1701
export const kodeTrxDeposit = (apiBaseURL) => {
    let value = '';
    if (apiBaseURL === 'https://demo.simpool.id/fineract-provider/api/v1') {
        value = '1701';
    } else if (apiBaseURL === 'https://simpool.id/fineract-provider/api/v1') {
        value = '1700';
    } else if (apiBaseURL === 'https://localhost:8443/fineract-provider/api/v1') {
        value = '1700';
    }

    return value;
}
export const weburlLite = (token, server) => `https://lite.rooc.egeroo.ai/v2/embededapps.html?token=${token}&server=${server}`;

export const fineractHeaderKey = 'Fineract-Platform-TenantId';

export const runreports = `${apiEntrySimpool}/runreports`;
export const getListStaff = `${apiEntrySimpool}/staff`;
export const getListLoanProduct = `${apiEntrySimpool}/loanproducts`;
export const glAccountUrl = `${apiEntrySimpool}/glaccounts`;

export const loginUrl = `${apiEntrySimpool}/authentication`;
export const retrieveUserUrl = `${apiEntrySimpool}/authentication/user`;
export const otpUrl = `${apiEntrySimpool}/authentication/otp`;

export const searchUrl = `${apiEntrySimpool}/search`;

// Members
export const reSendPasswordURL = `${apiEntrySimpool}/password/reset`;
export const listmobileuserURL = `${apiEntrySimpool}/externalusers/retrieveallnotpaging`;
export const mobileuserURL = `${apiEntrySimpool}/externalusers`;
export const resetpinURL = `${apiEntrySimpool}/pin/pinreset?command=reset`;
export const createChangePasswordMobileUserURL = userId => `${apiEntrySimpool}/password/change/create/externaluser/${userId}`;
export const confirmChangePasswordMobileUserURL = userId => `${apiEntrySimpool}/password/change/confirm/externaluser/${userId}`;
export const unlockMobileUserURL = userId => `${apiEntrySimpool}/userattempt/unlock-external/create/${userId}`;
export const createMemberNew = `${apiEntrySimpool}/clients/simpoolv3/user`;
export const clientUrl = `${apiEntrySimpool}/clients`;
export const clientAccountUrl = clientId => `${apiEntrySimpool}/clients/${clientId}/accounts`;
export const checkDuplicateUrl = `${apiEntrySimpool}/clients/checkduplicate`;
export const clientIdUrl = clientId => `${apiEntrySimpool}/clients/${clientId}`;
export const clientImageUrl = clientId => `${apiEntrySimpool}/clients/${clientId}/images`;
export const clientDocumentUrl = clientId => `${apiEntrySimpool}/clients/${clientId}/documents`;
export const clientDocumentIdUrl = (clientId, documentId) => `${apiEntrySimpool}/clients/${clientId}/documents/${documentId}`;
export const clientDetailUrl = clientId => `${apiEntrySimpool}/clients/detail/${clientId}`;
export const clientSummaryUrl = clientId => `${apiEntrySimpool}/runreports/ClientSummary?R_clientId=${clientId}&genericResultSet=false`;
export const clientTemplateUrl = `${apiEntrySimpool}/clients/template`;
export const clientTemplateCloseUrl = `${apiEntrySimpool}/clients/template?commandParam=close`;
export const closeMemberURL = clientId => `${apiEntrySimpool}/clients/${clientId}?command=close`;
export const docAttachUrl = (clientId, documentId) => `${apiEntrySimpool}/clients/${clientId}/documents/${documentId}/attachment`;

// Savings
export const accountChargesUrl = (savingsid, chargeId, command) => `${apiEntrySimpool}/savingsaccounts/${savingsid}/charges/${chargeId}${command}`
export const accountTransactionsUrl = (clientId, trxId) => `${apiEntrySimpool}/savingsaccounts/${clientId}/transactions/${trxId}`
export const accountTransfersUrl = tfId => `${apiEntrySimpool}/accounttransfers/${tfId}`
export const qrCodeUrl = savingsId => `${apiEntrySimpool}/ppob/qrcode/${savingsId}/100`
export const savingsAccountUrl = `${apiEntrySimpool}/savingsaccounts`;
export const savingsAccountCloseUrl = savingsId => `${apiEntrySimpool}/savingsaccounts/closeaccount?accountId=${savingsId}`;
export const savingsAccountItermediateUrl = `${apiEntrySimpool}/savingsaccounts/intermediateaccount`;
export const savingsAccountListUrl = `${apiEntrySimpool}/savingsaccounts/list`;
export const savingsAccountAssosiationsUrl = savingsId => `${apiEntrySimpool}/savingsaccounts/${savingsId}?associations=all`;
export const fixDepositAssosiationsUrl = savingsId => `${apiEntrySimpool}/fixeddepositaccounts/${savingsId}?associations=all`;
export const savingsAccountTemplateUrl = `${apiEntrySimpool}/savingsaccounts/template`;
export const savingsProductTemplateUrl = `${apiEntrySimpool}/savingsproducts/template`;
export const depositAccountTransaction = (clientId, depositId) => `${apiEntrySimpool}/fixeddepositaccounts/${clientId}/transactions/${depositId}`;
export const depositAccountTransfer = `${apiEntrySimpool}/fixeddepositaccounts/tra`;
export const productSavingsTemplateUrl = clientId => `${apiEntrySimpool}/savingsaccounts/template?clientId=${clientId}&staffInSelectedOfficeOnly=true`;
export const savingsByActionCommand = (savingsID, command) => `${apiEntrySimpool}/savingsaccounts/${savingsID}?command=${command}`
export const depositAccountTemplate = (clientId, productID) => `${apiEntrySimpool}/fixeddepositaccounts/template?${productID !== '' ? 'productId=' + productID + '&' : ''}clientId=${clientId}`;
export const savingsProductByIDURL = productID => `${apiEntrySimpool}/savingsproducts/${productID}`;
export const depositChartsURL = productID => `${apiEntrySimpool}/simpoolv3/account/fixeddeposit/charts/${productID}`;
export const depositCreateURL = `${apiEntrySimpool}/simpoolv3/account/fixeddeposit/create`;
export const savingsAccountAssosiationsEmptyUrl = savingsId => `${apiEntrySimpool}/savingsaccounts/${savingsId}?associations=`;
export const maintenanceAavingsAccountsUrl = savingsId => `${apiEntrySimpool}/maintenancesavingsaccounts/${savingsId}`;
export const maintenanceChargesSavingsAccountsUrl = param => `${apiEntrySimpool}/maintenancesavingsaccounts/charges/${param}`;

// Loans
export const loansUrl = loanId => `${apiEntrySimpool}/loans/${loanId}?associations=all&exclude=guarantors`;
export const loansDocumentsUrl = loanId => `${apiEntrySimpool}/loans/${loanId}/documents`;
export const loansDocumentsIdUrl = (loanId, docId) => `${apiEntrySimpool}/loans/${loanId}/documents/${docId}`;
export const loansDocAttachmentUrl = (loanId, docId) => `${apiEntrySimpool}/loans/${loanId}/documents/${docId}/attachment`;
export const loansNotesUrl = loanId => `${apiEntrySimpool}/loans/${loanId}/notes`;
export const listLoansUrl = `${apiEntrySimpool}/loans?limit=999999999`;
// export const listLoansSearchQueryUrl = query => `${apiEntrySimpool}/loans?limit=999999999&sqlSearch=${query}`; // l.loan_status_id = 100`;
export const listLoansSearchQueryUrl = query => `${apiEntrySimpool}/loans?sqlSearch=${query}`; // l.loan_status_id = 100`;
export const submitLoanApproveOrRejectURL = (loanId, command) => `${apiEntrySimpool}/loans/${loanId}?command=${command}`;
export const listprovisioningcategory = `${apiEntrySimpool}/provisioningcategory`;
export const loansTemplate = (clientId, productloanid) => `${apiEntrySimpool}/loans/template?clientId=${clientId}&productId=${productloanid}&templateType=individual`;
export const loansv3 = `${apiEntrySimpool}/simpoolv3/loans`;
export const scheduleLoans = `${loansv3}/calculateloanschedule`;

// Transactions
export const findAccountTransferUrl = `${apiEntrySimpool}/accounttransfers/findaccount`;
export const findAccountTransferWithParamUrl = param => `${findAccountTransferUrl}${param}`;
export const findAccountAndGLUrl = `${apiEntrySimpool}/accounttransfers/findaccountandgl`;
export const transactionsUrl = `${apiEntrySimpool}/transactions`;
export const getAllCodeTrx = `${apiEntrySimpool}/coretransactioncode/retrieveAllNonCash`;
export const getCoreTransactionDetailURL = coretransactionid => `${apiEntrySimpool}/coretransactioncode/${coretransactionid}`;
export const generalTransactionURL = `${apiEntrySimpool}/transactions?command=generaltransaction`;
export const transactionByCommandURL = command => `${apiEntrySimpool}/transactions?command=${command}`;
export const paymentToLoanURL = `${apiEntrySimpool}/transactions/backofficer?command=savingstoloan`;
export const feeByTrxModeURL = trxmode => `${apiEntrySimpool}/charges/code/${trxmode}`;
export const findSavingsByAccNumber = accnumber => `${apiEntrySimpool}/savingsaccounts?sqlSearch=sa.account_no='${accnumber}'`;
export const getAllCodeTrxV2 = `${apiEntrySimpool}/coretransactioncode`;

// Dashboard
export const dashboardGenderUrl = `${apiEntrySimpool}/runreports/GenderTrend`;
export const dashboardAgeUrl = `${apiEntrySimpool}/runreports/AgeTrend`;
export const dashboardProvinceUrl = `${apiEntrySimpool}/runreports/ProviceTrend`;
export const dashboardClientMonthUrl = `${apiEntrySimpool}/runreports/ClientTrendByMonth`;
export const dashboardLoanMonthUrl = `${apiEntrySimpool}/runreports/LoanTrendByMonth`;


// Merchant
export const listMerchant = merchantTenantID => `${apiEntrySimpool}/merchant/${merchantTenantID}`;

// Token
export const getTokenService = `${apiEntrySimpool}/token/service`;
export const getTokenTransactionHistory = `${apiEntrySimpool}/token/transactionhistory`;
export const getTokenTransactionHistoryTopUp = `${apiEntrySimpool}/topup/list-history`;
export const getTemplateToken = `${apiEntrySimpool}/token/service/template-token`;
export const createToken = `${apiEntrySimpool}/topup/create`;
export const getTemplateServiceToken = `${apiEntrySimpool}/token/service/template`;
export const getDataTopUp = queryparam => `${apiEntrySimpool}/topup/${queryparam}`;

export const ChangePasswordUserWebOTPURL = `${apiEntrySimpool}/password/change/create`;
export const ConfirmPasswordUserWebOTPURL = `${apiEntrySimpool}/password/change/confirm`;
export const PasswordValidationURL = `${apiEntrySimpool}/passwordvalidation/check`;
export const getUserProfileURL = userid => `${apiEntrySimpool}/users/${userid}`;
export const glAccountByIdUrl = accountid => `${apiEntrySimpool}/glaccounts/${accountid}?template=true`;
export const updateAndDeleteURL = accountid => `${apiEntrySimpool}/glaccounts/${accountid}`;

export const parsingNIKUrl = `${apiEntrySimpool}/mobile/account/client/nik-converter`;
export const unlockConfirmMobileUserURL = userId => `${apiEntrySimpool}/userattempt/unlock-external/confirm/${userId}`;
export const getOfficeUrl = `${apiEntrySimpool}/offices`;
export const getCurrencyUrl = `${apiEntrySimpool}/currencies?fields=selectedCurrencyOptions`;
export const getAccountingRulesUrl = `${apiEntrySimpool}/accountingrules?associations=all`;

export const paymentTypesURL = `${apiEntrySimpool}/paymenttypes`;
export const jurnalEntriesURL = `${apiEntrySimpool}/journalentries`;
export const AccountingRulesURL = `${apiEntrySimpool}/accountingrules`;
export const getDetailAccountingRulesURL = accrulesid => AccountingRulesURL + "/" + accrulesid;
export const listAccountRulesURL = AccountingRulesURL; //`${apiEntrySimpool}/accountingrules`;
export const submitAccountingRulesURL = AccountingRulesURL; //`${apiEntrySimpool}/accountingrules`;
export const AccountRulesTemplateURL = `${apiEntrySimpool}/accountingrules/template`;
export const basedFixDepositUrl = `${apiEntrySimpool}/fixeddepositaccounts`;
export const fixDepositByIdAndCommandsURL = (fixdepositid, command) => `${basedFixDepositUrl}/${fixdepositid}?command=${command}`;
export const getGlAccountIsGeneralTrueURL = `${glAccountUrl}?isGeneralTransaction=true`;
export const fixDepositByQueryParamURL = (fixdepositid, queryparam) => `${basedFixDepositUrl}/${fixdepositid}?${queryparam}`;
export const fixDepositByParamIDURL = fixdepositid => `${apiEntrySimpool}/fixeddepositaccount/${fixdepositid}`;
export const adjusmentTermSavingsURL = fixdepositid => `${apiEntrySimpool}/fixeddepositaccount/adjustment/${fixdepositid}`;
export const getLoanTransTemplateByIdAndCommandURL = (loanid, command) => `${apiEntrySimpool}/loans/${loanid}/transactions/template?command=${command}`;
export const submitTransByIdAndCommandURL = (loanid, command) => `${apiEntrySimpool}/loans/${loanid}/transactions?command=${command}`;
export const basedPortalMenuURL = `${apiEntrySimpool}/portalmenu`;
export const basedRolesURL = `${apiEntrySimpool}/roles`;
export const basedRolesMenuURL = `${apiEntrySimpool}/rolemenu`;
export const getGlAccountByQueryParam = queryparam => `${glAccountUrl}?${queryparam}`;
export const getListPaylaterByQueryParamURL = queryparam => `${apiEntrySimpool}/savingsaccounts/paylater?${queryparam}`;
export const getListUserByParamURL = queryparam => `${apiEntrySimpool}/users?${queryparam}`;
export const getUserTemplateURL = `${apiEntrySimpool}/users/template`;
export const submitInternalUserURL = `${apiEntrySimpool}/users`;
export const detailInternalUserURL = (userid, queryparam) => `${apiEntrySimpool}/users/${userid}${queryparam}`;
export const updateInternalUserURL = (userid) => `${apiEntrySimpool}/users/${userid}`;
export const getOTPChangePassowrdInternalUserURL = (userid) => `${apiEntrySimpool}/password/change/create/internaluser/${userid}`;
export const submitChangePassowrdInternalUserURL = (userid) => `${apiEntrySimpool}/password/change/confirm/internaluser/${userid}`;
export const getOTPUnlockInternalUserURL = (userid) => `${apiEntrySimpool}/userattempt/unlock-internal/create/${userid}`;
export const submitUnlockInternalUserURL = (userid) => `${apiEntrySimpool}/userattempt/unlock-internal/confirm/${userid}`;
export const getMobileUserByUsernameURL = (username) => `${apiEntrySimpool}/externalusers/get-by-username/${username}`;
export const getOTPResetMobileUser = `${apiEntrySimpool}/userdevice/reset/create`;
export const submitOTPResetMobileUser = `${apiEntrySimpool}/userdevice/reset/confirm`;
export const dashboardTotalApexURL = `${apiEntrySimpool}/dashboard/total`;
export const dashboardMemberApexURL = `${apiEntrySimpool}/dashboard/client-month`;
export const dashboardCityApexURL = `${apiEntrySimpool}/dashboard/city`;
export const dashboardClientTenantApexURL = `${apiEntrySimpool}/dashboard/client-tenant`;
export const dashboardGeoCityApexURL = `${apiEntrySimpool}/dashboard/geo-city`;
export const dashboardSyncronizeApexURL = `${apiEntrySimpool}/dashboard/sync`;
export const dashboardActiveMobileUserApexURL = `${apiEntrySimpool}/dashboard/mobileuser-month`;


export const chargesURL = `${apiEntrySimpool}/charges`;
export const chargesWithQueryParamURL = (queryparam) => `${chargesURL}${queryparam}`;
export const merchantDetail = merchantID => `${apiEntrySimpool}/merchant/get-by-id/${merchantID}`;
export const merchantPaymentURL = `${apiEntrySimpool}/merchant/payment`;
export const merchantQrisURL = `${apiEntrySimpool}/merchant/qris`;
export const merchantCreateSimpoolPaymentQRURL = `${apiEntrySimpool}/merchant/create-qr`;
export const seacrhJournalEntriesByParamURL = (queryparam) => `${jurnalEntriesURL}?${queryparam}`;
export const baseAutoSweepURL = `${apiEntrySimpool}/autosweep`;
export const baseAutoSweepGroupURL = `${apiEntrySimpool}/autosweepgroup`;
export const baseAutoSweepBulkURL = `${apiEntrySimpool}/autosweepbulkvalidation`;
export const baseBlockAccountsURL = (param) => `${apiEntrySimpool}/blockedaccounts${param}`;

export const basePromotionManagementURL = `${apiEntrySimpool}/promotions`;
export const getListPromotionManagementURL = `${apiEntrySimpool}/promotions/web`;
export const getDetailPromotionManagementURL = (id) => `${apiEntrySimpool}/promotions/${id}`;
export const baseDepositBillerURL = `${apiEntrySimpool}/ppob/depositbiller`;
export const depositBillerServiceURL = (param) => `${baseDepositBillerURL}/service${param}`;
export const depositBillerURL = (param) => `${baseDepositBillerURL}${param}`;

export const getListDepositBillerMitraURL = `${apiEntrySimpool}/ppob/mitra/list`;
export const getDepositBillerMitraByTenantURL = (tenantid) => `${apiEntrySimpool}/ppob/depositbiller/mitra/list/${tenantid}`;
export const getTemplateDepositBillerMitraByTenantURL = (tenantid) => `${apiEntrySimpool}/ppob/depositbiller/mitra/template/${tenantid}`;
export const getListIntermediaryByQueryParamURL = queryparam => `${apiEntrySimpool}/savingsaccounts/intermediary?${queryparam}`;
export const baseAgencyManagementURL = `${apiEntrySimpool}/agencymanagement`;
export const baseAgentGroupURL = (param) => `${baseAgencyManagementURL}/agentgroup${param}`;
export const baseAgentURL = (param) => `${baseAgencyManagementURL}/agent${param}`;
export const loanCollectionSheetURL = `${apiEntrySimpool}/loans/agents`;
export const submitBatchesURL = `${apiEntrySimpool}/batches`;
export const getAllCodeTrxNonCashURL = `${apiEntrySimpool}/coretransactioncode/retrieveAllNonCash`;
export const basedAccountTransfersURL = `${apiEntrySimpool}/accounttransfers`;
export const getAccTransferTemplateURL = queryparam => `${basedAccountTransfersURL}/template?${queryparam}`;
export const loansChargeTemplateURL = (loanid) => `${apiEntrySimpool}/loans/${loanid}/charges/template`;
export const loansChargeURL = (loanid) => `${apiEntrySimpool}/loans/${loanid}/charges`;
export const loansTemplateURL = (queryparam) => `${apiEntrySimpool}/loans/template/${queryparam}`;
export const baseOfficeUrl = (param) => `${getOfficeUrl}${param}`;
export const baseExternalServiceSMTPUrl = `${apiEntrySimpool}/externalservice/SMTP`;
export const basePassbookUrl = (param) => `${apiEntrySimpool}/typepassbook${param}`;
export const resetPassbookUrl = `${apiEntrySimpool}/passbookreset`;
export const printPassbookUrl = (accnumber) => `${apiEntrySimpool}/printpassbook${accnumber}`;
export const passbookCoverUrl = (param) => `${apiEntrySimpool}/passbookcover${param}`;
export const rePrintPassbookUrl = `${apiEntrySimpool}/printpassbook/reprint`;
export const baseReferralCodeURL = (param) => `${apiEntrySimpool}/referralcode${param}`;
export const getDepositBillerListSettlementURL = (tenantid) => `${apiEntrySimpool}/ppob/depositbiller/list-settlement/${tenantid}`;
export const baseppobproductgroupURL = `${apiEntrySimpool}/ppob/productgroup/template`;
export const baseTransactionModeURL = `${apiEntrySimpool}/coretransactionmode`;
export const baseTransactionCodeURL = `${apiEntrySimpool}/coretransactioncode`;
export const baseChargesURL = `${apiEntrySimpool}/charges`;
export const baseTransactionCodeDetailURL = (param) => `${apiEntrySimpool}/coretransactioncodedetail${param}`;
export const baseTransactionCodeDetailOverDraftURL = (param) => `${apiEntrySimpool}/coretransactioncodeoverdraftdetail${param}`;
export const baseTransactionCodeLimitURL = (param) => `${apiEntrySimpool}/coretransactioncodelimit${param}`;
export const baseClientJobURL = (param) => `${apiEntrySimpool}/clientjobs${param}`;
export const baseOfficeApprovalURL = (param) => `${apiEntrySimpool}/officeapproval${param}`;
export const basedFixDepositWithParamUrl = (param) => `${basedFixDepositUrl}${param}`;
export const dashboardGenderApexUrl = `${apiEntrySimpool}/dashboard/client-gender`;
export const dashboardAgeApexUrl = `${apiEntrySimpool}/dashboard/client-age`;
export const dashboardLoanApexUrl = `${apiEntrySimpool}/dashboard/loan`;
export const dashboardLoanDisburseApexUrl = `${apiEntrySimpool}/dashboard/loan-disburse`;
export const baseProductTemplateURL = (param) => `${apiEntrySimpool}/templateproducts${param}`;
export const basedTransactionCodeControlUrl = (param) => `${apiEntrySimpool}/transactioncodecontrol${param}`;
export const basedClienApproval = (param) => `${apiEntrySimpool}/clientapproval${param}`;
export const baseGLRecurringURL = `${apiEntrySimpool}/glrecurring`;
export const glRecurringTemplateURL = `${apiEntrySimpool}/glrecurring/template`;
export const feeByTrxModeChargeProductURL = param => `${apiEntrySimpool}/charges/chargeproduct/${param}`;
export const memberCommandURL = (clientId, command) => `${apiEntrySimpool}/clients/${clientId}?command=${command}`;
export const clientTemplateParamUrl = (param) => `${apiEntrySimpool}/clients/template${param}`;
export const savingsProductUrl = (param) => `${apiEntrySimpool}/savingsproducts/${param}`;
export const typePassBookUrl = (param) => `${apiEntrySimpool}/typepassbook/${param}`;
export const memberEntityUrl = `${apiEntrySimpool}/member/entity`;
export const memberPersonUrl = `${apiEntrySimpool}/member/person`;
export const getclientDataWithParamUrl = (param) => `${apiEntrySimpool}/clients${param}`;
export const unitUserURL = (param) => `${apiEntrySimpool}/unitusers/${param}`;
export const fundsWithParamURL = (param) => `${apiEntrySimpool}/funds${param}`;
export const currencyWithParamURL = (param) => `${apiEntrySimpool}/currencies${param}`;
export const updateEmployeeInformationDataImportURL = (param) => `${apiEntrySimpool}/clientjobs/updatefromdmt${param}`;
export const loansByParamURL = (param) => `${apiEntrySimpool}/loans/${param}`;
export const getListDataPaylaterByQueryParamURL = queryparam => `${apiEntrySimpool}/savingsaccounts/paylater${queryparam}`;
export const listMobileUserResetURL = `${apiEntrySimpool}/password/reset/list`;
export const savingTransactionURL = `${apiEntrySimpool}/savingsaccounts/transaction`;
export const loantypePassBookUrl = (param) => `${apiEntrySimpool}/loanpassbooktype/${param}`;
export const getFindLoanAccNumberUrl = (param) => `${basedAccountTransfersURL}/checkloanaccount${param}`;
export const loanPassBookCoverUrl = (param) => `${apiEntrySimpool}/loanpassbookcover/${param}`;
export const printloanpassbookUrl = (param) => `${apiEntrySimpool}/printloanpassbook/${param}`;
export const loanpassbookresetUrl = (param) => `${apiEntrySimpool}/loanpassbookreset/${param}`;
export const employmentLevelUrl = (param) => `${apiEntrySimpool}/employmentlevel/${param}`;
export const productSavingsTemplatePaylaterUrl = clientId => `${apiEntrySimpool}/savingsaccounts/template/paylater?clientId=${clientId}&staffInSelectedOfficeOnly=true`;
export const baseExternalServiceChatBotUrl = `${apiEntrySimpool}/externalservice/chat-boot`;
export const submitInternalLockUserURL = (userid, type) => `${apiEntrySimpool}/userattempt/lock/${type}/${userid}`;
export const getListSavingsByQueryParamURL = queryparam => `${apiEntrySimpool}/savingsaccounts/paging?${queryparam}`;
export const updateClientSimpleURL = `${apiEntrySimpool}/dataimport/client/update`;
export const memberPagingPersonUrl = (param) => `${apiEntrySimpool}/member${param}`;
export const transactionlimitUrl = (param) => `${apiEntrySimpool}/transactionlimit/${param}`;
export const getAllCodeTrxCashURL = `${apiEntrySimpool}/coretransactioncode/retrieveAllCash`;
export const tellerlimitUrl = (param) => `${apiEntrySimpool}/tellerlimit/${param}`;
export const denominationUrl = (param) => `${apiEntrySimpool}/denomination/${param}`;
export const financialactivityaccountsUrl = (param) => `${apiEntrySimpool}/financialactivityaccounts/${param}`;
export const makercheckersUrl = (param) => `${apiEntrySimpool}/makercheckers/${param}`;
export const auditsUrl = (param) => `${apiEntrySimpool}/audits/${param}`;
export const tellerDashboardUrl = (param) => `${apiEntrySimpool}/teller-dashboard/${param}`;
export const glSummaryParameterdUrl = (param) => `${apiEntrySimpool}/glsummary-parameter/${param}`;
export const baseprintPassbookUrl = (param) => `${apiEntrySimpool}/printpassbook${param}`;
export const externalUserParamUrl = (param) => `${apiEntrySimpool}/externalusers${param}`;
export const userParamUrl = (param) => `${apiEntrySimpool}/users${param}`;
export const termSavingParamUrl = (param) => `${apiEntrySimpool}/savingsaccounts/termdeposit${param}`;
export const loansSubmissionPagingParamURL = (param) => `${apiEntrySimpool}/loans/submissionpaging${param}`;
export const reportJobUrl = (param) => `${apiEntrySimpool}/report-job${param}`;
export const loanProductUrl = (param) => `${apiEntrySimpool}/loanproducts${param}`;
export const recurringdepositaccountsUrl = (param) => `${apiEntrySimpool}/recurringdepositaccounts${param}`;
export const savingRecurringParamUrl = (param) => `${apiEntrySimpool}/savingsaccounts/recurring-deposit${param}`;
export const accountTransferParamUrl = (param) => `${basedAccountTransfersURL}${param}`;
export const basedFixDepositParamUrl = (param) => `${basedFixDepositUrl}${param}`;
export const findSavingRecurringferUrl = (param) => `${apiEntrySimpool}/accounttransfers/findSavingRecurring${param}`;
export const getTokenParamUrl = (param) => `${apiEntrySimpool}/token${param}`;
export const provisioningcriteriaUrl = (param) => `${apiEntrySimpool}/provisioningcriteria${param}`;
export const interbranchSettlementUrl = (param) => `${apiEntrySimpool}/interbranch-settlement${param}`;
export const merchantTemplateUrl = merchantTenantID => `${apiEntrySimpool}/merchant/template/${merchantTenantID}`;
export const getSavingmerchantUrl = (merchantTenantID, savinsAccount) => `${apiEntrySimpool}/merchant/inqury-account-mitra-by-saving/${merchantTenantID}/${savinsAccount}`;
export const merchantUrl = (param) => `${apiEntrySimpool}/merchant${param}`;
export const mobileMenuUrl = (param) => `${apiEntrySimpool}/mobilemenu${param}`;
export const mobileVersionUrl = (param) => `${apiEntrySimpool}/mobile-version${param}`;
export const mobileCommerceUrl = (param) => `${apiEntrySimpool}/commerces${param}`;
export const baseJobsURL = (param) => `${apiEntrySimpool}/jobs${param}`;

//loans
export const loansWithParamURL = (param) => `${apiEntrySimpool}/loans${param}`;
export const subLoansListApprovalURL = (param) => `/listapproval${param}`;
export const subLoansApprovalURL = (param) => `/approve${param}`;
export const subLoansRejectURL = (param) => `/reject${param}`;
export const subLoansCSVApprovalURL = (param) => `/exportcsv/loanapproval${param}`;
export const baseOfficeApprovalLoanURL = (param) => `${apiEntrySimpool}/officeapprovalloan${param}`;
export const baseUserGroupURL = (param) => `${apiEntrySimpool}/usergroup${param}`;


//bankproductgroups
export const baseProductGroupURL = (param) => `${apiEntrySimpool}/bankproductgroups${param}`;
export const submitProductGroupURL = (param) => `/upload${param}`;
export const templateProductGroupURL = (param) => `/template${param}`;
export const baseProductGroupDetailsURL = (param) => `${apiEntrySimpool}/bankproductgroupdetails${param}`;
//transferclientaccounthistory
export const baseTransferclientaccounthistoryURL = (param) => `${apiEntrySimpool}/transferclientaccounthistory${param}`;
export const transferClientHistoryForClientURL = (param) => `/client${param}`;
export const transferClientHistoryForAccountURL = (param) => `/account${param}`;

//organisationposition
export const baseorganisationpositionURL = (param) => `${apiEntrySimpool}/organisationposition${param}`;

//organisationstructure
export const baseorganisationstructureURL = (param) => `${apiEntrySimpool}/organisationstructure${param}`;
//
export const loanCollectionSheetPagingURL = (param) => `${apiEntrySimpool}/loans/collectionsheetpaging${param}`;
export const loanCollectionSheetParamURL = (param) => `${apiEntrySimpool}/loans/collectionsheet${param}`;

export const submitDataImportFileFinancingRepaymentURL = (param) => `${apiEntrySimpool}/dataimport/loan/makerepaymentbulk${param}`;

export const baseAPISimpoolBulk = (param) => `${getBaseApi(apiEntrySimpool)}/bulk-import/api/simpool-bulk${param}`;
export const getTemplateSimpoolBulkURL = (param) => `${baseAPISimpoolBulk("")}/template${param}`;
export const uploadFileSimpoolBulkURL = (param) => `${baseAPISimpoolBulk("")}/upload${param}`;
export const downloadResultFileSimpoolBulkURL = (param) => `${baseAPISimpoolBulk("")}/download${param}`;
export const progressSimpoolBulkURL = (param) => `${baseAPISimpoolBulk("")}/progress${param}`;
export const listSimpoolBulkURL = (param) => `${baseAPISimpoolBulk("")}/list${param}`;
export const formatReportCodeURL = (param) => `${apiEntrySimpool}/reportformat/code${param}`;
export const dashboardtokenURL = (param) => `${apiEntrySimpool}/dashboard/token${param}`;
