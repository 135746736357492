import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
    rows: [],
    total: 0,
    error: false,
    template: null,
};

const fetchMemberList = (state, action) => {
    return updateObject(state, {
        rows: action.data.pageItems,
        total: action.data.totalFilteredRecords,
        error: false,
    })
};

const fetchMemberFailed = (state, action) => {
    return updateObject(state, {
        error: true
    });
};

const fetchMemberTemplateFailed = (state, action) => {
    return updateObject(state, {
        error: true
    });
};

const fetchMemberTemplate = (state, action) => {
    return updateObject(state, {
        template: action.template,
        error: false,
    });
};


const reduce = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.FETCH_MEMBER_LIST: return fetchMemberList(state, action);
        case actionTypes.FETCH_MEMBER_FAILED: return fetchMemberFailed(state, action);
        case actionTypes.FETCH_MEMBER_TEMPLATE_FAILED: return fetchMemberTemplateFailed(state, action);
        case actionTypes.FETCH_MEMBER_TEMPLATE: return fetchMemberTemplate(state, action);
        default: return state;
    }
};

export default reduce;

