import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function IconDataImport(props) {
    return (
        <SvgIcon {...props} viewBox='0 -30 190 190'>
            <path fillRule='evenodd' className="cls-1" d="M66,67.42H104.7a9.39,9.39,0,0,1,9.36,9.37v36.72a9.39,9.39,0,0,1-9.36,9.37H66a9.39,9.39,0,0,1-9.37-9.37V76.79A9.4,9.4,0,0,1,66,67.42Zm-45.5.16a2.9,2.9,0,0,1-2.74-3,2.85,2.85,0,0,1,2.74-3H43.88a2.9,2.9,0,0,1,2.74,3,2.85,2.85,0,0,1-2.74,3ZM87.79,18.19h9.83a6.89,6.89,0,0,1,4.94,2.06,7,7,0,0,1,2.07,4.95V58.38h-6V25.2a1,1,0,0,0-.29-.72,1,1,0,0,0-.71-.28H87.76V58.38h-6V7a1,1,0,0,0-1-1H7a1,1,0,0,0-.71.29A1,1,0,0,0,6,7V90.36a1,1,0,0,0,1,1H45.85v6h-23v11.17a1,1,0,0,0,1,1h22v6h-22a7.05,7.05,0,0,1-7-7V97.37H7a7.05,7.05,0,0,1-7-7V7A6.93,6.93,0,0,1,2.06,2.06,7,7,0,0,1,7,0H80.78a6.91,6.91,0,0,1,5,2.06A7,7,0,0,1,87.79,7V18.19ZM20.43,28.65a2.9,2.9,0,0,1-2.74-3,2.84,2.84,0,0,1,2.74-3H67.26a2.9,2.9,0,0,1,2.74,3,2.85,2.85,0,0,1-2.74,3Zm0,19.46a2.89,2.89,0,0,1-2.74-3,2.84,2.84,0,0,1,2.74-3H67.26a2.89,2.89,0,0,1,2.74,3,2.84,2.84,0,0,1-2.74,3Zm77,45.29a2.42,2.42,0,0,1,2.06,1c1.08,1.62-.39,3.22-1.42,4.35-2.9,3.18-9.47,9-10.9,10.63a2.36,2.36,0,0,1-3.71,0C82,107.61,75.09,101.5,72.33,98.4c-1-1.08-2.14-2.55-1.14-4a2.42,2.42,0,0,1,2.06-1h5.16V84.14a2.92,2.92,0,0,1,2.91-2.91h8.1a2.92,2.92,0,0,1,2.91,2.91V93.4Z"/>
        </SvgIcon>
    );
}

export default IconDataImport;