export const keyEcncrypt = 'Abio7Q9@916-266!';
export const keyLoginDataLocStorage = 'simpool';
export const keyToken = 'token';

export const chartColors = [
    "rgba(51, 102, 153, 0.4)",
    "rgba(153, 204, 255, 0.4)",
    "rgba(153, 153, 51, 0.4)",
    "rgba(102, 102, 153, 0.4)",
    "rgba(204, 153, 51, 0.4)",
    "rgba(0, 102, 102, 0.4)",
    "rgba(51, 153, 255, 0.4)",
    "rgba(153, 51, 0, 0.4)",
    "rgba(204, 204, 153, 0.4)",
    "rgba(102, 102, 102, 0.4)",
    "rgba(255, 204, 102, 0.4)",
    "rgba(102, 153, 204, 0.4)",
    "rgba(102, 51, 102, 0.4)",
    "rgba(153, 153, 204, 0.4)",
    "rgba(204, 204, 204, 0.4)",
    "rgba(102, 153, 153, 0.4)",
    "#CCCC66",
    "#CC6600",
    "#9999FF",
    "#0066CC",
    "#99CCCC",
    "#999999",
    "#FFCC00",
    "#009999",
    "#99CC33",
    "#FF9900",
    "#999966",
    "#66CCCC",
    "#339966",
    "#CCCC33",
    "#003f5c",
    "#665191",
    "#a05195",
    "#d45087",
    "#2f4b7c",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
    "#EF6F6C",
    "#465775",
    "#56E39F",
    "#59C9A5",
    "#5B6C5D",
    "#0A2342",
    "#2CA58D",
    "#84BC9C",
    "#CBA328",
    "#F46197",
    "#DBCFB0",
    "#545775"
  ];

export const deleteSessionSearchFilter = () => {
  sessionStorage.removeItem('accnopaylater');
  sessionStorage.removeItem('productpaylater');
  sessionStorage.removeItem('namepaylater');
  sessionStorage.removeItem('officepaylater');
  sessionStorage.removeItem('accnopaylateroperation');
  sessionStorage.removeItem('productpaylateroperation');
  sessionStorage.removeItem('namepaylateroperation');
  sessionStorage.removeItem('officepaylateroperation');
  sessionStorage.removeItem('accountNosavings');
  sessionStorage.removeItem('savingsProductNamesavings');
  sessionStorage.removeItem('displaynamesavings');
  sessionStorage.removeItem('clientNamesavings');
  sessionStorage.removeItem('availableBalancesavings');
  sessionStorage.removeItem('officeNameSavings');
  sessionStorage.removeItem('activatedOnDateSavings');
  sessionStorage.removeItem('lastActiveTransactionDateSavings');
  sessionStorage.removeItem('accountNosavingsoperation');
  sessionStorage.removeItem('savingsProductNamesavingsoperation');
  sessionStorage.removeItem('displaynamesavingsoperation');
  sessionStorage.removeItem('clientNamesavingsoperation');
  sessionStorage.removeItem('availableBalancesavingsoperation');
  sessionStorage.removeItem('officeNameSavingsoperation');
  sessionStorage.removeItem('activatedOnDateSavingsoperation');
  sessionStorage.removeItem('lastActiveTransactionDateSavingsoperation');
  sessionStorage.removeItem('officeteller');

  sessionStorage.removeItem('accnorecurring');
  sessionStorage.removeItem('productrecurring');
  sessionStorage.removeItem('namerecurring');
  sessionStorage.removeItem('officerecurring');
  sessionStorage.removeItem('accnorecurringoperation');
  sessionStorage.removeItem('productrecurringoperation');
  sessionStorage.removeItem('namerecurringoperation');
  sessionStorage.removeItem('officerecurringoperation');

  sessionStorage.removeItem('SEARCH-TELLER-OP');

  localStorage.removeItem('interestPostingLinkedAccount');
}

export const colourStatusLoan = (param) => {
  let idstatus = param.idstatus;
  let inArrears = param.inarrears;
  let type = param.type;
  // console.log('colourStatusLoan idstatus ',idstatus);
  // console.log('colourStatusLoan inArrears ',inArrears);
  let colour = 'green';
  if(idstatus == 600 || idstatus == 601 || idstatus == 500){
    colour = 'dimgray';
    if(type == 'LIST'){
      colour = 'gray';
    }
  }else if(idstatus == 700){
    colour = 'purple';
  }else if(idstatus == 300){
    if(inArrears){
      colour = 'red';
    }else{
      colour = 'green';
    }
  }else if(idstatus == 200){
    colour = 'blue';
  }else if(idstatus == 100){
    colour = 'orange';
  }
  // console.log('colourStatusLoan colour ',colour);
  return colour;
}

export const greenColors = '#45f248';
export const buttonSubmitColors = 'primary';
