import axios        from '../../Axios-Simpool';
import axiosImage   from '../../Axios-Image';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    clientIdUrl,
    clientImageUrl,
    clientAccountUrl,
    clientSummaryUrl,
    clientDocumentUrl,
    clientDocumentIdUrl,
    docAttachUrl,
} from '../../shared/apiUrl';
import {push}       from 'connected-react-router';
import { toLogout } from '../../shared/maskFunc';

export function* initGetMemberDetailSaga(action) {
    yield put(actions.getMemberStart());
    try {
        const response = yield axios.get(clientIdUrl(action.id)).then(res => res.data);
        yield put(actions.getMemberSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        if (error.status === 404) {
            yield put(push('/notfound', {backToPath: '/member', backToTitle: 'Member'}));
        } else {
            yield put(actions.getMemberFailed());
            action.errorCallback(error.data.message);
        }
    }
}

export function* initGetMemberImageSaga(action) {
    yield put(actions.getMemberImageStart());
    try {
        const response = yield axios.get(clientImageUrl(action.id), {
            headers: {
                'Accept': 'text/plain'
            }
        }).then(res => res.data);
        yield put(actions.getMemberImageSuccess());
        action.successCallback(response);
    } catch (error) {
        if (error.status === 404) {
            yield put(actions.getMemberImageSuccess());
            action.successCallback(null);
        } else {
            console.log(error);
            yield put(actions.getMemberImageFailed());
            const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
            action.errorCallback(errMessages);
        }
    }
}

export function* initGetMemberAccountSaga(action) {
    yield put(actions.getMemberAccountStart());
    try {
        const response = yield axios.get(clientAccountUrl(action.id)).then(res => res.data);
        yield put(actions.getMemberAccountSuccess());
        action.successCallback(response);
    } catch (error) {
        console.log(error);
        toLogout(error);
        yield put(actions.getMemberAccountFailed());
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetMemberSummarySaga(action) {
    yield put(actions.getMemberSummaryStart());
    try {
        const response = yield axios.get(clientSummaryUrl(action.id)).then(res => res.data);
        yield put(actions.getMemberSummarySuccess());
        action.successCallback(response);
    } catch (error) {
        console.log(error);
        yield put(actions.getMemberSummaryFailed());
        action.errorCallback(error.data.message);
    }
}

export function* initGetMemberDocSaga(action) {
    yield put(actions.getMemberDocStart());
    try {
        const response = yield axios.get(clientDocumentUrl(action.id)).then(res => res.data);
        yield put(actions.getMemberDocSuccess());
        action.successCallback(response);
    } catch (error) {
        console.log(error);
        yield put(actions.getMemberDocFailed());
        action.errorCallback(error.data.message);
    }
}

export function* initDeleteMemberImageSaga(action) {
    yield put(actions.deleteMemberImageStart());
    try {
        yield axios.delete(clientImageUrl(action.id)).then(res => res.data);
        yield put(actions.deleteMemberImageSuccess());
        action.successCallback();
    } catch (error) {
        console.log(error);
        yield put(actions.deleteMemberImageFailed());
        action.errorCallback(error.data.message);
    }
}

export function* initDeleteMemberDocSaga(action) {
    yield put(actions.deleteMemberDocStart());
    try {
        yield axios.delete(clientDocumentIdUrl(action.id, action.docId)).then(res => res.data);
        yield put(actions.deleteMemberDocSuccess());
        action.successCallback(action.docId);
    } catch (error) {
        console.log(error);
        yield put(actions.deleteMemberDocFailed());
        action.errorCallback(error.data.message);
    }
}

export function* initGetDocumentSaga(action){
    yield put(actions.getDocumentStart());
    try {
        const response = yield axiosImage.get(docAttachUrl(action.id, action.document.id));

        yield put(actions.getDocumentSuccess());
        const linkUrl = window.URL.createObjectURL(new Blob([response.data]));
        action.successCallback(linkUrl, action.document);
    } catch (error) {
        console.log(error);
        yield put(actions.getDocumentFailed());
        action.errorCallback(error.data.message);
    }
}