import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    qrCodeUrl,
    savingsAccountAssosiationsUrl,
    fixDepositAssosiationsUrl
}                   from '../../shared/apiUrl';
import { toLogout } from '../../shared/maskFunc';

export function* initGetMemberSavingDetailSaga(action) {
    yield put(actions.getMemberSavingDetailStart());
    try {
        const response = yield axios.get(savingsAccountAssosiationsUrl(action.id)).then(response => response.data);
        yield put(actions.getMemberSavingDetailSuccess());
        action.successCallback(response);
    } catch (error) {
        console.log(error);
        toLogout(error);
        yield put(actions.getMemberSavingDetailFailed());
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetMemberDepositDetailSaga(action) {
    yield put(actions.getMemberDepositDetailStart());
    try {
        const response = yield axios.get(fixDepositAssosiationsUrl(action.id))
            .then(response => response.data);
        yield put(actions.getMemberDepositDetailSuccess());
        action.successCallback(response);
    } catch (error) {
        console.log(error);
        toLogout(error);
        yield put(actions.getMemberDepositDetailFailed());
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetMemberSavingQRImageSaga(action) {
    yield put(actions.getMemberSavingQRCodeStart());
    try {
        const response = yield axios.get(qrCodeUrl(action.id))
            .then(res => res.data);
        if (response.status === 500)
            throw {
                data: {
                    message: 'error - 500'
                }
            };
        yield put(actions.getMemberSavingQRCodeSuccess());
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        if (error.status === 404) {
            yield put(actions.getMemberSavingQRCodeSuccess());
            action.errorCallback('notfound');
        } else {
            yield put(actions.getMemberSavingQRCodeFailed());
            const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
            action.errorCallback(errMessages);
        }
    }
}