import axios        from '../../Axios-Simpool';

import {
    baseExternalServiceSMTPUrl, baseJobsURL
}                   from '../../shared/apiUrl';
import {toLogout} from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* getDataExternalServiceSMTPSaga(action) {    
    try {
        const response = yield axios.get(baseExternalServiceSMTPUrl).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* editExternalServiceSMTPSaga(action) {
    try {
        const response = yield axios.put(baseExternalServiceSMTPUrl,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataJobsSaga(action) {
    try {
        const response = yield axios.get(baseJobsURL(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* getDataJobsPagingSaga(action) {
    try {
        const response = yield axios.get(baseJobsURL(action.param)).then(response => response.data);
        action.successHandler(response,action.paramvalue);
    } catch (error) {
        toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}),action.paramvalue);
    }
}