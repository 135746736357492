import { combineReducers } from 'redux';
import {connectRouter}     from 'connected-react-router';
import {history}           from '../history';
import settingsReducer     from './settings.reducer.js';
import authReducer         from './auth';
import memberReducer       from './member';
import savingsReducer      from './savings';
import mobileuserReducer from './mobileuser';
import loanReducer from './loan';
import loanNeedApprovalReducer from './loanneedapproval';
import loandisbursmentReducer from './loandisbursment';
import merchantReducer from './merchant';
import coaReducer from './coa';
import dataReducer from './data';

export default combineReducers({
    settings: settingsReducer,
    auth: authReducer,
    member: memberReducer,
    savings: savingsReducer,
    mobileuser:mobileuserReducer,
    loan:loanReducer,
    loanneedapproval:loanNeedApprovalReducer,
    loandisbursment:loandisbursmentReducer,
    merchant:merchantReducer,
    loan:loanReducer,
    coa:coaReducer,
    data:dataReducer,
    router: connectRouter(history),
});
