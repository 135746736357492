import React, {useEffect,useState} from 'react';
import {useDispatch}                from 'react-redux';
import styled                       from "styled-components";
import Dialog                       from '@material-ui/core/Dialog';
import DialogContent                from '@material-ui/core/DialogContent';
import {useTranslation}             from 'react-i18next';
import * as actions     from '../../store/actions';
import {withStyles}                 from '@material-ui/core/styles';
import MuiDialogTitle               from '@material-ui/core/DialogTitle';
import Typography                   from '@material-ui/core/Typography';
import IconButton                   from '@material-ui/core/IconButton';
import CloseIcon                    from '@material-ui/icons/Close';
import {
    Container, Card, CardBody
    , Button, CardHeader,Input,
    }                                   from 'reactstrap';
    import Skeleton         from 'react-loading-skeleton';
import TablerProfileRole from './TableUserProfileRole';

const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    height: 500px;
  }
`;

const UserProfilDialog = props => {
    const dispatch = useDispatch();
    const i18n = useTranslation('translations');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        props.setloading(true);
        setLoading(true);
        dispatch(actions.getUserProfile(successHandler,props.errorHandler));
    }, []);

    function createData(role, description) {
        return { role, description};
    }
    const successHandler = (data) => {
        //selectedRoles
        console.log('successHandler ',data);
        setData(data);
        let arr = [];
        for (var i = 0; i < data.selectedRoles.length; i++) {
            let role = data.selectedRoles[i].name;
            let description = data.selectedRoles[i].description;
            arr.push(createData(role,description));
        }
        setRows(arr);
        setLoading(false);
        props.setloading(false);   
    }

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
      });

      const DialogTitle = withStyles(styles)((props) => {
        const {children, classes, onClose, ...other} = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
      });
      return (
        <div style={{height:'700px'}}>
            <DialogTitle id="confirmation-dialog-title" onClose={() => props.showflag(false)}>
            {i18n.t('label_USER_PROFILE')}
            </DialogTitle>
            <DialogContent dividers>
            <Card style={{width:"500px",height:"300px"}}>
                <CardHeader tag="h4">{loading ? <Skeleton/> : i18n.t('label_PROFILE')}</CardHeader>
                <CardBody>
                    {
                        loading ?<Skeleton count={7} height={21} style={{marginTop: '1rem'}}/> :
                        (
                            <section>
                                <div className="row mt-3">
                                <span className="col-md-3">{i18n.t('label_USERID')}</span>
                                <strong className="col-md-7">
                                    {data != null ?data.id:''}
                                </strong>
                                </div>
                                <div className="row mt-3">
                                <span className="col-md-3">{i18n.t('label_USERNAME')}</span>
                                <strong className="col-md-7">
                                    {data != null ?data.username:''}
                                </strong>
                                </div>
                                <div className="row mt-3">
                                <span className="col-md-3">{i18n.t('label_OFFICE')}</span>
                                <strong className="col-md-7">
                                    {data != null ?data.officeName:''}
                                </strong>
                                </div>
                                <div className="row mt-3">
                                <span className="col-md-3">{i18n.t('Status')}</span>
                                <strong className="col-md-7">
                                    {localStorage.getItem('authenticated') ?localStorage.getItem('authenticated') ? i18n.t('label_AUTHENTICATED'):i18n.t('label_NOT_AUTHENTICATED'):''}
                                </strong>
                                </div>
                                <div className="row mt-3">
                                <span className="col-md-3">{i18n.t('Email')}</span>
                                <strong className="col-md-7">
                                    {data != null ?data.email:''}
                                </strong>
                                </div>
                                
                            </section>
                           
                        )
                        // (
                        //     <div>
                        //         <TablerProfileRole
                        //             rowsschedule={rows}
                        //         />
                        //     </div>
                        // )
                    }
                </CardBody>
            </Card>
            <Card style={{width:"600px",height:"300px",marginLeft:"530px",marginTop:"-318px"}}>
                <CardHeader tag="h4">{loading ? <Skeleton/> : i18n.t('label_USER_ROLE')}</CardHeader>
                <CardBody>
            {
                
                loading ? <Skeleton/> :
                (
                    
                    <section>
                    {/* <div style={{maxHeight:'280px'}}> */}
                    <TablerProfileRole
                        rowsschedule={rows}
                    />
                    {/* </div> */}
                    </section>
                    
                )
            }
            </CardBody>
            </Card>
            
            </DialogContent>
            {/* <DialogTitle id="confirmation-dialog-title" onClose={() => props.showflag(false)}>
            {i18n.t('USER PROFILE ROLE')}
            </DialogTitle>
            <DialogContent dividers>
            {
                loading ? <Skeleton/> :
                (
                    // <div style={{marginTop:'-280px',marginLeft:'535px'}}>
                    <TablerProfileRole
                        rowsschedule={rows}
                    />
                    // </div>
                )
            }
            </DialogContent> */}
        </div>
      )
}
export default UserProfilDialog;