import React, {useEffect,useState} from 'react';
import {useDispatch}                from 'react-redux';
import styled                       from "styled-components";
import Dialog                       from '@material-ui/core/Dialog';
import DialogContent                from '@material-ui/core/DialogContent';
import DialogActions                from '@material-ui/core/DialogActions';
import {useTranslation}             from 'react-i18next';
import {
    Button,Input
}                                   from 'reactstrap';
import * as actions     from '../../store/actions';
import {withStyles}                 from '@material-ui/core/styles';
import MuiDialogTitle               from '@material-ui/core/DialogTitle';
import Typography                   from '@material-ui/core/Typography';
import IconButton                   from '@material-ui/core/IconButton';
import CloseIcon                    from '@material-ui/icons/Close';
import {Loading}                    from '../../components/Common/Loading';
import Countdown from 'react-countdown';


const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    height: 500px;
  }
`;



const ChangePassworddDialog = props => {
    const dispatch = useDispatch();
    const i18n = useTranslation('translations');
    const [newpw, setNewPW] = useState('');
    const [repeatnewpw, setRepeatNewPW] = useState('');
    const [otpcode, setOtpCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [labelinfolengthow, setLabelInfoMinLengthPW] = useState('');
    const [expmilis, setExpMilis] = useState(0);
    const [referrence, setReferrence] = useState('');
    const [currdate, setCurrdate] = useState(null);
    // Random component
    const Completionist = () => <span>OTP Expired</span>;
    
    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return <span>{minutes>9?minutes:'0'+minutes}:{seconds>9?seconds:'0'+seconds}</span>;
    }
    };

    useEffect(() => {
        // setTimeout(function(){ console.log('')}, 1000);
        props.setloading(true);
        dispatch(actions.createChangePasswordUserWebOTP(successHandler,props.errorHandler));
        dispatch(actions.getPasswordValidation(HandlePasswordvalidation, props.errorHandler));
    }, []);
    const HandlePasswordvalidation = (data) => {
        let selectPWLength = data.filter(s => s.name === 'password-length' && s.active == true);
        if(selectPWLength.length > 0){
            setLabelInfoMinLengthPW(selectPWLength[0].value);
        }else{
            setLabelInfoMinLengthPW('1');
        }
        props.setloading(false);
    }
    const successHandler = (data) => {
        setExpMilis(data.expiredMillis);
        setReferrence(data.reference);
        setCurrdate(new Date());
    }
    

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
      });
      
    const DialogTitle = withStyles(styles)((props) => {
        const {children, classes, onClose, ...other} = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
      });
      const handleChangePW = (data) => {
        setNewPW(data.target.value);
      }
      const handleChangeRepeatPW = (data) => {
        setRepeatNewPW(data.target.value);
      }
      const handleChangeOtpCode = (data) => {
        setOtpCode(data.target.value);
      }
      function confirmChangePW(){
        var obj = new Object();
        obj.password = newpw;
        obj.repeatPassword = repeatnewpw;
        obj.otpCode = otpcode;
        obj.reference = referrence;
        var jsonString= JSON.stringify(obj);
        dispatch(actions.confirmChangePasswordUserWebOTP(jsonString,props.successHandlerConfirmPW,props.errorHandler));
      }
      const handleChangeTimeExpired = () => {
            return currdate != null && expmilis > 0 ? expmilis - (Math.abs(new Date() - currdate)):expmilis;
      }
      return (
          
        <div>
            <DialogTitle id="confirmation-dialog-title" onClose={() => props.showflag(false)}>
            {i18n.t('label_CHANGE_PASSWORD')}
            </DialogTitle>
            <DialogContent dividers>
                <div>
                <label className="mt-3 form-label required" htmlFor="newpassword">
                    {i18n.t('mobileuser.NEWPASSWORD')}
                </label>
                <span style={{fontSize:'small',color:'red'}} >{'(Min '+labelinfolengthow+', Max 20)'}</span>
                <Input
                    name="newpassword"
                    className={
                        "input-font-size"
                        // touched.username && errors.username
                        //     ? "input-font-size input-error"
                        //     : "input-font-size"
                    }
                    type="password"
                    id="newpassword"
                    onChange={val => handleChangePW(val)}
                    disabled={false}
                    // onBlur={handleBlur}
                    placeholder={''}
                    // value={''}
                    
                >
                    </Input>
                <label className="mt-3 form-label required" htmlFor="newrepeatpassword">
                    {i18n.t('mobileuser.NEWREPEATPASSWORD')}
                </label>
                <Input
                    name="newrepeatpassword"
                    className={
                        "input-font-size"
                        // touched.username && errors.username
                        //     ? "input-font-size input-error"
                        //     : "input-font-size"
                    }
                    type="password"
                    id="newrepeatpassword"
                    onChange={val => handleChangeRepeatPW(val)}
                    disabled={false}
                    // onBlur={handleBlur}
                    placeholder={''}
                    // value={''}
                    
                >
                </Input>
                <label className="mt-3 form-label required" htmlFor="otpcode">
                    {i18n.t('mobileuser.OTPCODE')}
                </label>
                <span style={{fontSize:'medium',color:'red'}} >
                {
                    expmilis > 0 ?<Countdown date={Date.now() + handleChangeTimeExpired()} renderer={renderer}/>:''
                }
                </span>
                <Input
                    name="otpcode"
                    className={
                        "input-font-size"
                        // touched.username && errors.username
                        //     ? "input-font-size input-error"
                        //     : "input-font-size"
                    }
                    type="text"
                    id="otpcode"
                    onChange={val => handleChangeOtpCode(val)}
                    disabled={false}
                    // onBlur={handleBlur}
                    placeholder={''}
                    // value={''}
                    
                >
                </Input>
                
                
                </div>
                {loading && <Loading/>}
            </DialogContent>
            <DialogActions>
            <Button autoFocus 
            onClick={() => props.showflag(false)} 
            color="red">
                Cancel
            </Button>
            <Button color="primary" 
              onClick={() => confirmChangePW()}
            >
                Submit
            </Button>
            </DialogActions>
        </div>
      )
};
export default ChangePassworddDialog;